<script>
import { Line } from 'vue-chartjs'
 
export default {
    extends: Line,

    props: [
        "card_id",
        "text_nation", 
        "month",
    ],

    data: () => ({
        list: {
            date: [],
            S: [],
            A: [],
            B: [],
            C_below: [],

            PSA_100: [],
            PSA_95: [],
            PSA_90: [],
            PSA_85: [],
            PSA_80: [],
            PSA_75: [],
            PSA_70: [],
            PSA_65: [],
            PSA_60: [],
            PSA_55: [],
            PSA_50: [],
            PSA_45: [],
            PSA_40: [],
            PSA_35: [],
            PSA_30: [],
            PSA_25: [],
            PSA_20: [],
            PSA_15: [],
            PSA_10: [],

            ETC_100: [],
            ETC_95: [],
            ETC_90: [],
            ETC_85: [],
            ETC_80: [],
            ETC_75: [],
            ETC_70: [],
            ETC_65: [],
            ETC_60: [],
            ETC_55: [],
            ETC_50: [],
            ETC_45: [],
            ETC_40: [],
            ETC_35: [],
            ETC_30: [],
            ETC_25: [],
            ETC_20: [],
            ETC_15: [],
            ETC_10: [],
        },

        ids: [],

        mode: "ETC"
    }),

    mounted(){
        this.group()
    },

    watch: {
        card_id(){
            this.group()
        },

        text_nation(){
            this.group()
        },

        month(){
            this.group()
        }
    },

    methods: {
        async group(){
            // 가격그룹 찾기
            await this.searchSameGroupCard()

            // Chart 생성
            await this.createGraph()   
        },

        // 가격그룹 찾기
        async searchSameGroupCard(){
            // 슈퍼타입 확인
            let supertype = ""
            await this.$http.post('/api/card/detail/trade/group/isPokemon', {
                params: {
                    card_id: this.card_id
                }
            }).then((res => {
                if(res.data.length){
                    supertype = res.data[0].supertype
                }
            }))

            // 시리즈, 이름, 희귀도 확인
            let card = ""
            await this.$http.post('/api/card/detail/trade/group/import/card/'+this.text_nation, {
                params: {
                    card_id: this.card_id
                }
            }).then((res => {
                if(res.data.length){
                    card = res.data[0]
                }
            }))

            if(card!="" && card.series && card.name && card.rarity){
                // 기술 확인
                let attacks = []
                await this.$http.post('/api/card/detail/trade/group/import/attacks/'+this.text_nation, {
                    params: {
                        card_id: this.card_id
                    }
                }).then((res => {
                    if(this.text_nation == "en" && res.data.length){
                        attacks = res.data.map(e => e.name)
                    }else{
                        attacks = res.data.map(e => e.name)
                    }
                }))

                // 시리즈, 이름, 희귀도 같은지 확인
                let idSameList = []
                await this.$http.post('/api/card/detail/trade/group/card/'+this.text_nation, {
                    params: {
                        series : card.series,
                        name : card.name,
                        rarity : card.rarity
                    }
                }).then( async(res) => {
                    if(res.data.length){
                        idSameList = await res.data.map(e => e.id)
                    }
                    this.ids = idSameList
                })

                let attacksSameList = []
                if(supertype == "Pokémon")
                {
                    for (let i=0; i<idSameList.length; i++){
                        await this.$http.post('/api/card/detail/trade/group/import/attacks/'+this.text_nation, {
                            params: {
                                card_id: idSameList[i]
                            }
                        }).then( async (res) => {
                            if(attacks.toString() == res.data.map(e => e.name).toString()){
                                attacksSameList.push(idSameList[i])
                            }
                        })
                    }

                    if(attacksSameList.length){
                        this.ids = attacksSameList
                    }
                }
            }else{
                this.ids = this.card_id
            }
        },

        fillMissingPrices(array, index) {
            // 현재 인덱스 이후 모든 요소가 0 또는 null인지 확인
            let allFollowingAreNullOrZero = true;
            for (let j = index + 1; j < array.length; j++) {
                if (array[j] !== 0 && array[j] != null) {
                    allFollowingAreNullOrZero = false;
                    break;
                }
            }

            // 현재 값이 0 또는 null이고, 이전 값이 유효하며, 이후 모든 값이 0 또는 null이 아니면 이전 값을 할당
            if (!allFollowingAreNullOrZero && 
                (array[index] == 0 || array[index] == null) && 
                index > 0 && 
                array[index - 1] != 0 && 
                array[index - 1] != null) {
                array[index] = array[index - 1];
            }
        },

        // Chart 생성
        async createGraph(){
            await this.$http.post('/api/card/detail/trade/chart', {
                params: {
                    ids: this.ids,
                    card_nation: this.text_nation,
                    month: this.month
                }
            }).then(res => {
                // 값이 없을 경우
                if(!res.data.length){

                    console.log("mode", this.mode)

                    let labels = []

                    if(this.mode == "기본"){
                        labels = [
                            {
                                label: "S급",
                                borderColor: '#F8468D',
                                pointStyle: 'line',
                            },
                            {
                                label: "A급",
                                borderColor: '#FF7A51',
                                pointStyle: 'line',
                            },
                            {
                                label: "B급",
                                borderColor: '#1DA1F2',
                                pointStyle: 'line',
                            },
                            {
                                label: "C급 이하",
                                borderColor: '#23D2E2',
                                pointStyle: 'line',
                            },
                        ]
                    }
                    else if(this.mode == "PSA"){
                        labels = [
                            {
                                label: "PSA 10",
                                borderColor: '#F8468D',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 9.5",
                                borderColor: '#FF7A51',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 9",
                                borderColor: '#FF7A51',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 8.5",
                                borderColor: '#1DA1F2',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 8",
                                borderColor: '#1DA1F2',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 7.5",
                                borderColor: '#23D2E2',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 7",
                                borderColor: '#23D2E2',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 6.5",
                                borderColor: '#615DFA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 6",
                                borderColor: '#615DFA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 5.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 4.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 4",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 3.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 3",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 2.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 2",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 1.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "PSA 1",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                        ]
                    }
                    else if(this.mode == "ETC"){
                        labels = [
                            {
                                label: "ETC 10",
                                borderColor: '#F8468D',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 9.5",
                                borderColor: '#FF7A51',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 9",
                                borderColor: '#FF7A51',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 8.5",
                                borderColor: '#1DA1F2',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 8",
                                borderColor: '#1DA1F2',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 7.5",
                                borderColor: '#23D2E2',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 7",
                                borderColor: '#23D2E2',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 6.5",
                                borderColor: '#615DFA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 6",
                                borderColor: '#615DFA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 5.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 4.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 4",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 3.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 3",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 2.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 2",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 1.5",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                            {
                                label: "ETC 1",
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                            },
                        ]
                    }



                    // 차트 Render
                    this.renderChart({
                        labels: ["판매글 없음"],
                        datasets: labels
                    },

                    // options
                    {
                        scales: {
                            // 가로선
                            yAxes: [{
                                display: true,
                                gridLines : {
                                    display : false
                                }
                            }]
                        },

                        legend: {
                            labels: {
                                usePointStyle: true
                            }
                        }
                    })

                // 값이 있을 경우
                }else{
                    // 값 불러오기

                    // 날짜
                    if(this.month == 1){
                        this.list.date = res.data.map(a => new Date(a.card_date).toLocaleDateString().slice(5))
                    }
                    else if(this.month == 3){
                        this.list.date = res.data.map(a => a.card_month + "월 " + a.card_week + "주")
                    }
                    else if(this.month == 6 || this.month == 12 || this.month == 120){
                        this.list.date = res.data.map(a => a.card_year + "년 " + a.card_month+ "월")
                    }
                    
                    // 가격
                    this.list.S = res.data.map(a => Math.round(a.S_price))
                    this.list.A = res.data.map(a => Math.round(a.A_price))
                    this.list.B = res.data.map(a => Math.round(a.B_price))
                    this.list.C_below = res.data.map(a => Math.round(a.C_below_price))

                    this.list.PSA_100 = res.data.map(a => Math.round(a.PSA_100_price))
                    this.list.PSA_95 = res.data.map(a => Math.round(a.PSA_95_price))
                    this.list.PSA_90 = res.data.map(a => Math.round(a.PSA_90_price))
                    this.list.PSA_85 = res.data.map(a => Math.round(a.PSA_85_price))
                    this.list.PSA_80 = res.data.map(a => Math.round(a.PSA_80_price))
                    this.list.PSA_75 = res.data.map(a => Math.round(a.PSA_75_price))
                    this.list.PSA_70 = res.data.map(a => Math.round(a.PSA_70_price))
                    this.list.PSA_65 = res.data.map(a => Math.round(a.PSA_65_price))
                    this.list.PSA_60 = res.data.map(a => Math.round(a.PSA_60_price))
                    this.list.PSA_55 = res.data.map(a => Math.round(a.PSA_55_price))
                    this.list.PSA_50 = res.data.map(a => Math.round(a.PSA_50_price))
                    this.list.PSA_45 = res.data.map(a => Math.round(a.PSA_45_price))
                    this.list.PSA_40 = res.data.map(a => Math.round(a.PSA_40_price))
                    this.list.PSA_35 = res.data.map(a => Math.round(a.PSA_35_price))
                    this.list.PSA_30 = res.data.map(a => Math.round(a.PSA_30_price))
                    this.list.PSA_25 = res.data.map(a => Math.round(a.PSA_25_price))
                    this.list.PSA_20 = res.data.map(a => Math.round(a.PSA_20_price))
                    this.list.PSA_15 = res.data.map(a => Math.round(a.PSA_15_price))
                    this.list.PSA_10 = res.data.map(a => Math.round(a.PSA_10_price))

                    this.list.ETC_100 = res.data.map(a => Math.round(a.ETC_100_price))
                    this.list.ETC_95 = res.data.map(a => Math.round(a.ETC_95_price))
                    this.list.ETC_90 = res.data.map(a => Math.round(a.ETC_90_price))
                    this.list.ETC_85 = res.data.map(a => Math.round(a.ETC_85_price))
                    this.list.ETC_80 = res.data.map(a => Math.round(a.ETC_80_price))
                    this.list.ETC_75 = res.data.map(a => Math.round(a.ETC_75_price))
                    this.list.ETC_70 = res.data.map(a => Math.round(a.ETC_70_price))
                    this.list.ETC_65 = res.data.map(a => Math.round(a.ETC_65_price))
                    this.list.ETC_60 = res.data.map(a => Math.round(a.ETC_60_price))
                    this.list.ETC_55 = res.data.map(a => Math.round(a.ETC_55_price))
                    this.list.ETC_50 = res.data.map(a => Math.round(a.ETC_50_price))
                    this.list.ETC_45 = res.data.map(a => Math.round(a.ETC_45_price))
                    this.list.ETC_40 = res.data.map(a => Math.round(a.ETC_40_price))
                    this.list.ETC_35 = res.data.map(a => Math.round(a.ETC_35_price))
                    this.list.ETC_30 = res.data.map(a => Math.round(a.ETC_30_price))
                    this.list.ETC_25 = res.data.map(a => Math.round(a.ETC_25_price))
                    this.list.ETC_20 = res.data.map(a => Math.round(a.ETC_20_price))
                    this.list.ETC_15 = res.data.map(a => Math.round(a.ETC_15_price))
                    this.list.ETC_10 = res.data.map(a => Math.round(a.ETC_10_price))

                    let set = []

                    if(this.mode == "기본"){
                        set = [
                            ...this.list.S,
                            ...this.list.A,
                            ...this.list.B,
                            ...this.list.C_below,
                        ]
                    }
                    else if(this.mode == "PSA"){
                        set = [
                            ...this.list.PSA_100,
                            ...this.list.PSA_95,
                            ...this.list.PSA_90,
                            ...this.list.PSA_85,
                            ...this.list.PSA_80,
                            ...this.list.PSA_75,
                            ...this.list.PSA_70,
                            ...this.list.PSA_65,
                            ...this.list.PSA_60,
                            ...this.list.PSA_55,
                            ...this.list.PSA_50,
                            ...this.list.PSA_45,
                            ...this.list.PSA_40,
                            ...this.list.PSA_35,
                            ...this.list.PSA_30,
                            ...this.list.PSA_25,
                            ...this.list.PSA_20,
                            ...this.list.PSA_15,
                            ...this.list.PSA_10,
                        ]
                    }
                    else if(this.mode == "ETC"){
                        set = [
                            ...this.list.ETC_100,
                            ...this.list.ETC_95,
                            ...this.list.ETC_90,
                            ...this.list.ETC_85,
                            ...this.list.ETC_80,
                            ...this.list.ETC_75,
                            ...this.list.ETC_70,
                            ...this.list.ETC_65,
                            ...this.list.ETC_60,
                            ...this.list.ETC_55,
                            ...this.list.ETC_50,
                            ...this.list.ETC_45,
                            ...this.list.ETC_40,
                            ...this.list.ETC_35,
                            ...this.list.ETC_30,
                            ...this.list.ETC_25,
                            ...this.list.ETC_20,
                            ...this.list.ETC_15,
                            ...this.list.ETC_10,
                        ]
                    }

                    let max_price = Math.max.apply(Math, set)

                    this.$emit("list", this.list)

                    // 가격없을 시 이전 가격으로 표시
                    for(let i=0; i < this.list.date.length; i++){
                        if(this.mode == "기본"){
                            this.fillMissingPrices(this.list.S, i)
                            this.fillMissingPrices(this.list.A, i)
                            this.fillMissingPrices(this.list.B, i)
                            this.fillMissingPrices(this.list.C_below, i)

                            // ! 필요한지 확인 필요
                            // S (null을 0으로 변환)
                            if(this.list.S[i] == 0){
                                this.list.S[i] = null
                            }

                            // A (null을 0으로 변환)
                            if(this.list.A[i] == 0){
                                this.list.A[i] = null
                            }

                            // B (null을 0으로 변환)
                            if(this.list.B[i] == 0){
                                this.list.B[i] = null
                            }

                            // C_below (null을 0으로 변환)
                            if(this.list.C_below[i] == 0){
                                this.list.C_below[i] = null
                            }
                        }
                        else if(this.mode == "PSA"){
                            this.fillMissingPrices(this.list.PSA_100, i)
                            this.fillMissingPrices(this.list.PSA_95, i)
                            this.fillMissingPrices(this.list.PSA_90, i)
                            this.fillMissingPrices(this.list.PSA_85, i)
                            this.fillMissingPrices(this.list.PSA_80, i)
                            this.fillMissingPrices(this.list.PSA_75, i)
                            this.fillMissingPrices(this.list.PSA_70, i)
                            this.fillMissingPrices(this.list.PSA_65, i)
                            this.fillMissingPrices(this.list.PSA_60, i)
                            this.fillMissingPrices(this.list.PSA_55, i)
                            this.fillMissingPrices(this.list.PSA_50, i)
                            this.fillMissingPrices(this.list.PSA_45, i)
                            this.fillMissingPrices(this.list.PSA_40, i)
                            this.fillMissingPrices(this.list.PSA_35, i)
                            this.fillMissingPrices(this.list.PSA_30, i)
                            this.fillMissingPrices(this.list.PSA_25, i)
                            this.fillMissingPrices(this.list.PSA_20, i)
                            this.fillMissingPrices(this.list.PSA_15, i)
                            this.fillMissingPrices(this.list.PSA_10, i)

                            if(this.list.PSA_100[i] == 0) this.list.PSA_100[i] = null
                            if(this.list.PSA_95[i] == 0) this.list.PSA_95[i] = null
                            if(this.list.PSA_90[i] == 0) this.list.PSA_90[i] = null
                            if(this.list.PSA_85[i] == 0) this.list.PSA_85[i] = null
                            if(this.list.PSA_80[i] == 0) this.list.PSA_80[i] = null
                            if(this.list.PSA_75[i] == 0) this.list.PSA_75[i] = null
                            if(this.list.PSA_70[i] == 0) this.list.PSA_70[i] = null
                            if(this.list.PSA_65[i] == 0) this.list.PSA_65[i] = null
                            if(this.list.PSA_60[i] == 0) this.list.PSA_60[i] = null
                            if(this.list.PSA_55[i] == 0) this.list.PSA_55[i] = null
                            if(this.list.PSA_50[i] == 0) this.list.PSA_50[i] = null
                            if(this.list.PSA_45[i] == 0) this.list.PSA_45[i] = null
                            if(this.list.PSA_40[i] == 0) this.list.PSA_40[i] = null
                            if(this.list.PSA_35[i] == 0) this.list.PSA_35[i] = null
                            if(this.list.PSA_30[i] == 0) this.list.PSA_30[i] = null
                            if(this.list.PSA_25[i] == 0) this.list.PSA_25[i] = null
                            if(this.list.PSA_20[i] == 0) this.list.PSA_20[i] = null
                            if(this.list.PSA_15[i] == 0) this.list.PSA_15[i] = null
                            if(this.list.PSA_10[i] == 0) this.list.PSA_10[i] = null
                        }
                        else if(this.mode == "ETC"){
                            this.fillMissingPrices(this.list.ETC_100, i)
                            this.fillMissingPrices(this.list.ETC_95, i)
                            this.fillMissingPrices(this.list.ETC_90, i)
                            this.fillMissingPrices(this.list.ETC_85, i)
                            this.fillMissingPrices(this.list.ETC_80, i)
                            this.fillMissingPrices(this.list.ETC_75, i)
                            this.fillMissingPrices(this.list.ETC_70, i)
                            this.fillMissingPrices(this.list.ETC_65, i)
                            this.fillMissingPrices(this.list.ETC_60, i)
                            this.fillMissingPrices(this.list.ETC_55, i)
                            this.fillMissingPrices(this.list.ETC_50, i)
                            this.fillMissingPrices(this.list.ETC_45, i)
                            this.fillMissingPrices(this.list.ETC_40, i)
                            this.fillMissingPrices(this.list.ETC_35, i)
                            this.fillMissingPrices(this.list.ETC_30, i)
                            this.fillMissingPrices(this.list.ETC_25, i)
                            this.fillMissingPrices(this.list.ETC_20, i)
                            this.fillMissingPrices(this.list.ETC_15, i)
                            this.fillMissingPrices(this.list.ETC_10, i)

                            if(this.list.ETC_100[i] == 0) this.list.ETC_100[i] = null
                            if(this.list.ETC_95[i] == 0) this.list.ETC_95[i] = null
                            if(this.list.ETC_90[i] == 0) this.list.ETC_90[i] = null
                            if(this.list.ETC_85[i] == 0) this.list.ETC_85[i] = null
                            if(this.list.ETC_80[i] == 0) this.list.ETC_80[i] = null
                            if(this.list.ETC_75[i] == 0) this.list.ETC_75[i] = null
                            if(this.list.ETC_70[i] == 0) this.list.ETC_70[i] = null
                            if(this.list.ETC_65[i] == 0) this.list.ETC_65[i] = null
                            if(this.list.ETC_60[i] == 0) this.list.ETC_60[i] = null
                            if(this.list.ETC_55[i] == 0) this.list.ETC_55[i] = null
                            if(this.list.ETC_50[i] == 0) this.list.ETC_50[i] = null
                            if(this.list.ETC_45[i] == 0) this.list.ETC_45[i] = null
                            if(this.list.ETC_40[i] == 0) this.list.ETC_40[i] = null
                            if(this.list.ETC_35[i] == 0) this.list.ETC_35[i] = null
                            if(this.list.ETC_30[i] == 0) this.list.ETC_30[i] = null
                            if(this.list.ETC_25[i] == 0) this.list.ETC_25[i] = null
                            if(this.list.ETC_20[i] == 0) this.list.ETC_20[i] = null
                            if(this.list.ETC_15[i] == 0) this.list.ETC_15[i] = null
                            if(this.list.ETC_10[i] == 0) this.list.ETC_10[i] = null
                        }
                    }

                    let renderList = []

                    if(this.mode == "기본"){
                        renderList = [
                            {
                                label: "S급",
                                backgroundColor: 'transparent',
                                borderColor: '#F8468D',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.S
                            },
                            {
                                label: "A급",
                                backgroundColor: 'transparent',
                                borderColor: '#FF7A51',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.A
                            },
                            {
                                label: "B급",
                                backgroundColor: 'transparent',
                                borderColor: '#1DA1F2',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.B
                            },
                            {
                                label: "C급 이하",
                                backgroundColor: 'transparent',
                                borderColor: '#23D2E2',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.C_below
                            },
                        ]
                    }
                    else if(this.mode == "PSA"){
                        renderList = [
                            {
                                label: "PSA 10",
                                backgroundColor: 'transparent',
                                borderColor: '#F8468D',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_100
                            },
                            {
                                label: "PSA 9",
                                backgroundColor: 'transparent',
                                borderColor: '#FF7A51',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_90
                            },
                            {
                                label: "PSA 8",
                                backgroundColor: 'transparent',
                                borderColor: '#1DA1F2',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_80
                            },
                            {
                                label: "PSA 7",
                                backgroundColor: 'transparent',
                                borderColor: '#23D2E2',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_70
                            },
                            {
                                label: "PSA 6",
                                backgroundColor: 'transparent',
                                borderColor: '#615DFA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_60
                            },
                            {
                                label: "PSA 5",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_50
                            },
                            {
                                label: "PSA 4",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_40
                            },
                            {
                                label: "PSA 3",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_30
                            },
                            {
                                label: "PSA 2",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_20
                            },
                            {
                                label: "PSA 1",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.PSA_10
                            },
                        ]
                    }
                    else if(this.mode == "ETC"){
                        renderList = [
                            {
                                label: "ETC 10",
                                backgroundColor: 'transparent',
                                borderColor: '#F8468D',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_100
                            },
                            {
                                label: "ETC 9",
                                backgroundColor: 'transparent',
                                borderColor: '#FF7A51',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_90
                            },
                            {
                                label: "ETC 8",
                                backgroundColor: 'transparent',
                                borderColor: '#1DA1F2',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_80
                            },
                            {
                                label: "ETC 7",
                                backgroundColor: 'transparent',
                                borderColor: '#23D2E2',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_70
                            },
                            {
                                label: "ETC 6",
                                backgroundColor: 'transparent',
                                borderColor: '#615DFA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_60
                            },
                            {
                                label: "ETC 5",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_50
                            },
                            {
                                label: "ETC 4",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_40
                            },
                            {
                                label: "ETC 3",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_30
                            },
                            {
                                label: "ETC 2",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_20
                            },
                            {
                                label: "ETC 1",
                                backgroundColor: 'transparent',
                                borderColor: '#ADAFCA',
                                pointStyle: 'line',
                                lineTension: 0,
                                data: this.list.ETC_10
                            },
                        ]
                    }

                    // 차트 Render
                    this.renderChart({
                        lineTension: 0,  
                        labels: this.list.date.length? this.list.date : ["판매글 없음"],
                        datasets: renderList
                    },

                    // options
                    {
                        tooltips: {
                            // 점 상세
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    // $toComma와 동일
                                    return tooltipItem.value.split(/(?=(?:...)*$)/).join(',') + "원"
                                }
                            }
                        }, 

                        scales: {
                            // 가로선
                            yAxes: [{
                                display: true,
                                ticks: {
                                    suggestedMin: 500,
                                    suggestedMax: max_price + max_price*0.5,
                                    userCallback: function(value, index, values) {
                                        value = value.toString()
                                        value = value.split(/(?=(?:...)*$)/)
                                        value = value.join(',')
                                        return value + "원"
                                    }
                                },
                                gridLines : {
                                    display : false
                                }
                            }]
                        },

                        legend: {
                            labels: {
                                usePointStyle: true
                            }
                        }
                    })
                }
            })
        }
    }
}
</script>