<template>
    <div>
        <!-- PC -->
        <v-sheet color="transparent" v-if="!$vuetify.breakpoint.mobile">
            <!-- 카드정보 -->
            <v-card
                v-if="isExist"
                flat class="px-6 pt-2 rounded-10"
            >
                <CardInfo 
                    :text_nation="text_nation"
                    @text_nation_changed="text_nation_update" 
                />

                <v-sheet
                    class="pb-4 d-flex justify-end"
                >
                    <div>
                        <p
                            class="text-end mr-2 mb-2"
                            style="font-size:11px; color:#ADAFCA;"
                        >
                            {{ view_count }}명이 이 카드를 봤습니다
                        </p>
                        <!-- <v-img
                            width="400"
                            class="rounded-10"
                            src="@/assets/banner.png"
                            @click="bannerOpen()"
                        /> -->
                    </div>
                </v-sheet>
            </v-card>

            <div class="mt-3">
                <CardButtons
                    :text_nation="text_nation"
                    :key="componentKey"
                />
            </div>

            <!-- 포인트 지급 안내문구 -->
            <p class="text-center my-2" style="font-size:11px; color:#ADAFCA;">
                (이미지와 정보 오류를 제공하면 포인트 지급 : 이미지 50~200P, 자료입력/수정 15~100P, 정보오류 10~100P)
            </p>

            <!-- 카드거래 -->
            <v-sheet
                v-if="isExist"
                class="mt-2 pa-4 rounded-10"
                style="position:relative;"
            >
                <PriceCreate

                />
                <CardTrade
                    :card_id="$route.query.id"
                    :text_nation="text_nation"
                    @text_nation_changed="text_nation_update" 
                />
            </v-sheet>

            <!-- 카드평점 / 댓글 -->
            <v-sheet
                v-if="isExist"
                class="mt-2 pa-4 rounded-10"
            >
                <CardReview
                    :card_id="$route.query.id"
                    :text_nation="text_nation" 
                    @text_nation_changed="text_nation_update" 
                />
            </v-sheet>

            <!-- 덱 레시피 -->
            <v-sheet
                v-if="isExist"
                class="mt-2 pa-4 rounded-10"
            >
                <DeckList
                    :card_id="$route.query.id"
                    :text_nation="text_nation"
                />
            </v-sheet>

            <!-- 관련카드 -->
            <v-sheet
                v-if="isExist"
                class="mt-2 pa-4 rounded-10"
            >
                <RelatedCardList />
            </v-sheet>

            <!-- 카드 전부 없을 경우 -->
            <v-sheet
                v-if="!isExist"
                class="py-16 rounded-10 text-center"
            >
                <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                <p class="blue--text">해당 ID로는 등록된 카드정보가 없습니다.</p>
            </v-sheet>

            <BannerView
                v-if="$store.state.ads_banner.card_search_detail"
                class="mt-3 ml-auto"
                type="card_search_detail"
                width="360"
                height="46"
                :round="'rounded-10'"
            />
        </v-sheet>

        <!-- Mobile -->
        <v-sheet color="transparent" v-if="$vuetify.breakpoint.mobile">
            <!-- 카드정보 -->
            <div
                v-if="isExist"
            >
                <CardInfo 
                    :text_nation="text_nation"
                    @text_nation_changed="text_nation_update" 
                />
            </div>

            <div class="my-6px">
                <CardButtons
                    :text_nation="text_nation"
                    :key="componentKey"
                />
            </div>

            <!-- <v-img
                src="@/assets/banner.png"
                @click="bannerOpen()"
            /> -->

            <!-- 카드거래 -->
            <v-sheet
                v-if="isExist"
                style="position:relative;"
            >
                <CardTrade
                    :card_id="$route.query.id"
                    :text_nation="text_nation"
                    @text_nation_changed="text_nation_update" 
                    :key="componentKey"
                />
            </v-sheet>

            <!-- 카드평점 / 댓글 -->
            <v-sheet
                v-if="isExist"
                class="mt-2 pa-4 rounded-10"
            >
                <CardReview
                    :card_id="$route.query.id"
                    :text_nation="text_nation" 
                    @text_nation_changed="text_nation_update" 
                />
            </v-sheet>

            <!-- 덱 레시피 -->
            <v-sheet
                v-if="isExist"
                class="mt-2 pa-4 rounded-10"
            >
                <DeckList
                    :card_id="$route.query.id"
                    :text_nation="text_nation"
                />
            </v-sheet>

            <!-- 관련카드 -->
            <v-sheet
                v-if="isExist"
                class="mt-2 pa-4 rounded-10"
            >
                <RelatedCardList />
            </v-sheet>

            <!-- 카드 전부 없을 경우 -->
            <v-sheet
                v-if="!isExist"
                class="py-16 rounded-10 text-center"
            >
                <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                <p class="blue--text">해당 ID로는 등록된 카드정보가 없습니다.</p>
            </v-sheet>

            <BannerView
                v-if="$store.state.ads_banner.card_search_detail"
                class="mt-3"
                type="card_search_detail"
                width="100%"
                :height="46/360*100+'vw'"
                :round="'rounded-0'"
            />
        </v-sheet>
    </div>
</template>
<script>
import PriceCreate from '@/components/card/detail/CardButtons/PriceCreate'

import CardInfo from '@/components/card/detail/CardInfo'
import CardButtons from '@/components/card/detail/CardButtons'
import CardTrade from '@/components/card/detail/CardTrade'
import CardReview from '@/components/card/detail/CardReview'
import DeckList from '@/components/card/detail/DeckList'
import RelatedCardList from '@/components/card/detail/RelatedCardList'
import BannerView from '@/components/BannerView'

export default {
    components: {
        PriceCreate,
        CardInfo,
        CardButtons,
        CardTrade,
        CardReview,
        DeckList,
        RelatedCardList,
        BannerView
    },

    data: () => ({
        card: {},
        text_nation: "en",
        isExist: 0,
        view_count: 0,
        componentKey: 0
    }),

    mounted(){
        // 한개라도 카드정보가 있는지 체크
        this.checkExist()

        // 조회수 불러오기
        this.loadViewCount()
    },

    watch: {
        text_nation(){
            this.componentKey++
        }
    },

    methods: {
        // 조회수 불러오기
        loadViewCount(){
            this.$http.post("/api/card/select/view_count", {
                params: {
                    card_id: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length)
                {
                    this.view_count = res.data[0].view_count
                }
            })
        },

        text_nation_update(text_nation){
            this.text_nation = text_nation
        },

        // 한개라도 카드정보가 있는지 체크
        checkExist(){
            this.$http.post('/api/card/detail/isExist', {
                params: {
                    id: this.$route.query.id
                }
            }).then((res) => {
                this.isExist = res.data[0].isExist
            })
        },

        bannerOpen(){
            window.open('https://icu.gg/board/read?board_group=%EB%84%88%EC%A0%95%EB%8B%A4&board_name=%EC%9D%B4%EB%B2%A4%ED%8A%B8%20%EC%B0%B8%EA%B0%80&id=600', '_blank')
        }
    }
}
</script>
<style>
/* 진화 table */
.detail_table_evolve{
    width:100%;
    border:1px solid #ccc;
    border-collapse: collapse;
}

.detail_table_evolve tr td:first-child{
    font-weight:bold;
    background:#ECEFF1;
    width:160px;
    height:40px;
    text-align:center;
}

.detail_table_evolve tr td{
    height:40px;
    text-align:center;
    border:1px solid #ccc;
}

/* 이미지 업로드 */
#image_upload table tr td{
    background:white;
}

#image_upload2 table tr td{
    background:white;
    height:60px;
}

/* 입력/수정하기 */

/* 기본 정보 */
#basic{
    min-height:524px;
    border:1px solid #ccc;
    border-collapse:collapse;
    text-align:center;
}
#basic tr th{
    height:44px;
    font-size:13pt;
    font-weight:bold;
}
#basic tr td{
    height:38px;
    font-size:11pt;
    border:1px solid #ccc;
}
#basic tr td:nth-child(odd){ 
    width:180px;
    background:#f2f3f4;
}

/* 기술 정보 */
.attacks{
    border:1px solid #ccc;
    border-collapse: collapse;
    text-align:center;
}
.attacks tr td{
    border:1px solid #ccc;
}
.attacks tr th{
    height:44px;
    font-size:13pt;
    font-weight:bold;
}
.attack{
    border:1px solid #ccc;
    border-collapse:collapse;
    text-align:center;
}
.attack tr td{
    font-size:11pt;
    min-width:50px;
    height:40px;
    border:1px solid #ccc;
}
.attack tr td:first-child{ 
    width:100px;
}
td.attackHead{
    background:#f2f3f4;
}
</style>