<template>
    <div>
        <!-- 카드 이름 표시 -->
        <Name 
            :card_id="card_id"
            :text_nation="text_nation"
        />

        <!-- TEXT 언어선택 -->
        <v-sheet
            v-if="$route.path.startsWith('/card/detail') && $vuetify.breakpoint.mobile"
            class="d-flex mt-3 mb-5"
        >
            <v-btn
                depressed
                dark
                width="60"
                height="34"
                class="rounded-15 mr-1"
                v-ripple="false"
                style="font-size:12px; font-weight:400; min-width:0px;"
                :color="text_nation == 'kr'? '#615DFA':'#ADAFCA'"
                @click="$emit('text_nation_changed', 'kr')"
            >
                한글
            </v-btn>
            <v-btn
                depressed
                dark
                width="60"
                height="34"
                class="rounded-15 mr-1"
                v-ripple="false"
                style="font-size:12px; font-weight:400; min-width:0px;"
                :color="text_nation == 'en'? '#615DFA':'#ADAFCA'"
                @click="$emit('text_nation_changed', 'en')"
            >
                영어
            </v-btn>
            <v-btn
                depressed
                dark
                width="60"
                height="34"
                class="rounded-15"
                v-ripple="false"
                style="font-size:12px; font-weight:400; min-width:0px;"
                :color="text_nation == 'jp'? '#615DFA':'#ADAFCA'"
                @click="$emit('text_nation_changed', 'jp')"
            >
                일어
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                depressed
                dark
                width="60"
                height="34"
                class="rounded-15"
                v-ripple="false"
                style="font-size:12px; font-weight:400; min-width:0px;"
                color="primary"
                @click="scrollToTop"
            >
                맨 위로
            </v-btn>
        </v-sheet>

        <!-- 목록 -->
        <div>
            <!-- PC -->
            <div
                v-if="!$vuetify.breakpoint.mobile"
            >
                <div
                    style="padding:0 100px;"
                >
                    <div
                        v-if="list.length"
                        class="mt-3"
                        style="margin:0 -4px;"
                    >
                        <v-row
                            no-gutters
                            class="d-flex justify-center"
                        >
                            <v-col
                                :class="$vuetify.breakpoint.mobile? 'py-4 px-10':'pa-1'"
                                :cols="$vuetify.breakpoint.mobile? 12:4"
                                v-for="item in list" :key="item.id"
                            >
                                <v-card
                                    style="cursor:pointer; box-shadow:0px 2px 10px #eee !important; border:1px solid #eee;"
                                    class="customHover text-center rounded-xl pb-2 mx-auto elevation-0"
                                    @click="read(item)"
                                >
                                    <v-sheet
                                        height="136"
                                        class=""
                                        style="position:relative;"
                                    >
                                        <!-- 덱 이미지 -->
                                        <v-img
                                            height="100"
                                            class="rounded-t-xl"
                                            position="center 30%"
                                            :src="computed_image(item)"
                                        ></v-img>

                                        <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                                        <v-sheet
                                            width="120"
                                            class="rounded-20 mx-auto"
                                            style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                                        >
                                            <p
                                                class="mb-0 py-2"
                                                style="font-size:13px; font-weight:bold;"
                                                :style="item.regulation_group == '스탠다드'? 'color:#615DFA;':'color:#23D2E2;'"
                                            >
                                                {{item.regulation_group}}
                                            </p>
                                        </v-sheet>
                                    </v-sheet>

                                    <div
                                        style="position:relative; margin-top:-9px; z-index:2;"
                                    >
                                        <!-- 덱 제목 -->
                                        <p
                                            style="font-size:13px; font-weight:bold; line-height:18px;"
                                            class="px-8 text-truncate mb-0"
                                        >
                                            {{item.title}}
                                        </p>

                                        <!-- 덱 작성일자 -->
                                        <p
                                            class="mb-0 pb-1px pt-1px"
                                            style="font-size:9px; color:#B2B2B2; line-height:11px;"
                                        >
                                            {{new Date(item.created).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                                        </p>

                                        <!-- 덱 태그 -->
                                        <p
                                            style="font-size:10px; height:20px;"
                                            class="mb-1"
                                        >
                                            <font
                                                v-if="item.tag"
                                                class="primary--text font-weight-medium"
                                            >
                                                {{"#"+item.tag?.replaceAll(',', ' #')}}
                                            </font>
                                        </p>
                                    </div>

                                    <div
                                        class="d-flex px-5"
                                    >
                                        <v-sheet
                                            width="33%"
                                        >
                                            <p
                                                class="mb-1"
                                                style="font-size:11px; font-weight:bold; color:#424361;"
                                            >
                                                {{item.pokemon_count}}
                                            </p>
                                            <p
                                                style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                            >
                                                포켓몬
                                            </p>
                                        </v-sheet>
                                        <v-sheet
                                            width="1"
                                            class="mb-3"
                                            style="background:#f8f7fb;"
                                        ></v-sheet>
                                        <v-sheet
                                            width="33%"
                                        >
                                            <p
                                                class="mb-1"
                                                style="font-size:11px; font-weight:bold; color:#424361;"
                                            >
                                                {{item.trainer_count}}
                                            </p>
                                            <p
                                                style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                            >
                                                트레이너스
                                            </p>
                                        </v-sheet>
                                        <v-sheet
                                            width="1"
                                            class="mb-3"
                                            style="background:#f8f7fb;"
                                        ></v-sheet>
                                        <v-sheet
                                            width="33%"
                                        >
                                            <p
                                                class="mb-1"
                                                style="font-size:11px; font-weight:bold; color:#424361;"
                                            >
                                                {{item.energy_count}}
                                            </p>
                                            <p
                                                style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                            >
                                                에너지
                                            </p>
                                        </v-sheet>
                                    </div>

                                    <p
                                        style="font-size:11px; margin-bottom:-2px;"
                                    >
                                        <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-eye-outline</v-icon> {{item.view_count}}
                                        <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-thumb-up-outline</v-icon> {{item.like_count}}
                                        <v-icon class="pb-1 mr-2px" size="16">mdi-content-copy</v-icon> {{item.copy_count}}
                                    </p>

                                    <v-sheet
                                        v-if="item.status=='공개'"
                                        height="60"
                                    >
                                        <!-- 별점 -->
                                        <div class="d-flex justify-center align-center mb-1">
                                            <v-rating
                                                :value="item.rating_avg"
                                                half-increments
                                                readonly
                                                background-color="grey"
                                                color="#615DFA"
                                                size="14"
                                            ></v-rating>
                                            <p
                                                class="ml-1 mt-1 mb-0"
                                                style="font-size:11px;"
                                            >
                                                평점
                                                <span style="color:#615DFA">({{item.rating_count}})</span>
                                            </p>
                                        </div>

                                        <!-- 작성자 닉네임 -->
                                        <div
                                            class="d-flex justify-center align-center mb-3"
                                        >
                                            <Avatar
                                                v-if="item.writer"
                                                :user="item.writer"
                                                :color="'black'"
                                            />
                                            <p
                                                class="mb-0"
                                                style="font-size:12px; color:#424361; font-weight:bold; margin-left:-12px;"
                                            >
                                                {{item.writer_nickname}}
                                            </p>
                                        </div>
                                    </v-sheet>
                                    <v-sheet
                                        v-if="item.status=='비공개'"
                                        height="60"
                                        class="pt-5"
                                    >
                                        <!-- 비공개 -->
                                        <p
                                            class="mb-0"
                                            style="font-size:12px; color:#424361; font-weight:medium;"
                                        >
                                            비공개
                                        </p>
                                    </v-sheet>

                                    <v-sheet
                                        height="1"
                                        class="mb-2"
                                        style="background:#f8f7fb;"
                                    ></v-sheet>

                                    <div
                                        class="d-flex justify-center"
                                        style="font-size:13px; font-weight:bold; color:#424361;"
                                    >
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Grass').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Grass').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Fire').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Fire').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Water').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Water').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Lightning').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Lightning').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Psychic').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Psychic').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Fighting').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Fighting').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Darkness').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Darkness').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Metal').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Metal').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Fairy').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Fairy').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Dragon').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Dragon').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                                        </div>
                                        <div
                                            v-if="item.types.split(',').filter(item => item === 'Colorless').length"
                                            class="mx-2px"
                                        >
                                            {{item.types.split(',').filter(item => item === 'Colorless').length}}<br/>
                                            <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                    <div
                        v-if="list.length == 0"
                        class="mt-5 text-center py-10"
                    >
                        <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                        <p style="color:#ccc">아직 이 카드로 만든 덱 레시피가 없습니다</p>
                    </div>
                </div>

                <!-- 버튼 (PC) -->
                <div
                    :class="$vuetify.breakpoint.mobile? 'd-flex justify-center mb-6':'d-flex justify-end mt-4 mb-6'"
                    style="padding:0 100px;"
                >
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px;"
                        color="primary"
                        width="90"
                        height="35"
                        :to="'/deck/list?search_type='+this.keyword.search_type+'&text='+this.$route.query.id"
                    >
                        더보기
                    </v-btn>
                </div>
            </div>

            <!-- Mobile -->
            <div
                v-if="$vuetify.breakpoint.mobile"
            >
                <div>
                    <div
                        v-if="list.length"
                        class="mt-3"
                        style="margin:0 -4px;"
                    >
                        <v-carousel
                            class="my-carousel"
                            hide-delimiters
                            height="380"
                        >
                            <v-carousel-item
                                v-for="item in list"
                                :key="item"
                            >
                                <v-col
                                    :class="$vuetify.breakpoint.mobile? 'py-4 px-10':'pa-1'"
                                    :cols="$vuetify.breakpoint.mobile? 12:4"
                                    v-for="item in list.slice(0,1)" :key="item.id"
                                >
                                    <v-card
                                        style="cursor:pointer; box-shadow:0px 2px 10px #eee !important; border:1px solid #eee;"
                                        class="customHover text-center rounded-xl pb-2 mx-auto elevation-0"
                                        @click="read(item)"
                                    >
                                        <v-sheet
                                            height="136"
                                            class=""
                                            style="position:relative;"
                                        >
                                            <!-- 덱 이미지 -->
                                            <v-img
                                                height="100"
                                                class="rounded-t-xl"
                                                position="center 30%"
                                                :src="computed_image(item)"
                                            ></v-img>

                                            <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                                            <v-sheet
                                                width="120"
                                                class="rounded-20 mx-auto"
                                                style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                                            >
                                                <p
                                                    class="mb-0 py-2"
                                                    style="font-size:13px; font-weight:bold;"
                                                    :style="item.regulation_group == '스탠다드'? 'color:#615DFA;':'color:#23D2E2;'"
                                                >
                                                    {{item.regulation_group}}
                                                </p>
                                            </v-sheet>
                                        </v-sheet>

                                        <div
                                            style="position:relative; margin-top:-9px; z-index:2;"
                                        >
                                            <!-- 덱 제목 -->
                                            <p
                                                style="font-size:13px; font-weight:bold; line-height:18px;"
                                                class="px-8 text-truncate mb-0"
                                            >
                                                {{item.title}}
                                            </p>

                                            <!-- 덱 작성일자 -->
                                            <p
                                                class="mb-0 pb-1px pt-1px"
                                                style="font-size:9px; color:#B2B2B2; line-height:11px;"
                                            >
                                                {{new Date(item.created).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                                            </p>

                                            <!-- 덱 태그 -->
                                            <p
                                                style="font-size:10px; height:20px;"
                                                class="mb-1"
                                            >
                                                <font
                                                    v-if="item.tag"
                                                    class="primary--text font-weight-medium"
                                                >
                                                    {{"#"+item.tag?.replaceAll(',', ' #')}}
                                                </font>
                                            </p>
                                        </div>

                                        <div
                                            class="d-flex px-5"
                                        >
                                            <v-sheet
                                                width="33%"
                                            >
                                                <p
                                                    class="mb-1"
                                                    style="font-size:11px; font-weight:bold; color:#424361;"
                                                >
                                                    {{item.pokemon_count}}
                                                </p>
                                                <p
                                                    style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                                >
                                                    포켓몬
                                                </p>
                                            </v-sheet>
                                            <v-sheet
                                                width="1"
                                                class="mb-3"
                                                style="background:#f8f7fb;"
                                            ></v-sheet>
                                            <v-sheet
                                                width="33%"
                                            >
                                                <p
                                                    class="mb-1"
                                                    style="font-size:11px; font-weight:bold; color:#424361;"
                                                >
                                                    {{item.trainer_count}}
                                                </p>
                                                <p
                                                    style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                                >
                                                    트레이너스
                                                </p>
                                            </v-sheet>
                                            <v-sheet
                                                width="1"
                                                class="mb-3"
                                                style="background:#f8f7fb;"
                                            ></v-sheet>
                                            <v-sheet
                                                width="33%"
                                            >
                                                <p
                                                    class="mb-1"
                                                    style="font-size:11px; font-weight:bold; color:#424361;"
                                                >
                                                    {{item.energy_count}}
                                                </p>
                                                <p
                                                    style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                                >
                                                    에너지
                                                </p>
                                            </v-sheet>
                                        </div>

                                        <p
                                            style="font-size:11px; margin-bottom:-2px;"
                                        >
                                            <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-eye-outline</v-icon> {{item.view_count}}
                                            <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-thumb-up-outline</v-icon> {{item.like_count}}
                                            <v-icon class="pb-1 mr-2px" size="16">mdi-content-copy</v-icon> {{item.copy_count}}
                                        </p>

                                        <v-sheet
                                            v-if="item.status=='공개'"
                                            height="60"
                                        >
                                            <!-- 별점 -->
                                            <div class="d-flex justify-center align-center mb-1">
                                                <v-rating
                                                    :value="item.rating_avg"
                                                    half-increments
                                                    readonly
                                                    background-color="grey"
                                                    color="#615DFA"
                                                    size="14"
                                                ></v-rating>
                                                <p
                                                    class="ml-1 mt-1 mb-0"
                                                    style="font-size:11px;"
                                                >
                                                    평점
                                                    <span style="color:#615DFA">({{item.rating_count}})</span>
                                                </p>
                                            </div>

                                            <!-- 작성자 닉네임 -->
                                            <div
                                                class="d-flex justify-center align-center mb-3"
                                            >
                                                <Avatar
                                                    v-if="item.writer"
                                                    :user="item.writer"
                                                    :color="'black'"
                                                />
                                                <p
                                                    class="mb-0"
                                                    style="font-size:12px; color:#424361; font-weight:bold; margin-left:-12px;"
                                                >
                                                    {{item.writer_nickname}}
                                                </p>
                                            </div>
                                        </v-sheet>
                                        <v-sheet
                                            v-if="item.status=='비공개'"
                                            height="60"
                                            class="pt-5"
                                        >
                                            <!-- 비공개 -->
                                            <p
                                                class="mb-0"
                                                style="font-size:12px; color:#424361; font-weight:medium;"
                                            >
                                                비공개
                                            </p>
                                        </v-sheet>

                                        <v-sheet
                                            height="1"
                                            class="mb-2"
                                            style="background:#f8f7fb;"
                                        ></v-sheet>

                                        <div
                                            class="d-flex justify-center"
                                            style="font-size:13px; font-weight:bold; color:#424361;"
                                        >
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Grass').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Grass').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Fire').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Fire').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Water').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Water').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Lightning').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Lightning').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Psychic').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Psychic').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Fighting').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Fighting').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Darkness').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Darkness').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Metal').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Metal').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Fairy').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Fairy').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Dragon').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Dragon').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                                            </div>
                                            <div
                                                v-if="item.types.split(',').filter(item => item === 'Colorless').length"
                                                class="mx-2px"
                                            >
                                                {{item.types.split(',').filter(item => item === 'Colorless').length}}<br/>
                                                <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-carousel-item>
                            <template v-slot:prev="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" :ripple="false">
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:next="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" :ripple="false">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </template>
                        </v-carousel>
                    </div>
                    <div
                        v-if="list.length == 0"
                        class="mt-5 text-center py-10"
                    >
                        <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                        <p style="color:#ccc">아직 이 카드로 만든 덱 레시피가 없습니다</p>
                    </div>
                </div>

                <!-- 버튼 (PC) -->
                <div
                    :class="$vuetify.breakpoint.mobile? 'd-flex justify-center mb-6':'d-flex justify-end mt-4 mb-6'"
                    style="padding:0 100px;"
                >
                    <v-btn
                        depressed
                        dark
                        class="rounded-15"
                        style="font-size:12px;"
                        color="primary"
                        width="60"
                        height="35"
                        :to="'/deck/list?search_type='+this.keyword.search_type+'&text='+this.$route.query.id"
                    >
                        더보기
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Name from './DeckList/Name'
import Avatar from '@/components/AvatarForDeckList'

export default {
    props: ["card_id", "text_nation"],

    components: {
        Name,
        Avatar
    },

    data: () => ({
        keyword: {
            regulation_group: "전체",
            search_type: "카드 ID",
            text: "",

            // 덱 태그
            tag: "",

            // 제품명 시즌
            season: ""
        },

        sequence: "최신순",

        card: {
            en: {},
            kr: {},
            jp: {}
        },
        
        list: [],
    }),

    async mounted(){
        await this.loadCard()
        
        this.keyword.text = this.$route.query.id

        this.search()
    },

    methods: {
        // 덱 이미지 표시
        computed_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },
        
        scrollToTop(){
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        // 카드 정보 불러오기
        async loadCard(){
            // en
            await this.$http.post('/api/card/detail/card_en', {
                params: {
                    id: this.card_id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.en = JSON.parse(res.data[0].dump)
                }
            })

            // kr
            await this.$http.post('/api/card/detail/card_kr', {
                params: {
                    id: this.card_id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.kr = res.data[0]
                }
            })

            // jp
            await this.$http.post('/api/card/detail/card_jp', {
                params: {
                    id: this.card_id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.jp = res.data[0]
                }
            })
        },

        // 검색
        search(){
            // console.log(this.keyword)
            this.$http.post("/api/new/deck/select/search", {
                params: {
                    deck_nation: this.text_nation,
                    keyword: this.keyword
                }
            }).then((res) => {
                // console.log("search", res)
                this.list = res.data.slice(0,3)
            })
        },

        read(item){
            if(item.status == "공개"){
                this.$router.push('/deck/read?id='+item.id)
            }else{
                if(item.writer == this.$store.state.user_id){
                    this.$router.push('/deck/read?id='+item.id)
                }else{
                    alert("비공개된 덱은 작성자 외에는 볼 수 없습니다.")
                }
            }
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

</style>
<style scoped>
::v-deep .my-carousel .v-carousel__navigation {
  background-color: transparent !important; /* 배경을 투명하게 설정 */
  box-shadow: none !important; /* 그림자 제거 */
}

::v-deep .my-carousel .v-carousel__navigation {
  background-color: transparent !important;
  box-shadow: none !important; /* 그림자 제거 */
}

.v-btn::before {
  background-color: transparent !important;
  box-shadow: none !important; /* 그림자 제거 */
}
</style>