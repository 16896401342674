<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 카드 이름 표시 -->
            <Name 
                :card_id="card_id"
                :text_nation="text_nation"
            />

            <!-- TEXT 언어선택 -->
            <v-sheet
                class="d-flex justify-center mt-3 mb-5"
                v-if="$route.path.startsWith('/card/detail')"
            >
                <v-btn
                    depressed
                    text
                    tile
                    height="48"
                    class="pl-1 pr-2 mr-1"
                    v-ripple="false"
                    style="font-size:20px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    :color="review_text_nation == 'kr'? 'primary':''"
                    :style="review_text_nation == 'kr'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                    :dark="review_text_nation == 'kr'"
                    @click="$emit('text_nation_changed', 'kr')"
                >
                    한글
                </v-btn>
                <v-btn
                    depressed
                    text
                    tile
                    height="48"
                    class="pl-1 pr-2 mr-1"
                    v-ripple="false"
                    style="font-size:20px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    :color="review_text_nation == 'en'? 'primary':''"
                    :style="review_text_nation == 'en'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                    :dark="review_text_nation == 'en'"
                    @click="$emit('text_nation_changed', 'en')"
                >
                    영어
                </v-btn>
                <v-btn
                    depressed
                    text
                    tile
                    height="48"
                    class="pl-1 pr-2"
                    v-ripple="false"
                    style="font-size:20px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    :color="review_text_nation == 'jp'? 'primary':''"
                    :style="review_text_nation == 'jp'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                    :dark="review_text_nation == 'jp'"
                    @click="$emit('text_nation_changed', 'jp')"
                >
                    일어
                </v-btn>
            </v-sheet>

            <!-- 평점 상단 -->
            <v-sheet class="d-flex justify-space-around my-10">
                <!-- 성능 평점 -->
                <v-menu offset-y open-on-hover nudge-bottom="10">
                    <template v-slot:activator="{ on: menu }">
                        <v-sheet width="30%" class="shadow px-2 py-5 text-center rounded-15" v-on="{ ...menu }">
                            <p class="font-weight-bold text-caption mb-2">
                                이 카드의 성능은?
                            </p>
                            <v-sheet color="transparent">
                                <p class="text-caption #615DFA--text text--darken-2 mb-1">
                                    {{summary.perform_avg==null? 0 : parseFloat(summary.perform_avg).toFixed(2)}} ({{summary.perform_count}}명 참여)
                                </p>
                                <v-rating
                                    v-model="summary.perform_avg"
                                    readonly
                                    half-increments
                                    background-color="#615DFA"
                                    color="#615DFA"
                                ></v-rating>
                                <p class="mt-3 mb-0" style="font-size:15px; letter-spacing:-0.2px;">
                                    " {{summary_perform_avg_text}} "
                                </p>
                            </v-sheet>
                        </v-sheet>
                    </template>
                    <v-card class="pa-3 d-flex justify-center">
                        <v-sheet class="text-caption">
                            <p class="ma-0 blue--text text--darken-1">1점 : "이 카드를 쓸 바엔 다른 카드 쓰세요"</p>
                            <p class="ma-0 blue--text text--darken-2">2점 : "대체할 게 없다면 한 번 사용해볼 정도?"</p>
                            <p class="ma-0 blue--text text--darken-3">3점 : "있으면 쓰는 카드"</p>
                            <p class="ma-0 blue--text text--darken-4">4점 : "좋은 카드에요, 사용해보세요"</p>
                            <p class="ma-0 blue--text text--darken-4">5점 : "필수 카드, 4장 꽉꽉 채워서 덱에 넣으세요"</p>
                        </v-sheet>
                    </v-card>
                </v-menu>  

                <!-- 수집 평점 -->
                <v-menu offset-y open-on-hover nudge-bottom="10">
                    <template v-slot:activator="{ on: menu }">
                        <v-sheet width="30%" class="shadow px-2 py-5 text-center rounded-15" v-on="{ ...menu }">
                            <p class="font-weight-bold text-caption mb-2">
                                이 카드의 수집 난이도는?
                            </p>
                            <v-sheet color="transparent">
                                <p class="text-caption grey--text text--darken-2 mb-1">
                                    {{summary.collect_avg==null? 0 : parseFloat(summary.collect_avg).toFixed(2)}} ({{summary.collect_count}}명 참여)
                                </p>
                                <v-rating
                                    v-model="summary.collect_avg"
                                    readonly
                                    half-increments
                                    background-color="#615DFA"
                                    color="#615DFA"
                                ></v-rating>
                                <p class="mt-3 mb-0" style="font-size:15px; letter-spacing:-0.2px;">
                                    " {{summary_collect_avg_text}} "
                                </p>
                            </v-sheet>
                        </v-sheet>
                    </template>
                    <v-card class="pa-3 d-flex justify-center">
                        <v-sheet class="text-caption">
                            <p class="ma-0 blue--text text--darken-1">1점 : "너도 나도 10장 이상 가지고 있는 카드"</p>
                            <p class="ma-0 blue--text text--darken-2">2점 : "어렵지 않게 수집할 수 있어요"</p>
                            <p class="ma-0 blue--text text--darken-3">3점 : "어렵지만 수집할 만해요"</p>
                            <p class="ma-0 blue--text text--darken-4">4점 : "구하기가 하늘에 별따기에요"</p>
                            <p class="ma-0 blue--text text--darken-4">5점 : "존재 하기는 하는 걸까?"</p>
                        </v-sheet>
                    </v-card>
                </v-menu>   

                <!-- 전체 평점 -->
                <v-sheet width="30%" class="shadow px-2 py-5 text-center rounded-15">
                    <p class="font-weight-bold text-caption mb-2">
                        이 카드의 전체 평점은?
                    </p>
                    <v-sheet color="transparent">
                        <p class="text-caption grey--text text--darken-2 mb-1">
                            평점 {{summary.total_avg==null? 0 : parseFloat(summary.total_avg).toFixed(2)}} ({{summary.total_count}}명 참여)
                        </p>
                        <v-rating
                            v-model="summary.total_avg"
                            readonly
                            half-increments
                            background-color="#615DFA"
                            color="#615DFA"
                        ></v-rating>
                        <p class="mt-3 mb-0" style="font-size:15px; margin-top:6px;">
                            성능평점과 수집평점의 합산 평점입니다
                        </p>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
            
            <v-sheet class="mx-16 px-10 mb-6">
                <!-- 평가 목록 -->
                <v-sheet v-for="item in review_list" :key="item.id">
                    <!-- 리뷰 상단 -->
                    <div class="d-flex mt-2 mx-2">
                        <!-- 프로필 & 기능 -->
                        <div class="d-flex align-center">
                            <!-- 프로필 -->
                            <Avatar v-if="item.user_id" :user="item.user_id" :color="'black'" />

                            <!-- 닉네임 -->
                            <p class="ml-2 my-0 font-weight-bold" color="#424361" style="font-size:13px; line-height:18px !important;">
                                {{item.nickname}}
                            </p>

                            <!-- 댓글 작성일 -->
                            <font class="ml-2 mr-2" style="font-size:13px; color:#ADAFCA; line-height:18px;">
                                {{new Date(item.created).toLocaleString()}}
                            </font>

                            <!-- 삭제, 수정 -->
                            <div class="d-flex">
                                <!-- 삭제 -->
                                <v-sheet
                                    v-if="item.user_id==$store.state.user_id || $store.state.type=='관리자'"
                                    title="삭제"
                                    width="18"
                                    height="18"
                                    class="mx-1"
                                    style="cursor:pointer;"
                                    @click="review_delete(item)"
                                >
                                    <v-img contain src="@/assets/board/delete.svg"></v-img>
                                </v-sheet>

                                <!-- 수정 -->
                                <v-sheet
                                    v-if="!item.isUpdating && (item.user_id==$store.state.user_id || $store.state.type=='관리자')"
                                    title="수정"
                                    width="18"
                                    height="18"
                                    class="mx-1"
                                    style="cursor:pointer;"
                                    @click="review_update(item)"
                                >
                                    <v-img contain src="@/assets/board/update.svg"></v-img>
                                </v-sheet>

                                <!-- 블라인드 처리 -->
                                <v-sheet
                                    v-if="item.status!='블라인드' && $store.state.type=='관리자'"
                                    title="블라인드 처리"
                                    width="18"
                                    height="18"
                                    class="mx-1"
                                    style="cursor:pointer;"
                                    @click="review_blind(item)"
                                >
                                    <v-img contain src="@/assets/board/blind.svg"></v-img>
                                </v-sheet>
                            </div>
                        </div>
                        <v-spacer></v-spacer>

                        <!-- 평점 -->
                        <div class="mt-5">
                            <!-- 성능 평점 -->
                            <div class="d-flex align-center">
                                <p v-if="item.rate_perform_check" class="mt-1 mb-0 mr-2" style="font-size:13px;">성능</p>
                                <v-rating
                                    v-if="item.rate_perform_check"
                                    v-model="item.rate_perform"
                                    :readonly="!item.isUpdating"
                                    half-increments
                                    background-color="grey"
                                    color="#615DFA"
                                    size="16"
                                    style="height:25px;"
                                ></v-rating>
                            </div>

                            <!-- 수집 평점 -->
                            <div class="d-flex align-center">
                                <p v-if="item.rate_collect_check" class="mt-1 mb-0 mr-2" style="font-size:13px;">수집</p>
                                <v-rating
                                    v-if="item.rate_collect_check"
                                    v-model="item.rate_collect"
                                    :readonly="!item.isUpdating"
                                    half-increments
                                    background-color="grey"
                                    color="#615DFA"
                                    size="16"
                                    style="height:25px;"
                                ></v-rating>
                            </div>
                        </div>
                    </div>

                    <!-- 리뷰 내용 -->
                    <div>
                        <!-- 블라인드됨 -->
                        <v-sheet 
                            v-if="item.status=='블라인드'"
                            class="py-3"
                        >
                            <p class="text-caption text-center my-2">해당 평가는 관리자에 의해 블라인드 처리되었습니다.</p>
                            <v-sheet class="d-flex justify-end">
                                <v-btn
                                    v-if="$store.state.type=='관리자'"
                                    class="rounded-10 mr-2"
                                    color="#23D2E2"
                                    small
                                    dark
                                    depressed
                                    @click="review_unblind(item)"
                                >   
                                    블라인드 해제
                                </v-btn>
                            </v-sheet>
                        </v-sheet>

                        <!-- 기본 -->
                        <v-sheet v-if="item.status!='블라인드' && !item.isUpdating" class="ml-10 mb-4" width="620">
                            <v-textarea
                                dense
                                hide-details
                                flat
                                solo
                                v-model="item.content"
                                readonly
                                rows="1"
                                style="font-size:13px;"
                                auto-grow
                            ></v-textarea>
                        </v-sheet>

                        <!-- 수정 시 -->
                        <div>
                            <!-- 내용 -->
                            <v-sheet v-if="item.isUpdating" outlined class="d-flex justify-center py-1">
                                <v-textarea
                                    hide-details
                                    flat
                                    solo
                                    v-model="item.content"
                                    placeholder="평가 내용을 입력해주세요"
                                ></v-textarea>
                            </v-sheet>

                            <!-- 수정, 취소 버튼 -->
                            <div v-if="item.isUpdating" class="mt-2 mb-4 d-flex justify-end">
                                <v-btn
                                    class="rounded-10"
                                    width="90"
                                    height="35"
                                    dark
                                    depressed
                                    color="primary"
                                    @click="review_update_submit(item)"
                                >
                                    수정
                                </v-btn>
                                <v-btn
                                    class="rounded-10 ml-2"
                                    width="90"
                                    height="35"
                                    dark
                                    depressed
                                    color="primary"
                                    @click="review_update(item)"
                                >
                                    취소
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <v-divider></v-divider>
                </v-sheet>

                <!-- 평가 달기 -->
                <div class="mt-10">
                    <v-sheet class="d-flex align-center justify-center font-weight-medium mb-2">
                        <!-- 성능 평점 -->
                        <p style="font-size:15px;" class="mt-1 mb-0">이 카드의 성능은?</p>

                        <!-- 입력 여부 -->
                        <v-checkbox
                            dense
                            hide-details
                            class="ml-2 mb-2px"
                            color="#615DFA"
                            v-model="review.rate_perform_check"
                            @change="perform_check_changed()"
                        ></v-checkbox>
                        <!-- 평점 -->
                        <v-rating
                            :disable="review.rate_perform_check"
                            :readonly="!review.rate_perform_check"
                            v-model="review.rate_perform"
                            half-increments
                            background-color="grey"
                            color="#615DFA"
                            size="20"
                            style="height:23px;"
                        ></v-rating>

                        <!-- 수집 평점 -->
                        <p style="font-size:15px;" class="mt-1 mb-0 ml-4">이 카드의 수집 난이도는?</p>

                        <!-- 입력 여부 -->
                        <v-checkbox
                            dense
                            hide-details
                            class="ml-2 mb-2px"
                            color="#615DFA"
                            v-model="review.rate_collect_check"
                            @change="collect_check_changed()"
                        ></v-checkbox>

                        <!-- 평점 -->
                        <v-rating
                            :disable="review.rate_collect_check"
                            :readonly="!review.rate_collect_check"
                            v-model="review.rate_collect"
                            half-increments
                            background-color="grey"
                            color="#615DFA"
                            size="20"
                            style="height:23px;"
                        ></v-rating>
                    </v-sheet>

                    <!-- 평가 내용 -->
                    <v-sheet outlined class="d-flex justify-center py-1">
                        <v-textarea
                            hide-details
                            flat
                            solo
                            v-model="review.content"
                            placeholder="평가 내용을 입력해주세요"
                        ></v-textarea>
                    </v-sheet>

                    <!-- 제출 -->
                    <v-sheet class="d-flex justify-end mt-3">
                        <v-btn
                            dark
                            depressed
                            color="primary"
                            class="rounded-10"
                            @click="reviewSubmit()"
                        >
                            평가 참여
                        </v-btn>
                    </v-sheet>
                </div>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 카드 이름 표시 -->
            <Name 
                :card_id="card_id"
                :text_nation="text_nation"
            />

            <!-- TEXT 언어선택 -->
            <v-sheet
                v-if="$route.path.startsWith('/card/detail')"
                class="d-flex mt-3 mb-5"
            >
                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15 mr-1"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    :color="review_text_nation == 'kr'? '#615DFA':'#ADAFCA'"
                    @click="$emit('text_nation_changed', 'kr')"
                >
                    한글
                </v-btn>
                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15 mr-1"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    :color="review_text_nation == 'en'? '#615DFA':'#ADAFCA'"
                    @click="$emit('text_nation_changed', 'en')"
                >
                    영어
                </v-btn>
                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    :color="review_text_nation == 'jp'? '#615DFA':'#ADAFCA'"
                    @click="$emit('text_nation_changed', 'jp')"
                >
                    일어
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    color="primary"
                    @click="scrollToTop"
                >
                    맨 위로
                </v-btn>
            </v-sheet>

            <!-- 평점 상단 -->
            <v-sheet class="my-4 px-4">
                <!-- 성능 평점 -->
                <v-menu offset-y open-on-hover nudge-bottom="10">
                    <template v-slot:activator="{ on: menu }">
                        <v-sheet class="shadow px-2 py-5 text-center rounded-15 mb-4" v-on="{ ...menu }">
                            <p class="font-weight-bold mb-0" style="font-size:15px;">
                                이 카드의 성능은?
                            </p>
                            <v-sheet color="transparent">
                                <p class="text-caption #615DFA--text text--darken-2 mb-0">
                                    평점 {{summary.perform_avg==null? 0 : parseFloat(summary.perform_avg).toFixed(2)}} ({{summary.perform_count}}명 참여)
                                </p>
                                <v-rating
                                    v-model="summary.perform_avg"
                                    readonly
                                    half-increments
                                    background-color="#615DFA"
                                    color="#615DFA"
                                ></v-rating>
                                <p class="mt-3 mb-0" style="font-size:15px; letter-spacing:-0.5px;">
                                    "{{summary_perform_avg_text}}"
                                </p>
                            </v-sheet>
                        </v-sheet>
                    </template>
                    <v-card class="pa-3 d-flex justify-center">
                        <v-sheet class="text-caption">
                            <p class="ma-0 blue--text text--darken-1">1점 : "이 카드를 쓸 바엔 다른 카드 쓰세요"</p>
                            <p class="ma-0 blue--text text--darken-2">2점 : "대체할 게 없다면 한 번 사용해볼 정도?"</p>
                            <p class="ma-0 blue--text text--darken-3">3점 : "있으면 쓰는 카드"</p>
                            <p class="ma-0 blue--text text--darken-4">4점 : "좋은 카드에요, 사용해보세요"</p>
                            <p class="ma-0 blue--text text--darken-4">5점 : "필수 카드, 4장 꽉꽉 채워서 덱에 넣으세요"</p>
                        </v-sheet>
                    </v-card>
                </v-menu>  

                <!-- 수집 평점 -->
                <v-menu offset-y open-on-hover nudge-bottom="10">
                    <template v-slot:activator="{ on: menu }">
                        <v-sheet class="shadow px-2 py-5 text-center rounded-15 mb-4" v-on="{ ...menu }">
                            <p class="font-weight-bold mb-0" style="font-size:15px;">
                                이 카드의 수집 난이도는?
                            </p>
                            <v-sheet color="transparent">
                                <p class="text-caption grey--text text--darken-2 mb-0">
                                    평점 {{summary.collect_avg==null? 0 : parseFloat(summary.collect_avg).toFixed(2)}} ({{summary.collect_count}}명 참여)
                                </p>
                                <v-rating
                                    v-model="summary.collect_avg"
                                    readonly
                                    half-increments
                                    background-color="#615DFA"
                                    color="#615DFA"
                                ></v-rating>
                                <p class="mt-3 mb-0" style="font-size:15px; letter-spacing:-0.5px;">
                                    "{{summary_collect_avg_text}}"
                                </p>
                            </v-sheet>
                        </v-sheet>
                    </template>
                    <v-card class="pa-3 d-flex justify-center">
                        <v-sheet class="text-caption">
                            <p class="ma-0 blue--text text--darken-1">1점 : "너도 나도 10장 이상 가지고 있는 카드"</p>
                            <p class="ma-0 blue--text text--darken-2">2점 : "어렵지 않게 수집할 수 있어요"</p>
                            <p class="ma-0 blue--text text--darken-3">3점 : "어렵지만 수집할 만해요"</p>
                            <p class="ma-0 blue--text text--darken-4">4점 : "구하기가 하늘에 별따기에요"</p>
                            <p class="ma-0 blue--text text--darken-4">5점 : "존재 하기는 하는 걸까?"</p>
                        </v-sheet>
                    </v-card>
                </v-menu>   

                <!-- 전체 평점 -->
                <v-sheet class="shadow px-2 py-5 text-center rounded-15">
                    <p class="font-weight-bold mb-0" style="font-size:15px;">
                        이 카드의 전체 평점은?
                    </p>
                    <v-sheet color="transparent">
                        <p class="text-caption grey--text text--darken-2 mb-0">
                            평점 {{summary.total_avg==null? 0 : parseFloat(summary.total_avg).toFixed(2)}} ({{summary.total_count}}명 참여)
                        </p>
                        <v-rating
                            v-model="summary.total_avg"
                            readonly
                            half-increments
                            background-color="#615DFA"
                            color="#615DFA"
                        ></v-rating>
                        <p class="mt-3 mb-0" style="font-size:15px; margin-top:6px;">
                            성능평점과 수집평점의 합산 평점입니다
                        </p>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
            <v-sheet class="mt-8 mb-6 px-2">
                <v-divider class="mb-4"></v-divider>
                <!-- 평가 목록 -->
                <v-sheet
                    v-for="item in review_list" :key="item.id"
                    style="position:relative;"
                >
                    <!-- 리뷰 상단 -->
                    <div>
                        <!-- 프로필 & 기능 -->
                        <div class="d-flex align-center mr-1">
                            <!-- 프로필 -->
                            <Avatar v-if="item.user_id" :user="item.user_id" :color="'black'" />

                            <!-- 닉네임 -->
                            <p class="ml-2 my-0 font-weight-bold" color="#424361" style="font-size:13px; line-height:18px !important;">
                                {{item.nickname}}
                            </p>

                            <!-- 댓글 작성일 -->
                            <font class="ml-2 mr-2" style="font-size:10px; color:#ADAFCA; line-height:14px;">
                                {{new Date(item.created).toLocaleString()}}
                            </font>

                            <v-spacer></v-spacer>

                            <!-- 삭제, 수정 -->
                            <div class="d-flex">
                                <!-- 삭제 -->
                                <v-sheet
                                    v-if="item.user_id==$store.state.user_id || $store.state.type=='관리자'"
                                    title="삭제"
                                    width="18"
                                    height="18"
                                    class="mx-1"
                                    style="cursor:pointer;"
                                    @click="review_delete(item)"
                                >
                                    <v-img contain src="@/assets/board/delete.svg"></v-img>
                                </v-sheet>

                                <!-- 수정 -->
                                <v-sheet
                                    v-if="!item.isUpdating && (item.user_id==$store.state.user_id || $store.state.type=='관리자')"
                                    title="수정"
                                    width="18"
                                    height="18"
                                    class="mx-1"
                                    style="cursor:pointer;"
                                    @click="review_update(item)"
                                >
                                    <v-img contain src="@/assets/board/update.svg"></v-img>
                                </v-sheet>

                                <!-- 블라인드 처리 -->
                                <v-sheet
                                    v-if="item.status!='블라인드' && $store.state.type=='관리자'"
                                    title="블라인드 처리"
                                    width="18"
                                    height="18"
                                    class="mx-1"
                                    style="cursor:pointer;"
                                    @click="review_blind(item)"
                                >
                                    <v-img contain src="@/assets/board/blind.svg"></v-img>
                                </v-sheet>
                            </div>
                        </div>
                    </div>

                    <!-- 평점 -->
                    <div
                        class="ml-2 d-flex justify-end"
                        style="position:absolute; top:20px; right:4px; z-index:10;"
                    >
                        <!-- 성능 평점 -->
                        <div class="d-flex align-center">
                            <p v-if="item.rate_perform_check" class="mt-1 mb-0 mr-1" style="font-size:10px;">성능</p>
                            <v-rating
                                v-if="item.rate_perform_check"
                                v-model="item.rate_perform"
                                :readonly="!item.isUpdating"
                                half-increments
                                background-color="grey"
                                color="#615DFA"
                                size="12"
                                style="height:22px; margin-right:4px;"
                            ></v-rating>
                        </div>

                        <!-- 수집 평점 -->
                        <div class="d-flex align-center">
                            <p v-if="item.rate_collect_check" class="mt-1 mb-0 ml-1 mr-1" style="font-size:10px;">수집</p>
                            <v-rating
                                v-if="item.rate_collect_check"
                                v-model="item.rate_collect"
                                :readonly="!item.isUpdating"
                                half-increments
                                background-color="grey"
                                color="#615DFA"
                                size="12"
                                style="height:22px; margin-right:4px;"
                            ></v-rating>
                        </div>
                    </div>

                    <!-- 리뷰 내용 -->
                    <div
                        style="position:relative; margin-top:4px;"
                    >
                        <!-- 블라인드됨 -->
                        <v-sheet 
                            v-if="item.status=='블라인드'"
                            class="py-3"
                        >
                            <p class="text-caption text-center my-2">해당 평가는 관리자에 의해 블라인드 처리되었습니다.</p>
                            <v-sheet class="d-flex justify-end">
                                <v-btn
                                    v-if="$store.state.type=='관리자'"
                                    class="rounded-10 mr-2"
                                    color="#23D2E2"
                                    small
                                    dark
                                    depressed
                                    @click="review_unblind(item)"
                                >   
                                    블라인드 해제
                                </v-btn>
                            </v-sheet>
                        </v-sheet>

                        <!-- 기본 -->
                        <v-sheet v-if="item.status!='블라인드' && !item.isUpdating">
                            <v-textarea
                                dense
                                hide-details
                                flat
                                solo
                                v-model="item.content"
                                readonly
                                rows="1"
                                style="font-size:13px;"
                                auto-grow
                            ></v-textarea>
                        </v-sheet>

                        <!-- 수정 시 -->
                        <div>
                            <!-- 내용 -->
                            <v-sheet v-if="item.isUpdating" outlined class="d-flex justify-center py-1">
                                <v-textarea
                                    hide-details
                                    flat
                                    solo
                                    v-model="item.content"
                                    placeholder="평가 내용을 입력해주세요"
                                ></v-textarea>
                            </v-sheet>

                            <!-- 수정, 취소 버튼 -->
                            <div v-if="item.isUpdating" class="mt-2 mb-4 d-flex justify-end">
                                <v-btn
                                    class="rounded-10"
                                    width="90"
                                    height="35"
                                    dark
                                    depressed
                                    color="primary"
                                    @click="review_update_submit(item)"
                                >
                                    수정
                                </v-btn>
                                <v-btn
                                    class="rounded-10 ml-2"
                                    width="90"
                                    height="35"
                                    dark
                                    depressed
                                    color="primary"
                                    @click="review_update(item)"
                                >
                                    취소
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <v-divider></v-divider>
                </v-sheet>

                <!-- 평가 달기 -->
                <div class="mt-4">
                    <v-sheet class="d-flex align-center font-weight-bold" style="margin-bottom:-8px;">
                        <!-- 성능 평점 -->
                        <p style="font-size:12px;" class="mt-1 mb-0">이 카드의 성능은?</p>

                        <!-- 입력 여부 -->
                        <v-checkbox
                            dense
                            hide-details
                            class="ml-2 mb-2px"
                            color="#615DFA"
                            v-model="review.rate_perform_check"
                            @change="perform_check_changed()"
                        ></v-checkbox>
                        <!-- 평점 -->
                        <v-rating
                            :disable="review.rate_perform_check"
                            :readonly="!review.rate_perform_check"
                            v-model="review.rate_perform"
                            half-increments
                            background-color="grey"
                            color="#615DFA"
                            size="16"
                            style="height:23px; margin-left:-8px;"
                        ></v-rating>
                    </v-sheet>
                    <v-sheet class="d-flex align-center font-weight-bold">
                        <!-- 수집 평점 -->
                        <p style="font-size:12px;" class="mt-1 mb-0">이 카드의 수집 난이도는?</p>

                        <!-- 입력 여부 -->
                        <v-checkbox
                            dense
                            hide-details
                            class="ml-2 mb-2px"
                            color="#615DFA"
                            v-model="review.rate_collect_check"
                            @change="collect_check_changed()"
                        ></v-checkbox>

                        <!-- 평점 -->
                        <v-rating
                            :disable="review.rate_collect_check"
                            :readonly="!review.rate_collect_check"
                            v-model="review.rate_collect"
                            half-increments
                            background-color="grey"
                            color="#615DFA"
                            size="16"
                            style="height:23px; margin-left:-8px;"
                        ></v-rating>
                    </v-sheet>

                    <!-- 평가 내용 -->
                    <v-sheet class="d-flex justify-center py-1">
                        <v-textarea
                            hide-details
                            outlined
                            rows="2"
                            v-model="review.content"
                            class="mr-2"
                            placeholder="평가 내용을 입력해주세요(평가 작성시 5P 지급)"
                        ></v-textarea>
                        <v-btn
                            width="60"
                            height="66"
                            dark
                            depressed
                            color="primary"
                            class="rounded-10"
                            style="font-size:12px; font-weight:400;"
                            @click="reviewSubmit()"
                        >
                            <div>
                                평가<br/>
                                참여
                            </div>
                        </v-btn>
                    </v-sheet>
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Name from './CardReview/Name'
import Avatar from "@/components/Avatar"
import PointModule from '@/components/PointModule.js'

export default {
    components: {
        Name,
        Avatar
    },

    props: ["card_id", "text_nation"],

    data: () => ({
        review_list: [],

        summary: {
            perform_avg: 0,
            perform_count: 0,
            collect_avg: 0,
            collect_count: 0,
            total_avg: 0,
            total_count: 0
        },

        review: {
            rate_perform_check: false,
            rate_collect_check: false,
            rate_perform: 0,
            rate_collect: 0,
            content: ""
        },

        review_text_nation: ""
    }),

    mounted(){
        // 현재 카드 평점
        this.load_sum()
    },

    watch: {
        // 국가 변경시
        text_nation(){
            this.review_text_nation = this.text_nation
            this.load_sum()
        },
    },

    computed: {
        // 성능 평점 문구
        summary_perform_avg_text(){
            switch(Math.floor(this.summary.perform_avg)){
                case 0 : return "이 카드에 평점을 입력해주세요!"
                case 1 : return "이 카드를 쓸 바엔 다른 카드 쓰세요"
                case 2 : return "대체할 게 없다면 한 번 사용해볼 정도?"
                case 3 : return "있으면 쓰는 카드"
                case 4 : return "좋은 카드에요, 사용해보세요"
                case 5 : return "필수 카드, 4장 꽉꽉 채워서 덱에 넣으세요"
            }
        },

        // 수집 평점 문구
        summary_collect_avg_text(){
            switch(Math.floor(this.summary.collect_avg)){
                case 0 : return "이 카드에 평점을 입력해주세요!"
                case 1 : return "너도 나도 10장 이상 가지고 있는 카드"
                case 2 : return "어렵지 않게 수집할 수 있어요"
                case 3 : return "어렵지만 수집할 만해요"
                case 4 : return "구하기가 하늘에 별따기에요"
                case 5 : return "존재 하기는 하는 걸까?"
            }
        },

        computed_total_avg(){
            return (this.summary.perform_avg + this.summary.collect_avg / 2).toFixed(2)
        }
    },

    methods: {
        scrollToTop(){
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        
        // 현재 카드 평점
        load_sum(){
            // 전체 통계
            this.$http.post('/api/card/detail/review/list/avg', {
                params: {
                    card_id: this.$route.query.id,
                    text_nation: this.text_nation
                }
            }).then(res => {
                // console.log("summary", res)
                this.summary = res.data[0]
            })

            // 리뷰 목록
            this.$http.post('/api/card/detail/review/list', {
                params: {
                    card_id: this.$route.query.id,
                    text_nation: this.text_nation
                }
            }).then(res => {
                this.review_list = res.data
            })
        },

        // 리뷰 수정 모드 스위치
        review_update(item){
            item.isUpdating = !item.isUpdating
        },

        // 리뷰 수정
        review_update_submit(item){
            if(!item.rate_perform_check && !item.rate_collect_check)
            {
                alert("카드 댓글을 달기 위해서는 성능과 수집 평점 중 1개 이상 참여하셔야 합니다.")
            }
            else
            {
                this.$http.post('/api/card/detail/review/update', {
                    params: {
                        card_id: this.$route.query.id,
                        text_nation: this.text_nation,
                        writer: this.$store.state.user_id,
                        rate_perform_check: item.rate_perform_check,
                        rate_perform: item.rate_perform,
                        rate_collect_check: item.rate_collect_check,
                        rate_collect: item.rate_collect,
                        content: item.content,
                        id: item.id
                    }
                }).then(() => {
                    this.load_sum()
                })
            }
        },

        // 리뷰 삭제
        review_delete(item){
            if(confirm("해당 리뷰를 삭제하시겠습니까?")){
                this.$http.post('/api/card/detail/review/delete', {
                    params: {
                        id: item.id
                    }
                }).then(res => {
                    this.load_sum()

                    // 포인트 적립 롤백
                    PointModule.amountCheck('카드랭킹', '카드 리뷰 쓰기').then((res) => {
                        PointModule.Update(this.$store.state.user_id, -res.data[0].amount)
                        PointModule.Record(this.$store.state.user_id, '카드랭킹', '카드 리뷰 쓰기', this.$route.query.id, -res.data[0].amount)
                    })
                })
            }
        },

        // 리뷰 블라인드
        review_blind(item){
            this.$http.post('/api/card/detail/review/blind', {
                params: {
                    id: item.id
                }
            }).then(res => {
                this.load_sum()
                alert("리뷰가 블라인드되었습니다.")
            })
        },

        // 리뷰 블라인드 해제
        review_unblind(item){
            this.$http.post('/api/card/detail/review/unblind', {
                params: {
                    id: item.id
                }
            }).then(res => {
                this.load_sum()
                alert("블라인드 해제되었습니다.")
            })
        },

        // 리뷰 입력
        reviewSubmit(){
            if(!this.$store.state.user_id)
            {
                alert("카드 평가는 로그인 이후에만 가능합니다.")
            }
            else if(this.$store.state.type=='밴 유저')
            {
                alert('밴 유저는 밴 기간동안 리뷰가 제한됩니다.')
            }
            else if(!this.review.rate_perform_check && !this.review.rate_collect_check)
            {
                alert("카드 평가를 달기 위해서는 성능과 수집 평점 중 1개 이상 참여하셔야 합니다.")
            }
            else
            {
                this.$http.post('/api/card/detail/review/insert', {
                    params: {
                        card_id: this.$route.query.id,
                        text_nation: this.text_nation,
                        writer: this.$store.state.user_id,
                        rate_perform_check: this.review.rate_perform_check,
                        rate_perform: this.review.rate_perform,
                        rate_collect_check: this.review.rate_collect_check,
                        rate_collect: this.review.rate_collect,
                        content: this.review.content
                    }
                }).then(() => {
                    this.load_sum()
                })

                // 포인트 적립
                PointModule.amountCheck('카드랭킹', '카드 리뷰 쓰기').then((res) => {
                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                    PointModule.Record(this.$store.state.user_id, '카드랭킹', '카드 리뷰 쓰기', this.$route.query.id, res.data[0].amount)
                })

                // 입력란 초기화
                this.review = {
                    rate_perform_check: false,
                    rate_collect_check: false,
                    rate_perform: 0,
                    rate_collect: 0,
                    content: ""
                }
            }
        },

        // 리뷰 성능 평점 상태 변경
        perform_check_changed(){
            if(!this.review.rate_perform_check){
                this.review.rate_perform = 0
            }
        },
        
        // 리뷰 수집 평점 상태 변경
        collect_check_changed(){
            if(!this.review.rate_collect_check){
                this.review.rate_collect = 0
            }
        },

        // 리뷰 수정하기 > 성능 평점 상태 변경
        item_perform_check_changed(item){
            if(!item.rate_perform_check){
                item.rate_perform = 0
            }
        },

        // 리뷰 수정하기 > 수집 평점 상태 변경
        item_collect_check_changed(item){
            if(!item.rate_collect_check){
                item.rate_collect = 0
            }
        }
    }
}
</script>
<style scoped>
.shadow{
    box-shadow: 0px 0px 20px 10px #F1F2F6 !important;
}

::v-deep .v-textarea.v-text-field--enclosed .v-text-field__slot {
    min-height:66px !important;
    font-size:12px !important;
    line-height:14px !important;
}
</style>