<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <div 
                v-if="$store.state.is_logined && $store.state.type!='밴 유저'"
                class="d-flex"
            >
                <!-- 이미지 등록 신청 -->
                <v-btn
                    v-if="$store.state.type!='운영진' && $store.state.type!='매니저' && $store.state.type !='일어알바'"
                    class="mr-2 rounded-10 font-weight-bold"
                    width="181"
                    height="50"
                    style="font-size:15px;"
                    depressed
                    color="white"
                    @click="dialog.image = true"
                >
                    <v-icon size="24" left color="primary">mdi-image</v-icon>
                    이미지 등록 신청
                </v-btn>

                <!-- 정보 입력/수정 -->
                <v-btn
                    v-if="$store.state.type!='운영진' && $store.state.type!='매니저' && $store.state.type !='일어알바'"
                    class="mr-2 rounded-10 px-4 font-weight-bold"
                    height="50"
                    style="font-size:15px;"
                    depressed
                    color="white"
                    @click="dialog.info = true"
                >
                    <v-icon size="24" left color="primary">mdi-image</v-icon>
                    정보 입력/수정
                </v-btn>

                <!-- 오류제보 -->
                <v-btn
                    v-if="$store.state.type!='운영진' && $store.state.type!='매니저' && $store.state.type !='일어알바'"
                    class="mr-2 rounded-10 px-6 font-weight-bold"
                    height="50"
                    style="font-size:15px;"
                    depressed
                    color="white"
                    @click="dialog.bug = true"
                >
                    <v-icon size="24" left color="primary">mdi-circle-edit-outline</v-icon>
                    오류제보
                </v-btn>

                <!-- 카드 수정내역 -->
                <UpdateLog/>

                <!-- 카드주소 복사 -->
                <v-btn
                    v-if="$store.state.type!='관리자'"
                    class="ml-2 mr-2 rounded-10 px-6 font-weight-bold"
                    height="50"
                    style="font-size:15px;"
                    depressed
                    color="white"
                    @click="copyURL()"
                >
                    <v-icon class="mr-2 mt-2px" size="24" left color="#615DFA">mdi-application-outline</v-icon>
                    카드주소 복사
                </v-btn>

                <!-- 카드ID 복사 -->
                <v-btn
                    v-if="$store.state.type!='관리자'"
                    class="rounded-10 px-6 font-weight-bold"
                    height="50"
                    style="font-size:15px;"
                    depressed
                    color="white"
                    @click="copyID()"
                >
                    <v-icon class="mr-2 mt-2px" size="24" left color="#615DFA">mdi-application-variable-outline</v-icon>
                    카드ID 복사
                </v-btn>
            
                <!-- 이미지 등록 신청 (dialog) -->
                <v-dialog
                    v-model="dialog.image"
                    width="auto"
                    content-class="rounded-15"
                >
                    <ImageProvide
                        @close="dialog.image = false"
                    />
                </v-dialog>

                <!-- 정보 입력/수정 (dialog)  -->
                <v-dialog
                    v-model="dialog.info"
                    width="auto"
                    content-class="rounded-15"
                >
                    <ProvideCard 
                        :card_id="$route.query.id"
                        @close="dialog.info = false"
                    />
                </v-dialog>

                <!-- 오류제보 (dialog)  -->
                <v-dialog
                    v-model="dialog.bug"
                    width="auto"
                    content-class="rounded-15"
                >
                    <BugProvide
                        :card="card"
                        @close="dialog.bug = false"
                    />
                </v-dialog>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <div 
                v-if="$store.state.is_logined && $store.state.type!='밴 유저'"
                class="d-flex justify-space-between"
            >
                <!-- 이미지 등록 신청 -->
                <v-btn
                    v-if="$store.state.type!='운영진' && $store.state.type!='매니저' && $store.state.type !='일어알바'"
                    class="rounded-10 mr-1"
                    width="54"
                    height="54"
                    style="font-size:9px; font-weight:400;"
                    depressed
                    small
                    color="white"
                    @click="dialog.image = true"
                >
                    <div class="text-center">
                        <v-icon size="16" color="primary" class="d-block mb-1">
                            mdi-image
                        </v-icon>
                        등록신청
                        <p style="font-size:7px; color:#23D2E2; margin-bottom:0px;">
                            50~200P
                        </p>
                    </div>
                </v-btn>

                <!-- 정보 입력/수정 -->
                <v-btn
                    v-if="$store.state.type!='운영진' && $store.state.type!='매니저' && $store.state.type !='일어알바'"
                    class="rounded-10 mr-1"
                    width="54"
                    height="54"
                    style="font-size:9px; font-weight:400;"
                    depressed
                    small
                    color="white"
                    @click="dialog.info = true"
                >
                    <div class="text-center">
                        <v-icon size="16" color="primary" class="d-block mb-1">
                            mdi-post-outline
                        </v-icon>
                        정보수정
                        <p style="font-size:7px; color:#23D2E2; margin-bottom:0px;">
                            15~100P
                        </p>
                    </div>
                </v-btn>

                <!-- 오류제보 -->
                <v-btn
                    v-if="$store.state.type!='운영진' && $store.state.type!='매니저' && $store.state.type !='일어알바'"
                    class="rounded-10 mr-1"
                    width="54"
                    height="54"
                    style="font-size:9px; font-weight:400;"
                    depressed
                    small
                    color="white"
                    @click="dialog.bug = true"
                >
                    <div class="text-center">
                        <v-icon size="16" color="primary" class="d-block mb-1">
                            mdi-help-circle-outline
                        </v-icon>
                        오류제보
                        <p style="font-size:7px; color:#23D2E2; margin-bottom:0px;">
                            10~100P
                        </p>
                    </div>
                </v-btn>

                <!-- 카드 수정내역 -->
                <UpdateLog/>

                <!-- 카드주소 복사 -->
                <v-btn
                    v-if="$store.state.type=='관리자'"
                    class="ml-1 mr-1 rounded-10"
                    width="54"
                    height="54"
                    style="font-size:9px; font-weight:400;"
                    depressed
                    small
                    color="white"
                    @click="copyURL()"
                >
                    <div class="text-center">
                        <v-icon size="16" color="#615DFA" class="d-block mb-1">
                            mdi-content-copy
                        </v-icon>
                        주소복사
                        <p style="font-size:7px; color:#ADAFCA; margin-bottom:0px;">
                            URL
                        </p>
                    </div>
                </v-btn>

                <!-- 카드ID 복사 -->
                <v-btn
                    v-if="$store.state.type=='관리자'"
                    class="rounded-10"
                    width="54"
                    height="54"
                    style="font-size:9px; font-weight:400;"
                    depressed
                    small
                    color="white"
                    @click="copyID()"
                >
                    <div class="text-center">
                        <v-icon size="16" color="#615DFA" class="d-block mb-1">
                            mdi-content-copy
                        </v-icon>
                        ID복사
                        <p style="font-size:7px; color:#ADAFCA; margin-bottom:0px;">
                            카드ID
                        </p>
                    </div>
                </v-btn>
            </div>

            <!-- 이미지 등록 신청 (dialog) -->
            <v-dialog
                v-model="dialog.image"
                width="auto"
                content-class="rounded-15"
            >
                <ImageProvide
                    @close="dialog.image = false"
                />
            </v-dialog>

            <!-- 정보 입력/수정 (dialog)  -->
            <v-dialog
                v-model="dialog.info"
                width="auto"
                content-class="rounded-15"
            >
                <ProvideCard 
                    :card_id="$route.query.id"
                    @close="dialog.info = false"
                />
            </v-dialog>

            <!-- 오류제보 (dialog)  -->
            <v-dialog
                v-model="dialog.bug"
                width="auto"
                content-class="rounded-15"
            >
                <BugProvide
                    :card="card"
                    @close="dialog.bug = false"
                />
            </v-dialog>
        </div>
    </div>
</template>
<script>
// 카드 수정내역
import UpdateLog from './AdminButtons/UpdateLog'

// 이미지 등록 신청
import ImageProvide from './UserButtons/ImageProvide'

// 정보 입력/수정
import ProvideCard from '@/components/cardmodule/ProvideCard'

// 오류제보
import BugProvide from './UserButtons/BugProvide'

export default {
    props: ["card", "isPreCard", "cardImport"],

    components: {
        UpdateLog,
        ImageProvide,
        ProvideCard,
        BugProvide
    },

    data: () => ({
        dialog: {
            image: false,
            info: false,
            bug: false
        }
    }),

    methods: {
        // 카드주소 복사
        copyURL(){
            this.$copyText(window.location.origin + this.$route.fullPath)
            alert('카드주소가 복사되었습니다')
        },

        // 카드ID 복사
        copyID(){
            this.$copyText(this.$route.query.id)
            alert('카드ID가 복사되었습니다')
        },
    }
}
</script>