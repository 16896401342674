<template>
    <div
        style="position:relative;"
    >
        <!-- 상단 -->
        <div class="pa-4 pr-0 d-flex align-center">
            <!-- 제목 -->
            <div>
                <p style="font-size:26px; font-weight:bold; margin-bottom:4px; cursor:pointer;" @click="keyword.nation='kr'; $router.push('/rank')">
                    카드랭킹
                    <span style="margin-left:4px; color:#F8468D; font-size:12px; vertical-align:super; font-weight:500;">BETA</span>
                </p>
                
                <p style="font-size:11px;" class="mb-0">실시간 카드순위를 확인해보세요</p>
            </div>
            <v-spacer></v-spacer>
            
            <!-- 메뉴 -->
            <v-sheet
                v-if="!$route.path.startsWith('/deck/read')"
                class="mt-1 pt-1 px-9 rounded-15 d-flex justify-space-between"
            >
                <v-btn
                    height="56"
                    class="px-0 mr-8"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="keyword.nation == 'kr'? 'primary':''"
                    :style="keyword.nation == 'kr'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                    :dark="keyword.nation == 'kr'"
                    @click="keyword.nation = 'kr'; keyword.series = ''; keyword.set_name = ''"
                >
                    국내판
                </v-btn>
                <v-btn
                    height="56"
                    class="px-0 mr-8"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="keyword.nation == 'en'? 'primary':''"
                    :style="keyword.nation == 'en'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                    :dark="keyword.nation == 'en'"
                    @click="keyword.nation = 'en'; keyword.series = ''; keyword.set_name = ''"
                >
                    북미판
                </v-btn>
                <v-btn
                    height="56"
                    class="px-0"
                    style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    :color="keyword.nation == 'jp'? 'primary':''"
                    :style="keyword.nation == 'jp'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid transparent;'"
                    :dark="keyword.nation == 'jp'"
                    @click="keyword.nation = 'jp'; keyword.series = ''; keyword.set_name = ''"
                >
                    일본판
                </v-btn>
            </v-sheet>
        </div>

        <!-- 검색조건 -->
        <v-sheet
            height="100"
            class="rounded-15 d-flex align-center px-8"
        >
            <!-- 시리즈 -->
            <v-autocomplete
                height="50"
                dense outlined hide-details
                class="rounded-10 mr-2"
                label="시리즈"
                placeholder="전체"
                persistent-placeholder
                :items="series_list"
                v-model="keyword.series"
                @change="keyword.set_name = ''"
            >
                <template v-slot:append>
                    <v-icon class="mr-1 pt-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>

            <!-- 제품명 -->
            <v-autocomplete
                height="50"
                dense outlined hide-details
                class="rounded-10 mr-2"
                label="제품명"
                placeholder="전체"
                persistent-placeholder
                :items="set_list"
                v-model="keyword.set_name"
                @change="keyword.series = ''"
            >
                <template v-slot:append>
                    <v-icon class="mr-1 pt-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>

            <!-- 평점랭킹 -->
            <v-btn
                depressed
                dark
                class="rounded-10 font-weight-bold mr-2"
                style="font-size:18px;"
                :color="rank_type == '평점랭킹'? '#23D2E2':'#ADAFCA'"
                width="111"
                height="49"
                @click="rank_type = '평점랭킹'"
            >
                평점랭킹
            </v-btn>

            <!-- 가격랭킹 -->
            <v-btn
                depressed
                dark
                class="rounded-10 font-weight-bold mr-2"
                style="font-size:18px;"
                :color="rank_type == '가격랭킹'? '#23D2E2':'#ADAFCA'"
                width="111"
                height="49"
                @click="rank_type = '가격랭킹'"
            >
                가격랭킹
            </v-btn>

            <!-- 사용랭킹 -->
            <v-btn
                depressed
                dark
                class="rounded-10 font-weight-bold"
                style="font-size:18px;"
                :color="rank_type == '사용랭킹'? '#23D2E2':'#ADAFCA'"
                width="111"
                height="49"
                @click="rank_type = '사용랭킹'"
            >
                사용랭킹
            </v-btn>
        </v-sheet>

        <!-- 목록 -->
        <v-sheet
            v-if="currentPageList.length"
            class="d-flex mt-4 mb-1 pb-2"
            color="transparent"
            style="font-size:16px; font-weight:bold;"
        >
            <p v-if="rank_type == '평점랭킹'" style="width:80px; text-align:center;" class="mb-0">순위</p>
            <p v-if="rank_type == '평점랭킹'" style="width:340px; text-align:center;" class="mb-0">카드</p>

            <p v-if="rank_type == '평점랭킹'" style="width:140px; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_1'? 'primary--text':''" @click="type = 'align_1'; align_1 = !align_1">
                종합평점
                <v-icon v-if="align_1" class="pb-6px" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_1" class="pb-6px" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '평점랭킹'" style="width:124px; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_2'? 'primary--text':''" @click="type = 'align_2'; align_2 = !align_2">
                성능평점
                <v-icon v-if="align_2" class="pb-6px" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_2" class="pb-6px" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '평점랭킹'" style="width:140px; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_3'? 'primary--text':''" @click="type = 'align_3'; align_3 = !align_3">
                수집평점
                <v-icon v-if="align_3" class="pb-6px" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_3" class="pb-6px" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>



            <p v-if="rank_type == '가격랭킹'" style="width:80px; text-align:center; cursor:pointer;" class="mb-0">순위</p>
            <p v-if="rank_type == '가격랭킹'" style="width:340px; text-align:center; cursor:pointer;" class="mb-0">카드</p>

            <p v-if="rank_type == '가격랭킹'" style="width:140px; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_1'? 'primary--text':''" @click="type = 'align_1'; align_1 = !align_1">
                평균가격
                <v-icon v-if="align_1" class="pb-6px" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_1" class="pb-6px" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '가격랭킹'" style="width:124px; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_2'? 'primary--text':''" @click="type = 'align_2'; align_2 = !align_2">
                최고가격
                <v-icon v-if="align_2" class="pb-6px" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_2" class="pb-6px" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '가격랭킹'" style="width:140px; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_3'? 'primary--text':''" @click="type = 'align_3'; align_3 = !align_3">
                거래량
                <v-icon v-if="align_3" class="pb-6px" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_3" class="pb-6px" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>




            <p v-if="rank_type == '사용랭킹'" style="width:80px; text-align:center; cursor:pointer;" class="mb-0">순위</p>
            <p v-if="rank_type == '사용랭킹'" style="width:340px; text-align:center; cursor:pointer;" class="mb-0">카드</p>
            
            <p v-if="rank_type == '사용랭킹'" style="width:150px; text-align:center; cursor:pointer;" class="pl-8 mb-0" :class="type == 'align_1'? 'primary--text':''" @click="type = 'align_1'; align_1 = !align_1">
                덱 채용건
                <v-icon v-if="align_1" class="pb-6px" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_1" class="pb-6px" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '사용랭킹'" style="width:124px; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_2'? 'primary--text':''" @click="type = 'align_2'; align_2 = !align_2">
                덱 채용수
                <v-icon v-if="align_2" class="pb-6px" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_2" class="pb-6px" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '사용랭킹'" style="width:140px; text-align:center; cursor:pointer;" class="pl-4 pr-3 mb-0" :class="type == 'align_3'? 'primary--text':''" @click="type = 'align_3'; align_3 = !align_3">
                수집
                <v-icon v-if="align_3" class="pb-6px" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_3" class="pb-6px" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
        </v-sheet>

        <v-sheet
            v-if="currentPageList.length"
            class="rounded-15 pt-2"
        >
            <v-row
                no-gutters
            >
                <v-col class="py-2" cols="12" v-for="(item, index) in currentPageList" :key="item.id">
                    <v-sheet class="pb-4 d-flex justify-center align-center rounded-15">
                        <v-sheet style="font-size:30px; font-weight:bold; color:#615DFA; width:80px; text-align:center;">
                            {{index+10*(page-1)+1}}
                        </v-sheet>

                        <v-menu
                            offset-x
                            right
                            open-on-hover
                            nudge-right="0"
                            max-width="320"
                            content-class="rounded-15"
                            :close-on-content-click="true"
                        >
                            <template v-slot:activator="{ on: menu }">
                                <v-sheet
                                    v-on="{ ...menu }"
                                    class="d-flex align-center text-truncate"
                                >
                                    <!-- 이미지 -->
                                    <v-img
                                        min-width="67"
                                        max-width="67"
                                        min-height="67"
                                        max-height="67"
                                        class="rounded-circle grey lighten-4"
                                        :src="showImage(item)"
                                    ></v-img>

                                    <v-sheet class="ml-2">
                                        <!-- 카드이름 -->
                                        <div>
                                            <p
                                                style="font-size:19px; font-weight:600; width:300px;"
                                                class="mb-0 pb-2px mt-1 ml-1 text-truncate"
                                            >
                                                {{item.name}}
                                            </p>
                                        </div>

                                        <!-- 제품명 -->
                                        <div class="ml-5px">
                                            <p
                                                style="font-size:12px; font-weight:300; width:300px;"
                                                class="mb-0 text-truncate"
                                            >
                                                {{item.set_name}}
                                            </p>
                                        </div>
                                        
                                        <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                        <div class="d-flex ml-1">
                                            <!-- 레귤레이션 -->
                                            <p
                                                v-if="item.regulationMark"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                <v-icon size="14" class="pb-7px mr-1">
                                                    mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                                </v-icon>
                                            </p>

                                            <!-- 넘버, 토탈넘버 -->
                                            <div class="mr-2px">
                                                <p
                                                    style="font-size:12px; font-weight:300;"
                                                    class="mb-0"
                                                >
                                                    {{item.number}} / {{item.set_printedTotal}}
                                                </p>
                                            </div>

                                            <!-- 희귀도 -->
                                            <div>
                                                <p
                                                    style="font-size:12px; font-weight:300;"
                                                    class="mb-0"
                                                >
                                                    {{item.rarity}}
                                                </p>
                                            </div>
                                        </div>
                                    </v-sheet>
                                </v-sheet>
                            </template>
                            <Detail
                                :id="item.id"
                                :isBoardContent="true"
                                :nation="keyword.nation"
                            />
                        </v-menu>

                        <v-sheet
                            width="140"
                            class="text-center font-weight-bold pr-6"
                        >
                            <div
                                v-if="rank_type == '평점랭킹'"
                                class="pl-1"
                            >
                                <!-- 평점(별) -->
                                <v-rating
                                    :value="item.total_avg"
                                    half-increments
                                    background-color="grey"
                                    color="#615DFA"
                                    size="16"
                                    density="compact"
                                    readonly
                                ></v-rating>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light"
                                    style="width:104px; font-size:12px; margin-bottom:-3px !important;"
                                >
                                    평점 {{item.total_avg?.toFixed(2)}}
                                    <font style="color:#615DFA;">({{item.total_count}})</font>
                                </p>
                            </div>
                            <span v-if="rank_type == '가격랭킹'" style="color:#615DFA;">{{$toComma(Math.floor(item.avg_price))}}원</span>
                            <span v-if="rank_type == '사용랭킹'" style="color:#615DFA;">{{item.deck_total_count}}건</span>
                        </v-sheet>
                        <v-sheet
                            width="140"
                            class="text-center font-weight-bold pr-6"
                        >
                            <div
                                v-if="rank_type == '평점랭킹'"
                                class="pl-1"
                            >
                                <!-- 평점(별) -->
                                <v-rating
                                    :value="item.perform_avg"
                                    half-increments
                                    background-color="grey"
                                    color="#615DFA"
                                    size="16"
                                    density="compact"
                                    readonly
                                ></v-rating>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light"
                                    style="width:104px; font-size:12px; margin-bottom:-3px !important;"
                                >
                                    평점 {{item.perform_avg?.toFixed(2)}}
                                    <font style="color:#615DFA;">({{item.total_perform}})</font>
                                </p>
                            </div>
                            <span v-if="rank_type == '가격랭킹'" style="color:#F8468D;">{{$toComma(Math.floor(item.max_price))}}원</span>
                            <span v-if="rank_type == '사용랭킹'" style="color:#F8468D;">{{item.deck_total_amount}}장</span>
                        </v-sheet>
                        <v-sheet
                            width="130"
                            class="text-center font-weight-bold pr-6"
                        >
                            <div
                                v-if="rank_type == '평점랭킹'"
                                class="pl-1"
                            >
                                <!-- 평점(별) -->
                                <v-rating
                                    :value="item.collect_avg"
                                    half-increments
                                    background-color="grey"
                                    color="#615DFA"
                                    size="16"
                                    density="compact"
                                    readonly
                                ></v-rating>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light"
                                    style="width:104px; font-size:12px; margin-bottom:-3px !important;"
                                >
                                    평점 {{item.collect_avg?.toFixed(2)}}
                                    <font style="color:#615DFA;">({{item.total_collect}})</font>
                                </p>
                            </div>
                            <span v-if="rank_type == '가격랭킹'" style="color:#1DA1F2;">{{item.count}}건</span>
                            <span v-if="rank_type == '사용랭킹'" style="color:#1DA1F2;">{{item.collection_total_amount}}장</span>
                        </v-sheet>
                    </v-sheet>
                    <v-divider class="mx-10 grey lighten-3"></v-divider>
                </v-col>
            </v-row>
            <!-- 페이지네이션 -->
            <v-pagination
                class="elevation-0 mt-4 mb-4 pb-4"
                color="primary"
                v-model="page"
                :length="pageLength"
                total-visible="10"
            ></v-pagination>
        </v-sheet>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 360px; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="80"
        ></v-progress-circular>

        <v-sheet
            v-if="!list.length && !loading"
            class="mt-2 text-center py-16 rounded-15"
        >
            <v-icon size="60" color="#424361" class="mb-3">mdi-close-circle-outline</v-icon>
            <p style="color:#424361; font-size:19px; font-weight:bold;">해당 조건에 맞는 데이터가 없습니다</p>
        </v-sheet>

        <BannerView
            v-if="$store.state.ads_banner.card_ranking"
            class="mt-3 mx-auto"
            type="card_ranking"
            width="360"
            height="46"
            :round="'rounded-10'"
        />
    </div>
</template>
<script>
import Detail from '@/components/card/detailForBoard/Detail'
import BannerView from '@/components/BannerView'

export default {
    components: {
        Detail,
        BannerView
    },

    data: () => ({
        select_list: {
            set: {
                en: [],
                kr: [],
                jp: []
            },

            series: {
                en: [],
                kr: [],
                jp: []
            }
        },

        // 키워드
        keyword: {
            nation: "kr",
            series: "",
            set_name: "",
        },

        // 랭킹
        rank_type: "평점랭킹",
        align_1: true,
        align_2: false,
        align_3: false,
        type: "align_1",

        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:10,
        list_ready:false,

        loading: false
    }),

    watch: {
        keyword: {
            handler: function (val, oldVal) {
                this.search()
            },
            deep: true
        },

        rank_type(){
            this.search()
        },

        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*10,(this.page)*10)
        },

        align_1(){
            this.search()
        },

        align_2(){
            this.search()
        },
        
        align_3(){
            this.search()
        },
    },

    computed: {
        // 제품명
        set_list(){
            switch(this.keyword.nation){
                case 'kr' : return this.select_list.set.kr
                case 'en' : return this.select_list.set.en
                case 'jp' : return this.select_list.set.jp
            }
        },

        // 시리즈
        series_list(){
            switch(this.keyword.nation){
                case 'kr' : return this.select_list.series.kr
                case 'en' : return this.select_list.series.en
                case 'jp' : return this.select_list.series.jp
            }
        }
    },

    mounted(){
        this.loading = true

        // 제품명 목록 불러오기
        this.loadSetsList()

        // 시리즈 목록 불러오기
        this.loadSeriesList()

        this.search()
    },

    methods: {
        showImage(item){
            if(item.image_kr != "default.png")
            {
                return '/upload/card_kr_image/' + item.image_kr
            }
            else if(item.image_en != "default.png")
            {
                return '/upload/card_en_image/' + item.image_en
            }
            else if(item.image_jp != "default.png")
            {
                return '/upload/card_jp_image/' + item.image_jp
            }
            else {
                return '/upload/card_kr_image/default.png'
            }
        },

        sortList(){
            if(this.rank_type == "평점랭킹")
            {
                if(this.type == "align_1")
                {
                    if(this.align_1)
                    {
                        this.list = this.list.sort((a, b) => b.total_avg - a.total_avg)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.total_avg - b.total_avg)
                    }
                }
                else if(this.type == "align_2")
                {
                    if(this.align_2)
                    {
                        this.list = this.list.sort((a, b) => b.perform_avg - a.perform_avg)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.perform_avg - b.perform_avg)
                    }
                }
                else if(this.type == "align_3")
                {
                    if(this.align_3)
                    {
                        this.list = this.list.sort((a, b) => b.collect_avg - a.collect_avg)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.collect_avg - b.collect_avg)
                    }
                }
            }

            if(this.rank_type == "가격랭킹")
            {
                if(this.type == "align_1")
                {
                    if(this.align_1)
                    {
                        this.list = this.list.sort((a, b) => b.avg_price - a.avg_price)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.avg_price - b.avg_price)
                    }
                }
                else if(this.type == "align_2")
                {
                    if(this.align_2)
                    {
                        this.list = this.list.sort((a, b) => b.max_price - a.max_price)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.max_price - b.max_price)
                    }
                }
                else if(this.type == "align_3")
                {
                    if(this.align_3)
                    {
                        this.list = this.list.sort((a, b) => b.count - a.count)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.count - b.count)
                    }
                }
            }

            if(this.rank_type == "사용랭킹")
            {
                if(this.type == "align_1")
                {
                    if(this.align_1)
                    {
                        this.list = this.list.sort((a, b) => b.deck_total_count - a.deck_total_count)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.deck_total_count - b.deck_total_count)
                    }
                }
                else if(this.type == "align_2")
                {
                    if(this.align_2)
                    {
                        this.list = this.list.sort((a, b) => b.deck_total_amount - a.deck_total_amount)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.deck_total_amount - b.deck_total_amount)
                    }
                }
                else if(this.type == "align_3")
                {
                    if(this.align_3)
                    {
                        this.list = this.list.sort((a, b) => b.collection_total_amount - a.collection_total_amount)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.collection_total_amount - b.collection_total_amount)
                    }
                }
            }

            this.currentPageList = this.list.slice(0,10)
            this.page = 1
        },

        // 제품명 목록 불러오기
        loadSetsList(){
            // 한글
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })

            // 영어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "jp"
                }
            }).then(res => { 
                this.select_list.set.jp = res.data.map(a => a.name)
            })
        },

        // 시리즈 목록 불러오기
        loadSeriesList(){
            // 한글
            this.$http.post('/api/card/select/series/kr')
            .then(res => { 
                this.select_list.series.kr = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 영어
            this.$http.post('/api/card/select/series/en')
            .then(res => { 
                this.select_list.series.en = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 일어
            this.$http.post('/api/card/select/series/jp')
            .then(res => { 
                this.select_list.series.jp = res.data.map(a => a.series).filter(e => e!=null)
            })
        },

        async search(){
            this.loading = true
            if(this.rank_type == "평점랭킹")
            {
                await this.$http.post('/api/rank/rating/'+this.keyword.nation, {
                    params: {
                        keyword: this.keyword,
                    }
                }).then(async res => {
                    console.log("평점랭킹", res)
                    this.list = res.data
                    this.pageLength = 10
                    this.page = 1
                    await this.sortList()
                    this.loading = false
                })
            }

            else if(this.rank_type == "가격랭킹")
            {
                await this.$http.post('/api/rank/trade/'+this.keyword.nation, {
                    params: {
                        keyword: this.keyword,
                    }
                }).then(async res => {
                    console.log("가격랭킹", res)
                    this.list = res.data
                    this.pageLength = 10
                    this.page = 1
                    await this.sortList()
                    this.loading = false
                })
            }

            else if(this.rank_type == "사용랭킹")
            {
                await this.$http.post('/api/rank/usage/'+this.keyword.nation, {
                    params: {
                        keyword: this.keyword,
                    }
                }).then(async res => {
                    console.log("사용랭킹", res)
                    this.list = res.data
                    this.pageLength = 10
                    this.page = 1
                    await this.sortList()
                    this.loading = false
                })
            }
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

::v-deep .v-input__icon--clear {
    padding-top:14px;
    padding-right:4px;
}
</style>