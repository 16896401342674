<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="mb-4"
        >
            <v-row no-gutters>
                <v-col cols="3">
                    <div class="d-flex align-center pl-1">
                        <font
                            v-if="card.en.hp"
                            style="font-size:20px;"
                            class="font-weight-bold my-0"
                        >
                            HP {{card.en.hp}}
                        </font>
                    </div>
                </v-col>
                <v-col cols="4">
                    <div class="d-flex align-center pl-7">
                        <span style="font-size:18px;" class="mr-2">도감번호</span>
                        <span
                            v-if="card.en.nationalPokedexNumbers"
                            style="font-size:18px; font-weight:bold;"
                        >
                            {{card.en.nationalPokedexNumbers.toString()}}
                        </span>
                    </div>
                </v-col>
                <v-col cols="5">
                    <div class="d-flex align-center pl-5">
                        <span style="font-size:18px;" class="mr-2">희귀도</span>
                        <p class="my-0 font-weight-bold" style="font-size:18px;">
                            <span v-if="text_nation=='en' && card.en.isAvailable && card.en.rarity">
                                {{card.en.rarity}}
                            </span>
                            <span v-if="text_nation=='kr' && card.kr.isAvailable && card.kr.rarity">
                                {{card.kr.rarity}}
                            </span>
                            <span v-if="text_nation=='jp' && card.jp.isAvailable && card.jp.rarity">
                                {{card.jp.rarity}}
                            </span>
                        </p>
                    </div>
                </v-col>
            </v-row>
        </div>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="pt-1 px-7"
        >
            <v-divider class="mb-3"></v-divider>

            <v-row no-gutters>
                <v-col cols="4">
                    <div class="py-1 d-flex align-center">
                        <font
                            v-if="card.en.hp"
                            style="font-size:13px;"
                            class="font-weight-bold my-0"
                        >
                            HP {{card.en.hp}}
                        </font>
                    </div>
                </v-col>
                <v-col cols="8">
                    <div class="py-1 d-flex align-center">
                        <span style="font-size:13px;" class="mr-2">도감번호</span>
                        <span
                            v-if="card.en.nationalPokedexNumbers"
                            style="font-size:13px; font-weight:bold;"
                        >
                            {{card.en.nationalPokedexNumbers.toString()}}
                        </span>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="py-1 d-flex align-center">
                        <span style="font-size:13px;" class="mr-2">희귀도</span>
                        <p class="my-0 font-weight-bold" style="font-size:13px;">
                            <span v-if="text_nation=='en' && card.en.isAvailable && card.en.rarity">
                                {{card.en.rarity}}
                            </span>
                            <span v-if="text_nation=='kr' && card.kr.isAvailable && card.kr.rarity">
                                {{card.kr.rarity}}
                            </span>
                            <span v-if="text_nation=='jp' && card.jp.isAvailable && card.jp.rarity">
                                {{card.jp.rarity}}
                            </span>
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"]
}
</script>