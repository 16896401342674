<template>
    <div>
        <!-- 버튼 -->
        <v-btn
            v-if="!$vuetify.breakpoint.mobile"
            depressed
            color="white"
            height="50"
            style="font-size:15px;"
            class="rounded-10 font-weight-bold px-6"
            @click="dialog = true"
        >
            <v-icon size="24" left color="primary">
                mdi-list-box-outline
            </v-icon>
            수정이력
        </v-btn>

        <!-- 버튼 -->
        <v-btn
            v-if="$vuetify.breakpoint.mobile"
            depressed
            small
            color="white"
            width="54"
            height="54"
            style="font-size:9px; font-weight:400;"
            class="rounded-10"
            @click="dialog = true"
        >
            <div class="text-center mb-10px">
                <v-icon size="16" color="primary" class="d-block mb-1">
                    mdi-list-box-outline
                </v-icon>
                수정이력
            </div>
        </v-btn>

        <!-- dialog -->
        <v-dialog v-model="dialog" width="1060" v-if="!$vuetify.breakpoint.mobile">
            <v-sheet width="1060">
                <p class="pt-3 mb-3 font-weight-bold text-center text-subtitle-1 grey--text text--darken-2">
                    수정이력
                </p>
                <v-divider></v-divider>
                <v-sheet class="pa-3">
                    <v-data-table
                        id="list"
                        :headers="headers"
                        :items="list"
                        :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                        no-data-text="해당 카드는 수정내역이 없습니다"
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <div style="width:120px">
                                    <font class="d-block text-truncate" style="width:120px;">
                                        {{item.provide_type == 'card' ? "카드": "이미지"}}
                                    </font>
                                </div>
                                <td><font class="d-block text-truncate" style="width:100px;">{{item.card_nation}}</font></td>
                                <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.provider_nickname}}({{item.provider}})</font></td>
                                <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.updater_nickname}}({{item.updater}})</font></td>
                                <td style="width:100%"><font class="d-block text-truncate" style="width:200px;">{{item.memo}}</font></td>
                                <td style="width:160px"><font class="d-block text-truncate" style="width:160px;">{{new Date(item.created).toLocaleDateString()}}</font></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-sheet>
                <v-sheet class="pb-2 d-flex justify-center">
                    <v-btn large dark color="blue" width="240" @click="dialog = false">
                        <v-icon small left>mdi-cancel</v-icon>닫기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- dialog -->
        <v-dialog v-model="dialog" v-if="$vuetify.breakpoint.mobile" content-class="rounded-10">
            <v-sheet>
                <p class="pt-3 mb-3 font-weight-bold text-center text-subtitle-1 grey--text text--darken-2">
                    수정이력
                </p>
                <v-divider></v-divider>

                <div
                    v-if="list.length"
                >
                    <v-sheet
                        v-for="item in list" :key="item.id"
                    >
                        <v-sheet
                            outlined
                            class="pa-4 ma-4 rounded-10 text-caption"
                        >
                            수정 유형: {{item.provide_type == 'card' ? "카드": "이미지"}}<br/>
                            발매 국가: {{item.card_nation}}<br/>
                            제보자: {{item.provider_nickname}}({{item.provider}})<br/>
                            수정자: {{item.updater_nickname}}({{item.updater}})<br/>
                            수정 내용: {{item.memo}}<br/>
                            수정 일자: {{new Date(item.created).toLocaleDateString()}}
                        </v-sheet>
                    </v-sheet>
                </div>
                <div
                    v-if="list.length == 0"
                >
                    <p
                        class="text-center"
                    >
                        해당 카드는 수정내역이 없습니다
                    </p>
                </div>

                <v-sheet class="pb-2 d-flex justify-center">
                    <v-btn large dark color="blue" width="240" depressed class="rounded-10" @click="dialog = false">
                        <v-icon small left>mdi-cancel</v-icon>닫기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data:() => ({
        dialog: false,
        headers: [
            {text: '수정 유형', value:'provide_type'},
            {text: '발매 국가', value:'card_nation'},
            {text: '제보자', value:'provider'},
            {text: '수정자', value:'updater'},
            {text: '수정 내용', value:'memo'},
            {text: '수정 일자', value:'created'}
        ],
        list: [],
    }),

    mounted(){
        this.$http.post('/api/admin/provide/update_log/list', {
            params: {
                card_id: this.$route.query.id
            }
        }).then((res) => {
            this.list = res.data
        })
    }
}
</script>