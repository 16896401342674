<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <p class="font-weight-bold text-center my-2">
                <!-- 한글카드 없을시 영어이름 표시 -->
                <v-sheet v-if="!card.kr" style="font-size:24px;">
                    {{card.en.name}} 덱 레시피
                </v-sheet>

                <!-- 한글카드 있을시 병행 표시 -->
                <v-sheet v-if="card.kr" style="font-size:24px;">
                    <!-- 한글 -->
                    <span v-if="text_nation=='kr'">
                        {{card.kr.name? card.kr.name : card.en.name}} 덱 레시피
                    </span>

                    <!-- 영어 -->
                    <span v-if="text_nation=='en'">
                        {{card.en.name}} {{card.kr.name? "("+card.kr.name+")" : ""}} 덱 레시피
                    </span>

                    <!-- 일어 -->
                    <span v-if="text_nation=='jp'">
                        {{card.jp.name? card.jp.name : card.en.name}} {{card.kr.name? "("+card.kr.name+")" : ""}} 덱 레시피
                    </span>
                </v-sheet>
            </p>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <p class="font-weight-bold my-2">
                <!-- 한글카드 없을시 영어이름 표시 -->
                <v-sheet v-if="!card.kr" style="font-size:16px;">
                    {{card.en.name}} 덱 레시피
                </v-sheet>

                <!-- 한글카드 있을시 병행 표시 -->
                <v-sheet v-if="card.kr" style="font-size:16px;">
                    <!-- 한글 -->
                    <span v-if="text_nation=='kr'">
                        {{card.kr.name? card.kr.name : card.en.name}} 덱 레시피
                    </span>

                    <!-- 영어 -->
                    <span v-if="text_nation=='en'">
                        {{card.en.name}} {{card.kr.name? "("+card.kr.name+")" : ""}} 덱 레시피
                    </span>

                    <!-- 일어 -->
                    <span v-if="text_nation=='jp'">
                        {{card.jp.name? card.jp.name : card.en.name}} {{card.kr.name? "("+card.kr.name+")" : ""}} 덱 레시피
                    </span>
                </v-sheet>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "card_id",
        "text_nation",
    ],

    data: () => ({
        load: false,
        card: {
            en: {},
            kr: {},
            jp: {}
        }
    }),

    mounted(){
        // 카드 정보 불러오기
        this.loadCard()
    },

    watch: {
        card_id(){
            this.loadCard()
        },

        text_nation(){
            this.loadCard()
        }
    },

    methods: {
        // 카드 정보 불러오기
        loadCard(){
            // en
            this.$http.post('/api/card/detail/card_en', {
                params: {
                    id: this.card_id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.en = JSON.parse(res.data[0].dump)
                }
            })

            // kr
            this.$http.post('/api/card/detail/card_kr', {
                params: {
                    id: this.card_id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.kr = res.data[0]
                }
            })

            // jp
            this.$http.post('/api/card/detail/card_jp', {
                params: {
                    id: this.card_id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.jp = res.data[0]
                }
            })
        }
    }
}
</script>