<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="pa-1 mb-2"
        >
            <!-- Subtypes -->
            <font
                v-if="card.en.subtypes"
                style="font-size:20px;"
                class="font-weight-bold"
            >
                {{computed_subtypes}}
            </font>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="pa-1 px-5 pb-2"
        >
            <v-divider class="mb-2"></v-divider>

            <!-- Subtypes -->
            <p
                v-if="card.en.subtypes"
                style="font-size:11px; background:#f6f6f6; line-height:22px;"
                class="font-weight-bold text-center mb-0"
            >
                {{computed_subtypes}}
            </p>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    data:() => ({
        supertype: {
            Energy: [],
            Pokémon: [],
            Trainer: []
        },

        subtypes: []
    }),

    mounted(){
        this.load_supertype_list()
    },

    methods: {
        load_supertype_list(){
            // 카드종류(supertype) list 불러오기
            this.$http.post('/api/admin/card/supertype/list/show')
            .then((res) => {
                this.supertype.Energy = res.data.filter(e => e.category == "Energy")
                this.supertype.Pokémon = res.data.filter(e => e.category == "Pokémon")
                this.supertype.Trainer = res.data.filter(e => e.category == "Trainer")
            })
        }
    },

    computed: {
        // Subtypes 매칭
        computed_subtypes(){
            if(this.card.en.supertype == "Energy")
            {
                return this.supertype.Energy.filter(e => this.card.en.subtypes.includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
            else if(this.card.en.supertype == "Pokémon")
            {
                return this.supertype.Pokémon.filter(e => this.card.en.subtypes.includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
            else if(this.card.en.supertype == "Trainer")
            {
                return this.supertype.Trainer.filter(e => this.card.en.subtypes.includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
        }
    }
}
</script>