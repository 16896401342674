<template>
    <div>
        <!-- 값테스트 -->
        <!-- <p class="text-h1">{{recent_s}}</p> -->

        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 카드 이름 표시 -->
            <Name 
                :card_id="card_id"
                :text_nation="text_nation"
            />

            <v-select
                style="position:absolute; top:76px; left:32px; width:220px;"
                class="rounded-xl"
                dense
                outlined
                :items="select_list.show_type"
                v-model="show_type"
                @change="show_type_changed()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- TEXT 언어선택 -->
            <v-sheet
                v-if="$route.path.startsWith('/card/detail')"
                class="d-flex justify-center mt-3 mb-5"
            >
                <v-btn
                    height="48"
                    class="pl-1 pr-2 mr-1"
                    style="font-size:20px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    v-ripple="false"
                    text
                    tile
                    depressed
                    :color="text_nation_local == 'kr'? 'primary':''"
                    :style="text_nation_local == 'kr'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                    :dark="text_nation_local == 'kr'"
                    @click="$emit('text_nation_changed', 'kr')"
                >
                    한글
                </v-btn>
                <v-btn
                    height="48"
                    class="pl-1 pr-2 mr-1"
                    style="font-size:20px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    v-ripple="false"
                    text
                    tile
                    depressed
                    :color="text_nation_local == 'en'? 'primary':''"
                    :style="text_nation_local == 'en'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                    :dark="text_nation_local == 'en'"
                    @click="$emit('text_nation_changed', 'en')"
                >
                    영어
                </v-btn>
                <v-btn
                    height="48"
                    class="pl-1 pr-2"
                    style="font-size:20px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                    v-ripple="false"
                    text
                    tile
                    depressed
                    :color="text_nation_local == 'jp'? 'primary':''"
                    :style="text_nation_local == 'jp'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                    :dark="text_nation_local == 'jp'"
                    @click="$emit('text_nation_changed', 'jp')"
                >
                    일어
                </v-btn>
            </v-sheet>

            <!-- 카드 시세 표시 -->
            <v-row class="mx-4">
                <!-- 차트 -->
                <v-col cols="6">
                    <Charts
                        v-if="chart_mode == '기본'"
                        :card_id="card_id"
                        :text_nation="text_nation"
                        @avgUpdated="avgUpdated"
                    />

                    <Charts2
                        v-if="chart_mode == 'PSA'"
                        :card_id="card_id"
                        :text_nation="text_nation"
                        @avgUpdated="avgUpdated"
                    />

                    <Charts3
                        v-if="chart_mode == '기타'"
                        :card_id="card_id"
                        :text_nation="text_nation"
                        @avgUpdated="avgUpdated"
                    />
                </v-col>

                <!-- 가격 정보 -->
                <v-col
                    v-if="mode_type == '기본'"
                    cols="6"
                    style="position:relative;"
                >
                    <v-sheet class="px-4">
                        <!-- 평균가, 최근거래가 -->
                        <v-sheet class="py-2 shadow rounded-15">
                            <p class="my-0 font-weight-bold d-flex align-center justify-center">
                                <span style="font-size:15px; color:#615DFA;">평균가(모든등급)</span>
                                <span class="ml-4" style="font-size:21px;">￦{{$toComma(total_avg)}}원</span>
                            </p>
                        </v-sheet>
                        <v-sheet class="py-2 shadow mt-4 rounded-15">
                            <p class="my-0 font-weight-bold d-flex align-center justify-center">
                                <span style="font-size:15px; color:#F8468D;">최근거래가(S급)</span>
                                <span class="ml-4" style="font-size:21px;">￦{{$toComma(recent_s)}}원</span>
                            </p>
                        </v-sheet>
                        <v-row
                            class="mt-2 ml-2 py-4 pl-3"
                            color="transparent"
                            no-gutters
                            :style="user_post_list.length == 0 && ids.length == 1? 'opacity:0.2':''"
                        >
                            <!-- 차트별 평균가 -->
                            <v-col cols="5" color="transparent">
                                <v-sheet class="pl-1" color="transparent">
                                    <p class="mt-0 mb-2" style="font-size:15px;">
                                        <span
                                            class="py-1 mr-2 white--text rounded-5 text-center"
                                            style="font-size:12px; width:36px; background:#F8468D; display:inline-block;"
                                        >
                                            S급
                                        </span>
                                        ￦{{$toComma(avg.S)}}원
                                    </p>
                                    <p class="mt-0 mb-2" style="font-size:15px;">
                                        <span
                                            class="py-1 mr-2 white--text rounded-5 text-center"
                                            style="font-size:12px; width:36px; background:#FF7A51; display:inline-block;"
                                        >
                                            A급
                                        </span>
                                        ￦{{$toComma(avg.A)}}원
                                    </p>
                                    <p class="mt-0 mb-2" style="font-size:15px;">
                                        <span
                                            class="py-1 mr-2 white--text rounded-5 text-center"
                                            style="font-size:12px; width:36px; background:#1DA1F2; display:inline-block;"
                                        >
                                            B급
                                        </span>
                                        ￦{{$toComma(avg.B)}}원
                                    </p>
                                    <p class="mt-0 mb-2" style="font-size:15px;">
                                        <span
                                            class="py-1 mr-2 white--text rounded-5 text-center"
                                            style="font-size:12px; width:36px; background:#23D2E2; display:inline-block;"
                                        >
                                            C급
                                        </span>
                                        ￦{{$toComma(avg.C_below)}}원
                                    </p>
                                    <!-- <p class="mt-0 mb-2" style="font-size:15px;">
                                        <span
                                            class="py-1 mr-2 white--text rounded-5 text-center"
                                            style="font-size:12px; width:36px; background:#615DFA; display:inline-block;"
                                        >
                                            등급
                                        </span>
                                        <v-icon
                                            class="px-0 mx-0"
                                            style="cursor:pointer;"
                                            color="icu_purple"
                                            @click="mode_type = '등급'"
                                        >
                                            mdi-chevron-left
                                        </v-icon>
                                        <span
                                            style="vertical-align:-5%; margin-left:-8px; cursor:pointer;"
                                            class="icu_purple--text"
                                            @click="mode_type = '등급'"
                                        >
                                            상세보기
                                        </span>
                                    </p> -->
                                </v-sheet>
                            </v-col>

                            <!-- 최고가, 최저가, 총 거래 건수, 거래등록, 거래완료 -->
                            <v-col cols="7">
                                <v-sheet
                                    class="pl-3"
                                >
                                    <p class="mt-0 mb-2 text-truncate" style="font-size:15px; width:220px;">
                                        최고가 : ￦{{$toComma(total_max)}}원
                                    </p>
                                    <p class="mt-0 mb-1 text-truncate" style="font-size:15px; width:220px;">
                                        최저가 : ￦{{$toComma(total_min)}}원
                                    </p>
                                    <br/>
                                    <p class="mt-0 mb-2 font-weight-bold" style="font-size:15px;">
                                        총 거래 건수 : {{$toComma(postCount.total)}}건
                                    </p>
                                    <p class="mt-0 mb-1 text-truncate" style="font-size:15px; width:220px;">
                                        거래등록<span style="color:#615DFA;">({{$toComma(postCount.registered)}})</span> :
                                        ￦{{$toComma(postCount.registered_price)}}원
                                    </p>
                                    <p class="mt-0 mb-1 text-truncate" style="font-size:15px; width:220px;">
                                        거래완료<span style="color:#615DFA;">({{$toComma(postCount.finished)}})</span> :
                                        ￦{{$toComma(postCount.finished_price)}}원
                                    </p>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-sheet>

                    <div
                        v-if="admin_post_list.length != 0 && user_post_list.length == 0 && ids.length == 1"
                        style="position:absolute; top:50%; left:50%; transform:translateX(-50%); z-index:10; width:280px; "
                    >
                        <p
                            style="font-size:13px; font-weight:bold; text-align:center;"
                        >
                            해당 카드는 아직 국내에 거래 이력이 없어<br/>
                            <span style="color:#615dfa; cursor:pointer;" @click="$router.push('/board/read?board_group=너정다&board_name=이용가이드&id=401')">해외 거래 시세로 적용</span>되었습니다<br/>
                            실 거래가와 차이가 있을 수 있으니 참고 바랍니다
                            <!-- {{admin_post_list.length}}
                            {{user_post_list.length}}
                            {{ids.length}} -->
                        </p>
                    </div>
                </v-col>

                <!-- 거래가 -->
                <v-col
                    v-if="mode_type == '등급'"
                    cols="6"
                    style="position:relative;"
                >
                    <v-sheet class="px-4">

                        <div
                            class="d-flex"
                        >
                            <v-sheet
                                width="50%"
                                class="text-center rounded-15 py-2 mr-2"
                                style="font-size:18px; color:#615DFA; font-weight:600; cursor:pointer;"
                                :style="mode == 'PSA'? 'border:1px solid #615DFA':'border:1px solid #e0e0e0'"
                                @click="mode = 'PSA'"
                            >
                                PSA
                            </v-sheet>
                            <v-sheet
                                width="50%"
                                class="text-center rounded-15 py-2"
                                style="font-size:18px; color:#615DFA; font-weight:600; cursor:pointer;"
                                :style="mode == 'ETC'? 'border:1px solid #615DFA':'border:1px solid #e0e0e0'"
                                @click="mode = 'ETC'"
                            >
                                기타
                            </v-sheet>
                        </div>

                        <!-- PSA -->
                        <v-row
                            v-if="mode == 'PSA'"
                            class="mt-2 ml-2 py-4 pl-3"
                            color="transparent"
                            no-gutters
                            :style="user_post_list.length == 0 && ids.length == 1? 'opacity:0.2':''"
                        >
                            <!-- 1 ~ 5위 -->
                            <v-col
                                cols="6"
                            >
                                <p
                                    v-for="item in grade_PSA_avg.slice(0,5)" :key="item.name"
                                    class="pl-1 mt-0 mb-2"
                                    style="font-size:15px;"
                                >
                                    <span
                                        class="py-1 mr-2 white--text rounded-5 text-center"
                                        style="font-size:12px; width:60px; display:inline-block;"
                                        :style="caculateColor(item)"
                                    >
                                        PSA {{item.name.split('_')[1] / 10}}
                                    </span>
                                    ￦{{item.value}}원
                                </p>
                            </v-col>

                            <!-- 6 ~ 10위 -->
                            <v-col
                                cols="6"
                            >
                                <p
                                    v-for="item in grade_PSA_avg.slice(5,10)" :key="item.name"
                                    class="pl-1 mt-0 mb-2"
                                    style="font-size:15px;"
                                >
                                    <span
                                        class="py-1 mr-2 white--text rounded-5 text-center"
                                        style="font-size:12px; width:60px; display:inline-block;"
                                        :style="caculateColor(item)"
                                    >
                                        PSA {{item.name.split('_')[1] / 10}}
                                    </span>
                                    ￦{{item.value}}원
                                </p>
                            </v-col>
                        </v-row>

                        <!-- ETC -->
                        <v-row
                            v-if="mode == 'ETC'"
                            class="mt-2 ml-2 py-4 pl-3"
                            color="transparent"
                            no-gutters
                            :style="user_post_list.length == 0 && ids.length == 1? 'opacity:0.2':''"
                        >
                            <!-- 1 ~ 5위 -->
                            <v-col
                                cols="6"
                            >
                                <p
                                    v-for="item in grade_ETC_avg.slice(0,5)" :key="item.name"
                                    class="pl-1 mt-0 mb-2"
                                    style="font-size:15px;"
                                >
                                    <span
                                        class="py-1 mr-2 white--text rounded-5 text-center"
                                        style="font-size:12px; width:60px; display:inline-block;"
                                        :style="caculateColor(item)"
                                    >
                                        기타 {{item.name.split('_')[1] / 10}}
                                    </span>
                                    ￦{{item.value}}원
                                </p>
                            </v-col>

                            <!-- 6 ~ 10위 -->
                            <v-col
                                cols="6"
                            >
                                <p
                                    v-for="item in grade_ETC_avg.slice(5,10)" :key="item.name"
                                    class="pl-1 mt-0 mb-2"
                                    style="font-size:15px;"
                                >
                                    <span
                                        class="py-1 mr-2 white--text rounded-5 text-center"
                                        style="font-size:12px; width:60px; display:inline-block;"
                                        :style="caculateColor(item)"
                                    >
                                        기타 {{item.name.split('_')[1] / 10}}
                                    </span>
                                    ￦{{item.value}}원
                                </p>
                            </v-col>
                        </v-row>

                        <v-row>
                            <!-- 최고가, 최저가, 총 거래 건수, 거래등록, 거래완료 -->
                            <v-col cols="6" class="pl-8">
                                <p class="mt-0 mb-2 text-truncate" style="font-size:15px; width:220px;">
                                    최고가 : ￦{{$toComma(total_max)}}원
                                </p>
                                <p class="mt-0 mb-1 text-truncate" style="font-size:15px; width:220px;">
                                    최저가 : ￦{{$toComma(total_min)}}원
                                </p>
                                <!-- <p class="mt-2 mb-2" style="font-size:15px;">
                                    <span
                                        class="py-1 mr-2 white--text rounded-5 text-center"
                                        style="font-size:12px; width:72px; background:#615DFA; display:inline-block; cursor:pointer;"
                                        @click="mode_type = '기본'"
                                    >
                                        돌아가기
                                    </span>
                                </p> -->
                            </v-col>
                            <v-col cols="6" class="pl-2">
                                <p class="mt-0 mb-2 font-weight-bold" style="font-size:15px;">
                                    총 거래 건수 : {{$toComma(postCount.total)}}건
                                </p>
                                <p class="mt-0 mb-1 text-truncate" style="font-size:15px; width:220px;">
                                    거래등록<span style="color:#615DFA;">({{$toComma(postCount.registered)}})</span> :
                                    ￦{{$toComma(postCount.registered_price)}}원
                                </p>
                                <p class="mt-0 mb-1 text-truncate" style="font-size:15px; width:220px;">
                                    거래완료<span style="color:#615DFA;">({{$toComma(postCount.finished)}})</span> :
                                    ￦{{$toComma(postCount.finished_price)}}원
                                </p>
                            </v-col>
                        </v-row>
                    </v-sheet>

                    <div
                        v-if="admin_post_list.length != 0 && user_post_list.length == 0 && ids.length == 1"
                        style="position:absolute; top:50%; left:50%; transform:translateX(-50%); z-index:10; width:280px; "
                    >
                        <p
                            style="font-size:13px; font-weight:bold; text-align:center;"
                        >
                            해당 카드는 아직 국내에 거래 이력이 없어<br/>
                            <span style="color:#615dfa; cursor:pointer;" @click="$router.push('/board/read?board_group=너정다&board_name=이용가이드&id=401')">해외 거래 시세로 적용</span>되었습니다<br/>
                            실 거래가와 차이가 있을 수 있으니 참고 바랍니다
                            <!-- {{admin_post_list.length}}
                            {{user_post_list.length}}
                            {{ids.length}} -->
                        </p>
                    </div>
                </v-col>
            </v-row>

            <!-- 거래글 -->
            <v-sheet class="pa-4 pt-2 px-6 mt-2 mb-4 ">
                <!-- 제목 -->
                <p class="text-center font-weight-bold mb-6" style="font-size:14px;">
                    최근 카드거래 글
                    <v-icon class="pb-1">mdi-chevron-down</v-icon>
                </p>

                <!-- 목록 -->
                <v-row v-if="list.length">
                    <v-col
                        :cols="4"
                        v-for="item in list"
                        :key="item.id + item.card_state + item.card_price"
                        width="100"
                    >
                        <v-sheet
                            style="cursor:pointer; border-bottom:1px solid #eee;"
                            @click="$router.push('/trade/post/read?id='+item.id)"
                        >
                            <v-sheet class="d-flex align-center" style="position:relative;">
                                <v-sheet class="ml-3">
                                    <!-- 카드 이름 -->
                                    <p
                                        class="mb-1 font-weight-bold text-truncate "
                                        style="font-size:13px; line-height:16px; width:230px;"
                                    >
                                        {{item.title}}
                                    </p>
                                    <p
                                        style="font-size:11px; width:230px; color:#ADAFCA;"
                                    >
                                        {{new Date(item.created).toLocaleString()}}
                                    </p>

                                    <!-- 카드등급 / 카드가격 -->
                                    <p
                                        class="font-weight-bold my-0 ml-2 text-center"
                                        style="position:absolute; top:-3px; right:0px; width:100px; line-height:18px;"
                                    >
                                        <span v-if="item.card_state" style="font-size:14px; color:#23D2E2;">{{item.card_state}}</span><br/>
                                        <span v-if="item.card_price" style="font-size:14px;">{{$toComma(item.card_price)}}원</span>
                                    </p>
                                </v-sheet>
                            </v-sheet>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 거래글 없을 시 -->
                <v-row v-if="!list.length">
                    <v-col>
                        <p class="text-center my-0" style="font-size:13px;">해당 카드의 카드거래글이 없습니다.</p>
                    </v-col>
                </v-row>
            </v-sheet>

            <!--카드 가격 안내 멘트-->
            <v-card flat class="px-2 text-center">
                <p class="font-weight-bold text-caption mb-1 grey--text">
                    <v-icon small color="grey" class="mb-1">
                        mdi-alert-circle-outline
                    </v-icon>
                    카드 가격은 카드거래에 올라온 판매글 기준으로 실시간 업데이트 됩니다
                </p>
            </v-card>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="pa-5 mobile"
        >
            <!-- 카드 이름 표시 -->
            <Name 
                :card_id="card_id"
                :text_nation="text_nation"
            />

            <v-select
                style="font-size:12px;"
                class="rounded-10 mt-3"
                hide-details
                height="33"
                dense
                outlined
                :items="select_list.show_type"
                v-model="show_type"
                @change="show_type_changed()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-1 pb-1">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon size="18" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- TEXT 언어선택 -->
            <v-sheet
                v-if="$route.path.startsWith('/card/detail')"
                class="d-flex mt-2 mb-5"
            >
                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15 mr-1"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    :color="text_nation_local == 'kr'? '#615DFA':'#ADAFCA'"
                    @click="$emit('text_nation_changed', 'kr')"
                >
                    한글
                </v-btn>
                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15 mr-1"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    :color="text_nation_local == 'en'? '#615DFA':'#ADAFCA'"
                    @click="$emit('text_nation_changed', 'en')"
                >
                    영어
                </v-btn>
                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    :color="text_nation_local == 'jp'? '#615DFA':'#ADAFCA'"
                    @click="$emit('text_nation_changed', 'jp')"
                >
                    일어
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    color="primary"
                    @click="scrollToTop"
                >
                    맨 위로
                </v-btn>
            </v-sheet>

            <!-- 차트 -->
            <Charts
                v-if="chart_mode == '기본'"
                :card_id="card_id"
                :text_nation="text_nation"
                @avgUpdated="avgUpdated"
            />

            <Charts2
                v-if="chart_mode == 'PSA'"
                :card_id="card_id"
                :text_nation="text_nation"
                @avgUpdated="avgUpdated"
            />

            <Charts3
                v-if="chart_mode == '기타'"
                :card_id="card_id"
                :text_nation="text_nation"
                @avgUpdated="avgUpdated"
            />

            <!-- 카드 시세 표시 -->
            <v-sheet
                v-if="mode_type == '기본'"
                class="px-4 mt-6"
                style="position:relative;"
            >
                <!-- 평균가, 최근거래가 -->
                <v-sheet class="py-2 shadow_mobile rounded-15">
                    <p class="my-0 font-weight-bold d-flex align-center justify-center">
                        <span style="font-size:12px; color:#615DFA;">평균가(모든등급)</span>
                        <span class="ml-4" style="font-size:16px;">￦{{$toComma(total_avg)}}원</span>
                    </p>
                </v-sheet>
                <v-sheet class="py-2 shadow_mobile mt-4 rounded-15">
                    <p class="my-0 font-weight-bold d-flex align-center justify-center">
                        <span style="font-size:12px; color:#F8468D;">최근거래가(S급)</span>
                        <span class="ml-4" style="font-size:16px;">￦{{$toComma(recent_s)}}원</span>
                    </p>
                </v-sheet>

                <!-- TODO -->
                <!--  && total_avg == 0 -->
                <v-row
                    class="mt-2 py-4"
                    color="transparent"
                    no-gutters
                    :style="user_post_list.length == 0 && ids.length == 1? 'opacity:0.2':''"
                >
                    <!-- 차트별 평균가 -->
                    <v-col cols="6" color="transparent">
                        <v-sheet color="transparent">
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#F8468D; display:inline-block;"
                                >
                                    S급
                                </span>
                                ￦{{$toComma(avg.S)}}원
                            </p>
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#FF7A51; display:inline-block;"
                                >
                                    A급
                                </span>
                                ￦{{$toComma(avg.A)}}원
                            </p>
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#1DA1F2; display:inline-block;"
                                >
                                    B급
                                </span>
                                ￦{{$toComma(avg.B)}}원
                            </p>
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#23D2E2; display:inline-block;"
                                >
                                    C급
                                </span>
                                ￦{{$toComma(avg.C_below)}}원
                            </p>
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#615DFA; display:inline-block;"
                                >
                                    등급
                                </span>
                                ￦{{$toComma(avg.PSG)}}원
                            </p>
                        </v-sheet>
                    </v-col>

                    <!-- 최고가, 최저가, 총 거래 건수, 거래등록, 거래완료 -->
                    <v-col cols="6">
                        <v-sheet>
                            <p class="mt-0 mb-1 text-truncate" style="font-size:12px;">
                                최고가 : ￦{{$toComma(total_max)}}원
                            </p>
                            <p class="mt-0 mb-0 text-truncate" style="font-size:12px;">
                                최저가 : ￦{{$toComma(total_min)}}원
                            </p>
                            <br/>
                            <p class="mt-0 mb-1 font-weight-bold" style="font-size:12px;">
                                총 거래 건수 : {{$toComma(postCount.total)}}건
                            </p>
                            <p class="mt-0 mb-0 text-truncate" style="font-size:12px;">
                                거래등록<span style="color:#615DFA;">({{$toComma(postCount.registered)}})</span> :
                                ￦{{$toComma(postCount.registered_price)}}원
                            </p>
                            <p class="mt-0 mb-1 text-truncate" style="font-size:12px;">
                                거래완료<span style="color:#615DFA;">({{$toComma(postCount.finished)}})</span> :
                                ￦{{$toComma(postCount.finished_price)}}원
                            </p>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 관리자 입력조차 없는 경우 -->
                <div
                    v-if="admin_post_list.length != 0 && user_post_list.length == 0 && ids.length == 1"
                    style="position:absolute; top:154px; left:50%; transform:translateX(-50%); z-index:10; width:300px; "
                >
                    <p
                        style="font-size:13px; font-weight:bold; text-align:center;"
                    >
                        해당 카드는 아직 국내에 거래 이력이 없어<br/>
                        <span style="color:#615dfa; cursor:pointer;" @click="$router.push('/board/read?board_group=너정다&board_name=이용가이드&id=401')">해외 거래 시세로 적용</span>되었습니다<br/>
                        실 거래가와 차이가 있을 수 있으니 참고 바랍니다
                    </p>
                </div>
            </v-sheet>

            <!-- 카드 시세 표시 -->
            <v-sheet
                v-if="mode_type == '등급'"
                class="px-4 mt-6"
                style="position:relative;"
            >
                <div
                    class="d-flex"
                >
                    <v-sheet
                        width="50%"
                        class="text-center rounded-15 py-1 mr-2"
                        style="font-size:16px; color:#615DFA; font-weight:600; cursor:pointer;"
                        :style="mode == 'PSA'? 'border:1px solid #615DFA':'border:1px solid #e0e0e0'"
                        @click="mode = 'PSA'"
                    >
                        PSA
                    </v-sheet>
                    <v-sheet
                        width="50%"
                        class="text-center rounded-15 py-1"
                        style="font-size:16px; color:#615DFA; font-weight:600; cursor:pointer;"
                        :style="mode == 'ETC'? 'border:1px solid #615DFA':'border:1px solid #e0e0e0'"
                        @click="mode = 'ETC'"
                    >
                        기타
                    </v-sheet>
                </div>

                <!-- PSA -->
                <v-row
                    v-if="mode == 'PSA'"
                    class="mt-2 py-4"
                    color="transparent"
                    no-gutters
                    :style="user_post_list.length == 0 && ids.length == 1? 'opacity:0.2':''"
                >
                    <!-- 1 ~ 5위 -->
                    <v-col
                        cols="6"
                    >
                        <p
                            v-for="item in grade_PSA_avg.slice(0,5)" :key="item.name"
                            class="mt-0 mb-1"
                            style="font-size:12px;"
                        >
                            <span
                                class="py-1 mr-2 white--text rounded-5 text-center"
                                style="font-size:10px; width:44px; display:inline-block;"
                                :style="caculateColor(item)"
                            >
                                PSA {{item.name.split('_')[1] / 10}}
                            </span>
                            ￦{{item.value}}원
                        </p>
                    </v-col>

                    <!-- 6 ~ 10위 -->
                    <v-col
                        cols="6"
                    >
                        <p
                            v-for="item in grade_PSA_avg.slice(5,10)" :key="item.name"
                            class="mt-0 mb-1"
                            style="font-size:12px;"
                        >
                            <span
                                class="py-1 mr-2 white--text rounded-5 text-center"
                                style="font-size:10px; width:44px; display:inline-block;"
                                :style="caculateColor(item)"
                            >
                                PSA {{item.name.split('_')[1] / 10}}
                            </span>
                            ￦{{item.value}}원
                        </p>
                    </v-col>
                </v-row>

                <!-- ETC -->
                <v-row
                    v-if="mode == 'ETC'"
                    class="mt-2 py-4"
                    color="transparent"
                    no-gutters
                    :style="user_post_list.length == 0 && ids.length == 1? 'opacity:0.2':''"
                >
                    <!-- 1 ~ 5위 -->
                    <v-col
                        cols="6"
                    >
                        <p
                            v-for="item in grade_ETC_avg.slice(0,5)" :key="item.name"
                            class="mt-0 mb-1"
                            style="font-size:12px;"
                        >
                            <span
                                class="py-1 mr-2 white--text rounded-5 text-center"
                                style="font-size:10px; width:44px; display:inline-block;"
                                :style="caculateColor(item)"
                            >
                                기타 {{item.name.split('_')[1] / 10}}
                            </span>
                            ￦{{item.value}}원
                        </p>
                    </v-col>

                    <!-- 6 ~ 10위 -->
                    <v-col
                        cols="6"
                    >
                        <p
                            v-for="item in grade_ETC_avg.slice(5,10)" :key="item.name"
                            class="mt-0 mb-1"
                            style="font-size:12px;"
                        >
                            <span
                                class="py-1 mr-2 white--text rounded-5 text-center"
                                style="font-size:10px; width:44px; display:inline-block;"
                                :style="caculateColor(item)"
                            >
                                기타 {{item.name.split('_')[1] / 10}}
                            </span>
                            ￦{{item.value}}원
                        </p>
                    </v-col>
                </v-row>

                <!-- TODO -->
                <!--  && total_avg == 0 -->
                <v-row
                    v-if="mode_type == '기본'"
                    class="mt-2 py-4"
                    color="transparent"
                    no-gutters
                    :style="user_post_list.length == 0 && ids.length == 1? 'opacity:0.2':''"
                >
                    <!-- 차트별 평균가 -->
                    <v-col cols="6" color="transparent">
                        <v-sheet color="transparent">
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#F8468D; display:inline-block;"
                                >
                                    S급
                                </span>
                                ￦{{$toComma(avg.S)}}원
                            </p>
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#FF7A51; display:inline-block;"
                                >
                                    A급
                                </span>
                                ￦{{$toComma(avg.A)}}원
                            </p>
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#1DA1F2; display:inline-block;"
                                >
                                    B급
                                </span>
                                ￦{{$toComma(avg.B)}}원
                            </p>
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#23D2E2; display:inline-block;"
                                >
                                    C급
                                </span>
                                ￦{{$toComma(avg.C_below)}}원
                            </p>
                            <p class="mt-0 mb-1" style="font-size:12px;">
                                <span
                                    class="py-1 mr-2 white--text rounded-5 text-center"
                                    style="font-size:10px; width:30px; background:#615DFA; display:inline-block;"
                                >
                                    등급
                                </span>
                                ￦{{$toComma(avg.PSG)}}원
                            </p>
                        </v-sheet>
                    </v-col>

                    <!-- 최고가, 최저가, 총 거래 건수, 거래등록, 거래완료 -->
                    <v-col cols="6">
                        <v-sheet>
                            <p class="mt-0 mb-1 text-truncate" style="font-size:12px;">
                                최고가 : ￦{{$toComma(total_max)}}원
                            </p>
                            <p class="mt-0 mb-0 text-truncate" style="font-size:12px;">
                                최저가 : ￦{{$toComma(total_min)}}원
                            </p>
                            <br/>
                            <p class="mt-0 mb-1 font-weight-bold" style="font-size:12px;">
                                총 거래 건수 : {{$toComma(postCount.total)}}건
                            </p>
                            <p class="mt-0 mb-0 text-truncate" style="font-size:12px;">
                                거래등록<span style="color:#615DFA;">({{$toComma(postCount.registered)}})</span> :
                                ￦{{$toComma(postCount.registered_price)}}원
                            </p>
                            <p class="mt-0 mb-1 text-truncate" style="font-size:12px;">
                                거래완료<span style="color:#615DFA;">({{$toComma(postCount.finished)}})</span> :
                                ￦{{$toComma(postCount.finished_price)}}원
                            </p>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-row
                    v-if="mode_type == '등급'"
                    class="mt-2 pb-4"
                    color="transparent"
                    no-gutters
                    :style="user_post_list.length == 0 && ids.length == 1? 'opacity:0.2':''"
                >

                    <!-- 최고가, 최저가, 총 거래 건수, 거래등록, 거래완료 -->
                    <v-col cols="12">
                        <v-sheet>
                            <p class="mt-0 mb-1 text-truncate" style="font-size:12px;">
                                최고가 : ￦{{$toComma(total_max)}}원
                            </p>
                            <p class="mt-0 mb-0 text-truncate" style="font-size:12px;">
                                최저가 : ￦{{$toComma(total_min)}}원
                            </p>
                            <br/>
                            <p class="mt-0 mb-1 font-weight-bold" style="font-size:12px;">
                                총 거래 건수 : {{$toComma(postCount.total)}}건
                            </p>
                            <p class="mt-0 mb-0 text-truncate" style="font-size:12px;">
                                거래등록<span style="color:#615DFA;">({{$toComma(postCount.registered)}})</span> :
                                ￦{{$toComma(postCount.registered_price)}}원
                            </p>
                            <p class="mt-0 mb-1 text-truncate" style="font-size:12px;">
                                거래완료<span style="color:#615DFA;">({{$toComma(postCount.finished)}})</span> :
                                ￦{{$toComma(postCount.finished_price)}}원
                            </p>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 관리자 입력조차 없는 경우 -->
                <div
                    v-if="admin_post_list.length != 0 && user_post_list.length == 0 && ids.length == 1"
                    style="position:absolute; top:154px; left:50%; transform:translateX(-50%); z-index:10; width:300px; "
                >
                    <p
                        style="font-size:13px; font-weight:bold; text-align:center;"
                    >
                        해당 카드는 아직 국내에 거래 이력이 없어<br/>
                        <span style="color:#615dfa; cursor:pointer;" @click="$router.push('/board/read?board_group=너정다&board_name=이용가이드&id=401')">해외 거래 시세로 적용</span>되었습니다<br/>
                        실 거래가와 차이가 있을 수 있으니 참고 바랍니다
                    </p>
                </div>
            </v-sheet>

            <!-- 관리자 입력만 있는 경우 -->
            <!-- <div
                v-if="admin_post_list.length == 0 && user_post_list.length == 0 "
                style="position:absolute; top:55%; left:50%; transform:translateX(-50%); z-index:10; width:300px; "
            >
                <p
                    style="font-size:13px; font-weight:bold; text-align:center;"
                >
                    해당 카드는 아직 거래 이력이 없어<br/>
                    <span style="color:#615dfa;">희귀도와 이름이 같은 카드 가격이 적용</span>되었습니다<br/>
                    실 거래가와 차이가 있을 수 있으니 참고 바랍니다
                </p>
            </div> -->

            <!-- 거래글 -->
            <v-sheet class="pt-2 mt-2 mb-4 ">
                <!-- 제목 -->
                <p class="text-center font-weight-bold mb-6" style="font-size:14px;">
                    최근 카드거래 글
                    <v-icon class="pb-1">mdi-chevron-down</v-icon>
                </p>

                <!-- 목록 -->
                <v-row v-if="list.length">
                    <v-col
                        :cols="12"
                        v-for="item in list"
                        :key="item.id"
                    >
                        <v-sheet
                            style="cursor:pointer; border-bottom:1px solid #eee;"
                            @click="$router.push('/trade/post/read?id='+item.id)"
                        >
                            <v-sheet class="d-flex align-center" style="position:relative;">
                                <v-sheet class="ml-3">
                                    <!-- 카드 이름 -->
                                    <p
                                        class="mb-1 font-weight-bold text-truncate "
                                        style="font-size:13px; line-height:16px;"
                                    >
                                        {{item.title}}
                                    </p>
                                    <p
                                        style="font-size:11px; color:#ADAFCA;"
                                    >
                                        {{new Date(item.created).toLocaleString()}}
                                    </p>

                                    <!-- 카드등급 / 카드가격 -->
                                    <p
                                        class="font-weight-bold my-0 ml-2 text-center"
                                        style="position:absolute; top:-3px; right:0px; line-height:18px;"
                                    >
                                        <span v-if="item.card_state" style="font-size:14px; color:#23D2E2;">{{item.card_state}}</span><br/>
                                        <span v-if="item.card_price" style="font-size:14px;">{{$toComma(item.card_price)}}원</span>
                                    </p>
                                </v-sheet>
                            </v-sheet>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- 거래글 없을 시 -->
                <v-row v-if="!list.length">
                    <v-col>
                        <p class="text-center my-0" style="font-size:13px;">해당 카드의 카드거래글이 없습니다.</p>
                    </v-col>
                </v-row>
            </v-sheet>

            <!--카드 가격 안내 멘트-->
            <v-card flat class="px-2 text-center">
                <p class="font-weight-bold mb-1 grey--text" style="font-size:9px;">
                    <v-icon small color="grey" class="mb-1">
                        mdi-alert-circle-outline
                    </v-icon>
                    카드 가격은 카드거래에 올라온 판매글 기준으로 실시간 업데이트 됩니다
                </p>
            </v-card>
        </div>

        <!-- 테스트용 -->
        <!-- <div>
            관리자 : {{admin_post_list.length}}
            유저 : {{user_post_list.length}}
            ids : {{ids}}
        </div> -->
    </div>
</template>
<script>
import Name from './CardTrade/Name'
import Charts from './CardTrade/Charts'
import Charts2 from './CardTrade/Charts2'
import Charts3 from './CardTrade/Charts3'

export default {
    components: {
        Name,
        Charts,
        Charts2,
        Charts3
    },

    props: [
        "card_id",
        "text_nation"
    ],

    data: () => ({
        total_avg: 0,
        total_max: 0,
        total_min: 0,

        recent_s: 0,

        postCount: {
            total: 0,
            registered: 0,
            finished: 0,
            registered_price: 0,
            finished_price: 0,
        },

        avg: {
            S: 0,
            A: 0,
            B: 0,
            C_below: 0,
            PSG: 0,

            PSA_100: 0,
            PSA_95: 0,
            PSA_90: 0,
            PSA_85: 0,
            PSA_80: 0,
            PSA_75: 0,
            PSA_70: 0,
            PSA_65: 0,
            PSA_60: 0,
            PSA_55: 0,
            PSA_50: 0,
            PSA_45: 0,
            PSA_40: 0,
            PSA_35: 0,
            PSA_30: 0,
            PSA_25: 0,
            PSA_20: 0,
            PSA_15: 0,
            PSA_10: 0,

            ETC_100: 0,
            ETC_95: 0,
            ETC_90: 0,
            ETC_85: 0,
            ETC_80: 0,
            ETC_75: 0,
            ETC_70: 0,
            ETC_65: 0,
            ETC_60: 0,
            ETC_55: 0,
            ETC_50: 0,
            ETC_45: 0,
            ETC_40: 0,
            ETC_35: 0,
            ETC_30: 0,
            ETC_25: 0,
            ETC_20: 0,
            ETC_15: 0,
            ETC_10: 0,
        },

        // 목록
        list: [],

        // 페이징
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,

        ids: [],

        admin_post_list: [],
        user_post_list: [],
        componentKey: 0,

        grade_PSA_avg: [],
        grade_ETC_avg: [],
        mode_type: "기본",
        mode: "PSA",

        chart_mode: "기본",

        show_type: "싱글카드 실거래가",

        select_list: {
            show_type: [
                "싱글카드 실거래가",
                "등급카드 실거래가",
            ]
        }
    }),

    computed: {
        text_nation_local() {
            return this.text_nation
        },
    },

    watch: {
        mode_type(){
            if(this.mode_type == "기본"){
                this.chart_mode = "기본"
                this.componentKey++
            }
            else if(this.mode_type == "등급"){
                if(this.mode == "PSA"){
                    this.chart_mode = "PSA"
                    this.componentKey++
                }else{
                    this.chart_mode = "기타"
                    this.componentKey++
                }
            }
        },

        mode(){
            if(this.mode_type == "기본"){
                this.chart_mode = "기본"
                this.componentKey++
            }
            else if(this.mode_type == "등급"){
                if(this.mode == "PSA"){
                    this.chart_mode = "PSA"
                    this.componentKey++
                }else{
                    this.chart_mode = "기타"
                    this.componentKey++
                }
            }
        },

        async text_nation(){
            this.avg = {
                S: 0,
                A: 0,
                B: 0,
                C_below: 0,
                PSG: 0
            }

            this.isOnlyAdmin()

            await this.searchSameGroupCard()

            // 거래글 목록
            this.load()
            this.loadTotal()
            this.loadPost()
            this.loadRecentS()

            this.componentKey++
        }
    },

    async mounted(){
        await this.searchSameGroupCard()
        // console.log(this.ids)

        // 거래글 목록
        await this.load()
        this.loadTotal()
        this.loadPost()
        this.loadRecentS()

        this.isOnlyAdmin()

        this.componentKey++
    },

    methods: {
        show_type_changed(){
            if(this.show_type == "등급카드 실거래가"){
                this.mode_type = "등급"
                this.mode="PSA"
            }
            else if(this.show_type == "싱글카드 실거래가"){
                this.mode_type = "기본"
            }
        },

        caculateColor(item){
            if(item.name) {
                let number = parseInt(item.name.split('_')[1]) / 10
                if (number == 10) {
                    return 'background:#F8468D;'
                } else if (number >= 9) {
                    return 'background:#FF7A51;'
                } else if (number >= 8) {
                    return 'background:#1DA1F2;'
                } else if (number >= 7) {
                    return 'background:#23D2E2;'
                } else if (number >= 6) {
                    return 'background:#615DFA;'
                } else {
                    return 'background:#ADAFCA;'
                }
            }
        },

        scrollToTop(){
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        isOnlyAdmin(){
            this.$http.post("/api/card/detail/trade/isOnlyAdmin", {
                params: {
                    card_id: this.$route.query.id,
                    card_nation: this.text_nation
                }
            }).then((res) => {
                // console.log(res.data)
                this.admin_post_list = res.data.filter(e => e.post_id == 0)
                this.user_post_list = res.data.filter(e => e.post_id != 0)
            })
        },

        // 가격그룹 찾기
        async searchSameGroupCard(){
            // 슈퍼타입 확인
            let supertype = ""
            await this.$http.post('/api/card/detail/trade/group/isPokemon', {
                params: {
                    card_id: this.card_id
                }
            }).then((res => {
                if(res.data.length){
                    // console.log("슈퍼타입 확인", res)
                    supertype = res.data[0].supertype
                }
            }))

            // 시리즈, 이름, 희귀도 확인
            let card = ""
            await this.$http.post('/api/card/detail/trade/group/import/card/'+this.text_nation, {
                params: {
                    card_id: this.card_id
                }
            }).then((res => {
                // console.log("시리즈, 이름, 희귀도 확인", res)
                if(res.data.length){
                    card = res.data[0]
                }
            }))

            if(card!="" && card.series && card.name && card.rarity){
                // 기술 확인
                let attacks = []
                await this.$http.post('/api/card/detail/trade/group/import/attacks/'+this.text_nation, {
                    params: {
                        card_id: this.card_id
                    }
                }).then((res => {
                    // console.log("기술 확인", res)
                    if(this.text_nation == "en" && res.data.length){
                        attacks = res.data.map(e => e.name)
                    }else{
                        attacks = res.data.map(e => e.name)
                    }
                }))

                // 시리즈, 이름, 희귀도 같은지 확인
                let idSameList = []
                await this.$http.post('/api/card/detail/trade/group/card/'+this.text_nation, {
                    params: {
                        series : card.series,
                        name : card.name,
                        rarity : card.rarity
                    }
                }).then( async(res) => {
                    // console.log("시리즈, 이름, 희귀도 같은지 확인", res)
                    if(res.data.length){
                        idSameList = await res.data.map(e => e.id)
                    }
                    this.ids = idSameList
                })

                let attacksSameList = []
                if(supertype == "Pokémon")
                {
                    for (let i=0; i<idSameList.length; i++){
                        await this.$http.post('/api/card/detail/trade/group/import/attacks/'+this.text_nation, {
                            params: {
                                card_id: idSameList[i]
                            }
                        }).then( async (res) => {
                            // console.log("기술 이름 같은지 확인", res)
                            if(attacks.toString() == res.data.map(e => e.name).toString()){
                                attacksSameList.push(idSameList[i])
                            }
                        })
                    }

                    if(attacksSameList.length){
                        this.ids = attacksSameList
                    }
                }
            }else{
                this.ids = this.card_id
            }
        },

        // 거래글 목록
        load(){
            // 'this.ids'가 배열이 아니라면, 배열로 변환
            if (!Array.isArray(this.ids)) {
                this.ids = [this.ids];
            }

            this.ids.forEach(e => {
                // 카드별 > 카드 목록
                this.$http.post('/api/card/detail/trade/info/post_list', {
                    params: {
                        card_id: e,
                        card_nation: this.text_nation
                    }
                }).then(res => {
                    this.list = Array.from(new Set(res.data)).slice(0,3)
                })   
            })
        },

        // 평균가, 최고가, 최저가
        loadTotal(){
            this.$http.post("/api/card/detail/trade/info/total", {
                params: {
                    ids: this.ids,
                    card_nation: this.text_nation
                }
            }).then((res) => {
                if(res.data.length){
                    this.total_avg = res.data[0].total_avg == null? 0 : parseInt(res.data[0].total_avg)
                    this.total_max = res.data[0].total_max == null? 0 : parseInt(res.data[0].total_max)
                    this.total_min = res.data[0].total_min == null? 0 : parseInt(res.data[0].total_min)
                }
            })
        },

        // 거래글 수
        loadPost(){
            this.$http.post("/api/card/detail/trade/info/post", {
                params: {
                    ids: this.ids,
                    card_nation: this.text_nation
                }
            }).then((res) => {
                if(res.data.length){
                    this.postCount.total = res.data[0].total == null? 0 : parseInt(res.data[0].total)
                    this.postCount.registered = res.data[0].registered == null? 0 : parseInt(res.data[0].registered)
                    this.postCount.finished = res.data[0].finished == null? 0 : parseInt(res.data[0].finished)
                    this.postCount.registered_price = res.data[0].registered_price == null? 0 : parseInt(res.data[0].registered_price)
                    this.postCount.finished_price = res.data[0].finished_price == null? 0 : parseInt(res.data[0].finished_price)
                }
            })
        },

        // 최근거래가 (S급)
        loadRecentS(){
            this.$http.post("/api/card/detail/trade/info/recent_s", {
                params: {
                    ids: this.ids,
                    card_nation: this.text_nation
                }
            }).then((res) => {
                console.log(res)
                if(res.data.length){
                    this.recent_s = res.data[0].price == null? 0 : parseInt(res.data[0].price)
                }else{
                    this.recent_s = 0
                }
            })
        },

        // 차트별 평균가
        avgUpdated(avg){
            this.avg = avg
            console.log(this.avg)

            // PSA
            this.grade_PSA_avg = Object.entries(avg)
            .filter(([key, value]) => key.startsWith('PSA_') && parseInt(key.split('_')[1]) <= 100 && parseInt(key.split('_')[1]) >= 10)
            .map(([key, value]) => ({ name: key, value: value }))
            .sort((a, b) => {
                if (a.value != 0 && b.value != 0) {
                    return parseInt(b.name.split('_')[1]) - parseInt(a.name.split('_')[1])
                } else if (a.value != 0) {
                    return -1;
                } else if (b.value != 0) {
                    return 1;
                } else {
                    return 0;
                }
            });

            // ETC
            this.grade_ETC_avg = Object.entries(avg)
            .filter(([key, value]) => key.startsWith('ETC_') && parseInt(key.split('_')[1]) <= 100 && parseInt(key.split('_')[1]) >= 10)
            .map(([key, value]) => ({ name: key, value: value }))
            .sort((a, b) => {
                if (a.value != 0 && b.value != 0) {
                    return parseInt(b.name.split('_')[1]) - parseInt(a.name.split('_')[1])
                } else if (a.value != 0) {
                    return -1;
                } else if (b.value != 0) {
                    return 1;
                } else {
                    return 0;
                }
            });


            console.log(this.grade_PSA_avg)
            console.log(this.grade_ETC_avg)
        }
    }
}
</script>
<style scoped>
.shadow{
    box-shadow: 0px 0px 18px 9px #F1F2F6 !important;
}

.shadow_mobile{
    box-shadow: 0px 0px 8px 4px #F1F2F6 !important;
}

/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

/* 모바일 카테고리 */
::v-deep .mobile .v-text-field {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-text-field .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-text-field .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px !important; /* 글꼴 크기 조절 */
    height: 33px;
    margin-left: 12px;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .v-input--switch .v-label {
    font-size:10px !important;
}

::v-deep .mobile .v-v-select__slot {
    font-size:12px !important;
}

::v-deep .mobile .v-select__selections .v-label {
    font-size:12px !important;
}

::v-deep .mobile .v-label {
    font-size:12px !important;
}

::v-deep button {
    min-width: 10px !important;
}
</style>