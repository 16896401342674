<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <v-sheet v-if="card.en && nation_isAvailable">
                <div class="mb-6">
                    <v-row
                        v-if="card.en.supertype!='Trainer'"
                        no-gutters
                    >
                        <v-col cols="3">
                            <div class="d-flex align-center pl-1">
                                <span style="font-size:18px;" class="mr-2">약점</span>
                                <div v-if="card.en.weaknesses">
                                    <v-sheet
                                        v-for="(item, value, index) in card.en.weaknesses.filter(e => e!='' && e!=null)" :key="index"
                                        class="d-flex align-center mx-1"
                                        color="transparent"
                                    >
                                        <!-- input -->
                                        <v-sheet 
                                            v-if="item.hasOwnProperty('input')"
                                            class="d-flex"
                                            style="font-size:18px;"
                                            color="transparent"
                                        >
                                            <v-img
                                                v-if="item.input.type!=undefined"
                                                :width="isBoard? '20':'30'"
                                                :src="require(`@/assets/card/type/${item.input.type}.png`)"
                                                :title="replaceIconToExplain(item.input.type)"
                                            ></v-img>
                                            {{item.value}}
                                        </v-sheet>

                                        <!-- 일반 -->
                                        <v-sheet 
                                            v-else
                                            class="d-flex"
                                            style="font-size:18px;"
                                            color="transparent"
                                        >
                                            <v-img
                                                v-if="item.type!=undefined"
                                                :width="isBoard? '20':'30'"
                                                :src="require(`@/assets/card/type/${item.type}.png`)"
                                                :title="replaceIconToExplain(item.type)"
                                            ></v-img>
                                            {{item.value}}
                                        </v-sheet>
                                    </v-sheet>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <div class="d-flex align-center pl-7">
                                <span style="font-size:18px;" class="mr-2">저항력</span>
                                <div v-if="card.en.resistances">
                                    <v-sheet
                                        v-for="(item, value, index) in card.en.resistances.filter(e => e!='' && e!=null)" :key="index"
                                        class="d-flex align-center mx-1"
                                        color="transparent"
                                    >
                                        <!-- input -->
                                        <v-sheet 
                                            v-if="item.hasOwnProperty('input')"
                                            class="d-flex mr-1"
                                            color="transparent"
                                        >
                                            <v-img
                                                v-if="item.input.type!=undefined"
                                                :width="isBoard? '20':'30'"
                                                :src="require(`@/assets/card/type/${item.input.type}.png`)"
                                                :title="replaceIconToExplain(item.input.type)"
                                            ></v-img>
                                            {{item.value}}
                                        </v-sheet>

                                        <!-- 일반 -->
                                        <v-sheet 
                                            v-else
                                            class="d-flex mr-1"
                                            color="transparent"
                                        >
                                            <v-img
                                                v-if="item.type!=undefined"
                                                :width="isBoard? '20':'30'"
                                                :src="require(`@/assets/card/type/${item.type}.png`)"
                                                :title="replaceIconToExplain(item.type)"
                                            ></v-img>
                                            {{item.value}}
                                        </v-sheet>
                                    </v-sheet>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="5">
                            <div class="d-flex align-center pl-5">
                                <span style="font-size:18px;" class="mr-2">후퇴비용</span>
                                <v-sheet
                                    v-if="card.en.retreatCost"
                                    class="d-flex justify-center"
                                    color="transparent"
                                >
                                    <v-sheet
                                        class="d-flex mx-2"
                                        color="transparent"
                                    >
                                        <v-img
                                            :width="isBoard? '20':'30'"
                                            v-for="(item, index) in card.en.retreatCost.filter(e => e!='' && e!=null)" :key="index"
                                            :src="require(`@/assets/card/type/${item}.png`)"
                                            :title="replaceIconToExplain(item)"
                                        ></v-img>
                                    </v-sheet>
                                </v-sheet>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <!-- 제품명 -->
                        <v-col class="ml-1">
                            <!-- 한글 -->
                            <v-sheet v-if="text_nation=='kr' && card.kr && card.kr.isAvailable && card.kr.set_name">
                                <span
                                    @click="searchBySetName(card.kr.set_name)" 
                                    style="cursor:pointer; font-size:18px; font-weight:bold;"
                                >
                                    {{card.kr.set_name}}
                                </span>
                            </v-sheet>

                            <!-- 영어 -->
                            <v-sheet v-if="text_nation=='en' && card.en && card.en.isAvailable && card.en.set_name">
                                <span
                                    @click="searchBySetName(card.en.set_name)" 
                                    style="cursor:pointer; font-size:18px; font-weight:bold;" 
                                >
                                    {{card.en.set_name}}
                                </span>
                            </v-sheet>

                            <!-- 일어 -->
                            <v-sheet v-if="text_nation=='jp' && card.jp && card.jp.isAvailable && card.jp.set_name">
                                <span
                                    @click="searchBySetName(card.jp.set_name)"
                                    style="cursor:pointer; font-size:18px; font-weight:bold;" 
                                >
                                    {{card.jp.set_name}}
                                </span>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <v-sheet v-if="card.en && nation_isAvailable">
                <div class="px-7 pb-2">
                    <v-row
                        v-if="card.en.supertype!='Trainer'"
                        no-gutters
                    >
                        <v-col cols="6">
                            <div class="d-flex align-center py-1">
                                <span style="font-size:13px;" class="mr-2">약점</span>
                                <div v-if="card.en.weaknesses">
                                    <v-sheet
                                        v-for="(item, value, index) in card.en.weaknesses.filter(e => e!='' && e!=null)" :key="index"
                                        class="d-flex align-center mx-1"
                                        color="transparent"
                                    >
                                        <!-- input -->
                                        <v-sheet 
                                            v-if="item.hasOwnProperty('input')"
                                            class="d-flex"
                                            style="font-size:13px;"
                                            color="transparent"
                                        >
                                            <v-img
                                                v-if="item.input.type!=undefined"
                                                :width="isBoard? '20':'20'"
                                                :src="require(`@/assets/card/type/${item.input.type}.png`)"
                                                :title="replaceIconToExplain(item.input.type)"
                                            ></v-img>
                                            {{item.value}}
                                        </v-sheet>

                                        <!-- 일반 -->
                                        <v-sheet 
                                            v-else
                                            class="d-flex"
                                            style="font-size:13px;"
                                            color="transparent"
                                        >
                                            <v-img
                                                v-if="item.type!=undefined"
                                                :width="isBoard? '20':'20'"
                                                :src="require(`@/assets/card/type/${item.type}.png`)"
                                                :title="replaceIconToExplain(item.type)"
                                            ></v-img>
                                            {{item.value}}
                                        </v-sheet>
                                    </v-sheet>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div class="d-flex align-center py-1">
                                <span style="font-size:13px;" class="mr-2">저항력</span>
                                <div v-if="card.en.resistances">
                                    <v-sheet
                                        v-for="(item, value, index) in card.en.resistances.filter(e => e!='' && e!=null)" :key="index"
                                        class="d-flex align-center mx-1"
                                        color="transparent"
                                    >
                                        <!-- input -->
                                        <v-sheet 
                                            v-if="item.hasOwnProperty('input')"
                                            class="d-flex mr-1"
                                            color="transparent"
                                        >
                                            <v-img
                                                v-if="item.input.type!=undefined"
                                                :width="isBoard? '20':'20'"
                                                :src="require(`@/assets/card/type/${item.input.type}.png`)"
                                                :title="replaceIconToExplain(item.input.type)"
                                            ></v-img>
                                            {{item.value}}
                                        </v-sheet>

                                        <!-- 일반 -->
                                        <v-sheet 
                                            v-else
                                            class="d-flex mr-1"
                                            color="transparent"
                                        >
                                            <v-img
                                                v-if="item.type!=undefined"
                                                :width="isBoard? '20':'20'"
                                                :src="require(`@/assets/card/type/${item.type}.png`)"
                                                :title="replaceIconToExplain(item.type)"
                                            ></v-img>
                                            {{item.value}}
                                        </v-sheet>
                                    </v-sheet>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="d-flex align-center py-1">
                                <span style="font-size:13px;" class="mr-2">후퇴비용</span>
                                <v-sheet
                                    v-if="card.en.retreatCost"
                                    class="d-flex justify-center"
                                    color="transparent"
                                >
                                    <v-sheet
                                        class="d-flex mx-2"
                                        color="transparent"
                                    >
                                        <v-img
                                            :width="isBoard? '20':'20'"
                                            v-for="(item, index) in card.en.retreatCost.filter(e => e!='' && e!=null)" :key="index"
                                            :src="require(`@/assets/card/type/${item}.png`)"
                                            :title="replaceIconToExplain(item)"
                                        ></v-img>
                                    </v-sheet>
                                </v-sheet>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    computed: {
        // card_check(){
        //     if(this.card){
        //         if(this.text_nation=='kr'){
        //             if((Object.keys(this.card.kr).length || this.card.kr.rarity!=null)){
        //                 return true
        //             }else{
        //                 return false
        //             }
        //         }else if(this.text_nation=='jp'){
        //             if((Object.keys(this.card.jp).length || this.card.jp.rarity!=null)){
        //                 return true
        //             }else{
        //                 return false
        //             }
        //         }else if(this.text_nation=='en'){
        //             if(Object.keys(this.card.en).length){
        //                 return true
        //             }else{
        //                 return false
        //             }
        //         }
        //     }
        // },

        nation_isAvailable(){
            if(this.card){
                if(this.text_nation=='kr'){
                    if((this.card.kr.isAvailable)){
                        return true
                    }else{
                        return false
                    }
                }else if(this.text_nation=='jp'){
                    if((this.card.jp.isAvailable)){
                        return true
                    }else{
                        return false
                    }
                }else if(this.text_nation=='en'){
                    if(this.card.en.isAvailable){
                        return true
                    }else{
                        return false
                    }
                }
            }
        }
    },

    methods: {
        // 에너지 설명
        replaceIconToExplain(text){
            if(!text){
                return text
            }
            text = text.replaceAll('0Cost', `무 에너지`)
            text = text.replaceAll('Grass', `풀 에너지`)
            text = text.replaceAll('Fire', `불꽃 에너지`)
            text = text.replaceAll('Water', `물 에너지`)
            text = text.replaceAll('Lightning', `번개 에너지`)
            text = text.replaceAll('Psychic', `초 에너지`)
            text = text.replaceAll('Fighting', `격투 에너지`)
            text = text.replaceAll('Darkness', `악 에너지`)
            text = text.replaceAll('Metal', `강철 에너지`)
            text = text.replaceAll('Fairy', `페어리 에너지`)
            text = text.replaceAll('Dragon', `드래곤 에너지`)
            text = text.replaceAll('Colorless', `무색 에너지`)
            return text
        },

        // 제품명 검색
        searchBySetName(set_name){
            // 카드거래 판매글쓰기에서는 제외
            if(!this.$route.path.startsWith('/trade')){

                let set_nation = '' 
                if(this.text_nation=='en')
                {
                    set_nation="영어 제품명"
                }
                else if(this.text_nation=='kr')
                {
                    set_nation="한글 제품명"
                }
                else if(this.text_nation=='jp')
                {
                    set_nation="일본 제품명"
                }

                // 페이지 이동
                this.$router.push('/card/list?set_nation='+set_nation+'&set_name='+encodeURIComponent(set_name))
            }
        }
    }
}
</script>