<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            min-height="640"
        >
            <!-- 카드이름 -->
            <CardName :card="card" :text_nation="text_nation" :isBoard="false" />
            <v-sheet class="d-flex">
                <v-sheet width="30%">
                    <!-- 이미지 -->
                    <CardImage :card="card" />
                    <!-- 아티스트, 블록넘버, 넘버, 토탈넘버 -->
                    <CardSide v-if="card" :card="card" :text_nation="text_nation" :isBoard="false" />
                </v-sheet>
                <v-sheet class="ml-2" width="69%">
                    <div class="d-flex align-center">
                        <!-- TEXT 언어선택 -->
                        <v-sheet class="d-flex justify-space-between">
                            <v-btn
                                depressed
                                text
                                tile
                                height="48"
                                class="pl-1 pr-2 mr-1"
                                v-ripple="false"
                                style="font-size:20px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                                :color="text_nation_in_info == 'kr'? 'primary':''"
                                :style="text_nation_in_info == 'kr'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                                :dark="text_nation_in_info == 'kr'"
                                @click="text_nation_in_info = 'kr'"
                            >
                                한글
                            </v-btn>
                            <v-btn
                                depressed
                                text
                                tile
                                height="48"
                                class="pl-1 pr-2 mr-1"
                                v-ripple="false"
                                style="font-size:20px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                                :color="text_nation_in_info == 'en'? 'primary':''"
                                :style="text_nation_in_info == 'en'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                                :dark="text_nation_in_info == 'en'"
                                @click="text_nation_in_info = 'en'"
                            >
                                영어
                            </v-btn>
                            <v-btn
                                depressed
                                text
                                tile
                                height="48"
                                class="pl-1 pr-2"
                                v-ripple="false"
                                style="font-size:20px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                                :color="text_nation_in_info == 'jp'? 'primary':''"
                                :style="text_nation_in_info == 'jp'? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                                :dark="text_nation_in_info == 'jp'"
                                @click="text_nation_in_info = 'jp'"
                            >
                                일어
                            </v-btn>
                        </v-sheet>

                        <v-spacer></v-spacer>

                        <!-- SUBTYPES, HP, TYPES -->
                        <Subtypes :card="card" :isBoard="false" />
                    </div>
                    <v-divider class="mb-6" color="#eaeaea"></v-divider>

                    <!-- 카드 정보가 없습니다 -->
                    <NoData :card="card" :text_nation="text_nation" :isBoard="false" />

                    <!-- 카드가 발매되지 않았습니다 -->
                    <NotAvailable :card="card" :text_nation="text_nation" :isBoard="false" />

                    <v-sheet>
                        <!-- 고대능력 -->
                        <Ancient :card="card" :text_nation="text_nation" :isBoard="false" />

                        <!-- 능력 -->
                        <Abilities :card="card" :text_nation="text_nation" :isBoard="false" />

                        <!-- 기술 -->
                        <Attacks :card="card" :text_nation="text_nation" :isBoard="false" />

                        <!-- 효과 & 룰 -->
                        <EffectsRules :card="card" :text_nation="text_nation" :isBoard="false" />

                        <v-divider class="my-7"></v-divider>

                        <!-- HP, 도감번호, 희귀도 -->
                        <HpNumberRarity :card="card" :text_nation="text_nation" :isBoard="false" />

                        <!-- 약점, 저항력, 후퇴비용 -->
                        <Weaknesses :card="card" :text_nation="text_nation" :isBoard="false" :key="componentKey" />
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            min-height="640"
            color="transparent"
        >
            <!-- 카드이름 -->
            <CardName :card="card" :text_nation="text_nation" :isBoard="false" />

            <!-- TEXT 언어선택 -->
            <div class="d-flex px-6">
                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15 mr-1"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    :color="text_nation_in_info == 'kr'? '#615DFA':'#ADAFCA'"
                    @click="text_nation_in_info = 'kr'"
                >
                    한글
                </v-btn>
                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15 mr-1"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    :color="text_nation_in_info == 'en'? '#615DFA':'#ADAFCA'"
                    @click="text_nation_in_info = 'en'"
                >
                    영어
                </v-btn>
                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    :color="text_nation_in_info == 'jp'? '#615DFA':'#ADAFCA'"
                    @click="text_nation_in_info = 'jp'"
                >
                    일어
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    depressed
                    dark
                    width="60"
                    height="34"
                    class="rounded-15"
                    v-ripple="false"
                    style="font-size:12px; font-weight:400; min-width:0px;"
                    color="primary"
                    @click="$router.push('/card/list')"
                >
                    뒤로
                </v-btn>
            </div>

            <!-- 이미지 -->
            <CardImage v-if="card" :card="card" :text_nation="text_nation" :isBoard="false" />

            <!-- 아티스트, 블록넘버, 넘버, 토탈넘버 -->
            <CardSide
                v-if="card && !$vuetify.breakpoint.mobile"
                :card="card"
                :text_nation="text_nation"
                :isBoard="false"
            />

            <!-- SUBTYPES, HP, TYPES -->
            <Subtypes :card="card" :isBoard="false" />

            <!-- 카드 정보가 없습니다 -->
            <NoData :card="card" :text_nation="text_nation" :isBoard="false" />

            <!-- 카드가 발매되지 않았습니다 -->
            <NotAvailable :card="card" :text_nation="text_nation" :isBoard="false" />

            <!-- 고대능력 -->
            <Ancient :card="card" :text_nation="text_nation" :isBoard="false" />

            <!-- 능력 -->
            <Abilities :card="card" :text_nation="text_nation" :isBoard="false" />

            <!-- 기술 -->
            <Attacks :card="card" :text_nation="text_nation" :isBoard="false" />

            <!-- 효과 & 룰 -->
            <EffectsRules :card="card" :text_nation="text_nation" :isBoard="false" />

            <v-divider class="my-7" v-if="!$vuetify.breakpoint.mobile"></v-divider>

            <!-- HP, 도감번호, 희귀도 -->
            <HpNumberRarity :card="card" :text_nation="text_nation" :isBoard="false" />

            <!-- 약점, 저항력, 후퇴비용 -->
            <Weaknesses :card="card" :text_nation="text_nation" :isBoard="false" :key="componentKey" />

            <v-sheet
                class="pb-2 d-flex justify-end rounded-10"
            >
                <div>
                    <p
                        class="text-end mr-3 mb-0"
                        style="font-size:10px; color:#ADAFCA;"
                    >
                        {{ view_count }}명이 이 카드를 봤습니다
                    </p>
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import CardName from './CardInfo/Name'
import CardImage from './CardInfo/Image'
import CardSide from './CardInfo/Side'
import Subtypes from './CardInfo/Subtypes'
import NoData from './CardInfo/NoData'
import NotAvailable from './CardInfo/NotAvailable'
import Ancient from './CardInfo/Ancient'
import Abilities from './CardInfo/Abilities'
import Attacks from './CardInfo/Attacks'
import Weaknesses from './CardInfo/Weaknesses'
import HpNumberRarity from './CardInfo/Hp&Number&Rarity'
import EffectsRules from './CardInfo/Effects&Rules'

export default {
    props: ["text_nation"],

    components: {
        CardName,
        CardImage,
        CardSide,
        Subtypes,
        NoData,
        NotAvailable,
        Ancient,
        Abilities,
        Attacks,
        Weaknesses,
        HpNumberRarity,
        EffectsRules
    },

    data: () => ({
        card: {
            en: {
                isAvailable: 1,
                series: ""
            },
            kr: {},
            jp: {}
        },

        isPreCard: false,
        cardImport: {
            dump: {},
            set: {
                name: "",
                printedTotal: ""
            }
        },

        text_nation_in_info: "en",
        componentKey: 0,

        view_count: 0
    }),

    watch: {
        text_nation_in_info(){
            this.$emit('text_nation_changed', this.text_nation_in_info)
        },
    },

    mounted(){
        // 카드 불러오기
        this.load()

        // 조회수 불러오기
        this.loadViewCount()
    },

    methods: {
        // 조회수 불러오기
        loadViewCount(){
            this.$http.post("/api/card/select/view_count", {
                params: {
                    card_id: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length)
                {
                    this.view_count = res.data[0].view_count
                }
            })
        },

        reload(){
            this.$router.go(this.$router.currentRoute)
        },

        forceRerenderer(){
            this.componentKey += 1
        },

        // 카드 불러오기
        async load(){
            // 영어카드 불러오기
            await this.$http.post('/api/card/detail/card_en', {
                params: {
                    id: this.$route.query.id
                }
            }).then(res => {
                console.log(res)
                this.card.en = JSON.parse(res.data[0].dump)

                this.card.en.series = res.data[0].series
                this.card.en.isAvailable = res.data[0].isAvailable

                this.card.en.set_name = this.card.en.set.name
                this.card.en.set_printedTotal = this.card.en.set.printedTotal
                this.card.en.image = res.data[0].image

                // 선입력카드의 경우
                if(this.card.en.images==null){
                    this.isPreCard = true

                    this.$http.post('/api/card/detail/import/pre_card', {
                        params: {
                            id : res.data[0].id
                        }
                    }).then((res) => {
                        Object.assign(this.cardImport, res.data[0])
                        this.cardImport.set.name = this.cardImport.set_name
                        this.cardImport.set.printedTotal = this.cardImport.set_printedTotal
                    })
                }
            })

            // 한글카드 불러오기
            await this.$http.post('/api/card/detail/card_kr', {
                params: {
                    id: this.$route.query.id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.kr=res.data[0]
                    if(
                        this.card.kr.name != null && this.card.kr.name != ""
                        && this.card.kr.rarity != null && this.card.kr.rarity != ""
                    )
                    {
                        this.text_nation_in_info = 'kr'
                    }
                }
            })

            // 일본카드 불러오기
            await this.$http.post('/api/card/detail/card_jp', {
                params: {
                    id: this.$route.query.id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.jp = res.data[0]

                    // 한글카드, 영어카드 모두 미발매일 경우
                    if(this.card.en.isAvailable == 0 && this.card.kr.isAvailable == 0){
                        this.text_nation_in_info = 'jp'
                    }

                    if((Object.keys(this.card.en).length==0 || this.card.en.images==null) && Object.keys(this.card.kr).length==0){
                        this.text_nation_in_info = 'jp'
                    }
                }
            })

            console.log("card", this.card)
            this.forceRerenderer()
        }
    }
}
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>