<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            height="380"
        >
            <v-divider></v-divider>
            <!-- TABS 제목 -->
            <v-tabs
                v-model="tabs"
                dense
                fixed-tabs
                color="primary"
                style="border-bottom:1px solid #e0e0e0;"
                class="mb-4"
                height="52"
                hide-slider
            >
                <!-- 개월 수 선택 -->
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        누적
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(월간)</span>
                    </p>
                </v-tab>
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        12개월
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(월간)</span>
                    </p>
                </v-tab>
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        6개월
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(월간)</span>
                    </p>
                </v-tab>
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        3개월
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(주간)</span>
                    </p>
                </v-tab>
                <v-tab>
                    <p class="my-0" style="font-size:14px; line-height:16px;">
                        1개월
                        <br/>
                        <span style="font-size:9px; color:#ADAFCA;">(일간)</span>
                    </p>
                </v-tab>
            </v-tabs>

            <div class="px-2">
                <Chart
                    :card_id="card_id"
                    :text_nation="text_nation"
                    :month="month"
                    :height="232"
                    @list="computeAVG"
                />
            </div>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
        >
            <v-divider></v-divider>
            <!-- TABS 제목 -->
            <v-tabs
                v-model="tabs"
                dense
                color="primary"
                style="border-bottom:1px solid #e0e0e0;"
                class="mb-4"
                height="40"
                hide-slider
                mobile-breakpoint="0"
            >
                <!-- 개월 수 선택 -->
                <v-tab class="px-0" style="width:20%">
                    <p class="my-0" style="font-size:10px; line-height:12px;">
                        누적
                        <br/>
                        <span style="font-size:8px; color:#ADAFCA;">(월간)</span>
                    </p>
                </v-tab>
                <v-tab class="px-0" style="width:20%">
                    <p class="my-0" style="font-size:10px; line-height:12px;">
                        12개월
                        <br/>
                        <span style="font-size:8px; color:#ADAFCA;">(월간)</span>
                    </p>
                </v-tab>
                <v-tab class="px-0" style="width:20%">
                    <p class="my-0" style="font-size:10px; line-height:12px;">
                        6개월
                        <br/>
                        <span style="font-size:8px; color:#ADAFCA;">(월간)</span>
                    </p>
                </v-tab>
                <v-tab class="px-0" style="width:20%">
                    <p class="my-0" style="font-size:10px; line-height:12px;">
                        3개월
                        <br/>
                        <span style="font-size:8px; color:#ADAFCA;">(주간)</span>
                    </p>
                </v-tab>
                <v-tab class="px-0" style="width:20%">
                    <p class="my-0" style="font-size:10px; line-height:12px;">
                        1개월
                        <br/>
                        <span style="font-size:8px; color:#ADAFCA;">(일간)</span>
                    </p>
                </v-tab>
            </v-tabs>
            <Chart 
                :card_id="card_id"
                :text_nation="text_nation"
                :month="month"
                :height="409"
                @list="computeAVG"
            />
        </v-sheet>
    </div>
</template>
<script>
import Chart from './Chart/Index3'

export default {
    components: {
        Chart
    },

    props: [
        "card_id",
        "text_nation",
    ],

    data: () => ({
        tabs: null,

        avg: {
            S: 0,
            A: 0,
            B: 0,
            C_below: 0,
            PSG: 0
        },

        month: 1,
    }),
    
    watch: {
        tabs(){
            if(this.tabs == 0){
                this.month = 120
            } 
            else if(this.tabs == 1){
                this.month = 12
            }
            else if(this.tabs == 2){
                this.month = 6
            } 
            else if(this.tabs == 3){
                this.month = 3
            }
            else if(this.tabs == 4){
                this.month = 1
            } 
        }
    },

    methods: {
        // 평균가 계산하기
        computeAVG(list){
            this.avg.S = this.$toComma(Math.round((list.S.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.S.filter(num => num !=0 ).length) || 0))
            this.avg.A = this.$toComma(Math.round((list.A.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.A.filter(num => num !=0 ).length) || 0))
            this.avg.B = this.$toComma(Math.round((list.B.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.B.filter(num => num !=0 ).length) || 0))
            this.avg.C_below = this.$toComma(Math.round((list.C_below.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.C_below.filter(num => num !=0 ).length) || 0))

            this.avg.PSA_100 = this.$toComma(Math.round((list.PSA_100.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_100.filter(num => num !=0 ).length) || 0))
            // this.avg.PSA_95 = this.$toComma(Math.round((list.PSA_95.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_95.filter(num => num !=0 ).length) || 0))
            this.avg.PSA_90 = this.$toComma(Math.round((list.PSA_90.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_90.filter(num => num !=0 ).length) || 0))
            // this.avg.PSA_85 = this.$toComma(Math.round((list.PSA_85.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_85.filter(num => num !=0 ).length) || 0))
            this.avg.PSA_80 = this.$toComma(Math.round((list.PSA_80.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_80.filter(num => num !=0 ).length) || 0))
            // this.avg.PSA_75 = this.$toComma(Math.round((list.PSA_75.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_75.filter(num => num !=0 ).length) || 0))
            this.avg.PSA_70 = this.$toComma(Math.round((list.PSA_70.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_70.filter(num => num !=0 ).length) || 0))
            // this.avg.PSA_65 = this.$toComma(Math.round((list.PSA_65.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_65.filter(num => num !=0 ).length) || 0))
            this.avg.PSA_60 = this.$toComma(Math.round((list.PSA_60.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_60.filter(num => num !=0 ).length) || 0))
            // this.avg.PSA_55 = this.$toComma(Math.round((list.PSA_55.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_55.filter(num => num !=0 ).length) || 0))
            this.avg.PSA_50 = this.$toComma(Math.round((list.PSA_50.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_50.filter(num => num !=0 ).length) || 0))
            // this.avg.PSA_45 = this.$toComma(Math.round((list.PSA_45.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_45.filter(num => num !=0 ).length) || 0))
            this.avg.PSA_40 = this.$toComma(Math.round((list.PSA_40.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_40.filter(num => num !=0 ).length) || 0))
            // this.avg.PSA_35 = this.$toComma(Math.round((list.PSA_35.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_35.filter(num => num !=0 ).length) || 0))
            this.avg.PSA_30 = this.$toComma(Math.round((list.PSA_30.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_30.filter(num => num !=0 ).length) || 0))
            // this.avg.PSA_25 = this.$toComma(Math.round((list.PSA_25.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_25.filter(num => num !=0 ).length) || 0))
            this.avg.PSA_20 = this.$toComma(Math.round((list.PSA_20.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_20.filter(num => num !=0 ).length) || 0))
            // this.avg.PSA_15 = this.$toComma(Math.round((list.PSA_15.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_15.filter(num => num !=0 ).length) || 0))
            this.avg.PSA_10 = this.$toComma(Math.round((list.PSA_10.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.PSA_10.filter(num => num !=0 ).length) || 0))

            this.avg.ETC_100 = this.$toComma(Math.round((list.ETC_100.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_100.filter(num => num !=0 ).length) || 0))
            // this.avg.ETC_95 = this.$toComma(Math.round((list.ETC_95.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_95.filter(num => num !=0 ).length) || 0))
            this.avg.ETC_90 = this.$toComma(Math.round((list.ETC_90.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_90.filter(num => num !=0 ).length) || 0))
            // this.avg.ETC_85 = this.$toComma(Math.round((list.ETC_85.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_85.filter(num => num !=0 ).length) || 0))
            this.avg.ETC_80 = this.$toComma(Math.round((list.ETC_80.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_80.filter(num => num !=0 ).length) || 0))
            // this.avg.ETC_75 = this.$toComma(Math.round((list.ETC_75.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_75.filter(num => num !=0 ).length) || 0))
            this.avg.ETC_70 = this.$toComma(Math.round((list.ETC_70.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_70.filter(num => num !=0 ).length) || 0))
            // this.avg.ETC_65 = this.$toComma(Math.round((list.ETC_65.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_65.filter(num => num !=0 ).length) || 0))
            this.avg.ETC_60 = this.$toComma(Math.round((list.ETC_60.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_60.filter(num => num !=0 ).length) || 0))
            // this.avg.ETC_55 = this.$toComma(Math.round((list.ETC_55.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_55.filter(num => num !=0 ).length) || 0))
            this.avg.ETC_50 = this.$toComma(Math.round((list.ETC_50.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_50.filter(num => num !=0 ).length) || 0))
            // this.avg.ETC_45 = this.$toComma(Math.round((list.ETC_45.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_45.filter(num => num !=0 ).length) || 0))
            this.avg.ETC_40 = this.$toComma(Math.round((list.ETC_40.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_40.filter(num => num !=0 ).length) || 0))
            // this.avg.ETC_35 = this.$toComma(Math.round((list.ETC_35.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_35.filter(num => num !=0 ).length) || 0))
            this.avg.ETC_30 = this.$toComma(Math.round((list.ETC_30.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_30.filter(num => num !=0 ).length) || 0))
            // this.avg.ETC_25 = this.$toComma(Math.round((list.ETC_25.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_25.filter(num => num !=0 ).length) || 0))
            this.avg.ETC_20 = this.$toComma(Math.round((list.ETC_20.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_20.filter(num => num !=0 ).length) || 0))
            // this.avg.ETC_15 = this.$toComma(Math.round((list.ETC_15.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_15.filter(num => num !=0 ).length) || 0))
            this.avg.ETC_10 = this.$toComma(Math.round((list.ETC_10.filter(num => num !=0 ).reduce((a, b) => a + b, 0) / list.ETC_10.filter(num => num !=0 ).length) || 0))

            this.$emit("avgUpdated", this.avg)
        }
    }
}
</script>
<style scoped>
::v-deep .v-tab {
    min-width: 0 !important;
}
</style>