<template>
    <div>
        <!-- 상단 -->
        <div class="d-flex align-center justify-space-between px-4 pt-3 mb-2">
            <!-- 제목 -->
            <p
                class="mb-0"
                style="font-size:16px; font-weight:bold; cursor:pointer;"
                @click="keyword.nation='kr'
                $router.push('/rank')"
            >
                카드랭킹
                <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
            </p>

            <p
                class="mb-0 mt-1 mr-1"
                style="font-size:10px;"
            >
                {{new Date().toLocaleString()}} 기준
            </p>
        </div>

        <!-- 메뉴 -->
        <div
            class="px-4 rounded-15 d-flex justify-space-between mb-2"
        >
            <!-- 시리즈 -->
            <v-autocomplete
                dense outlined hide-details
                class="rounded-10 mr-1 shrink"
                style="background:white; width:96px;"
                persistent-placeholder
                :items="select_list.nation"
                v-model="keyword.nation"
                @change="keyword.set_name = ''"
            >
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>

            <!-- 평점랭킹 -->
            <v-btn
                depressed
                dark
                class="rounded-10 mr-1"
                style="font-size:12px;"
                :color="rank_type == '평점랭킹'? '#23D2E2':'#ADAFCA'"
                width="74"
                height="40"
                @click="rank_type = '평점랭킹'"
            >
                평점랭킹
            </v-btn>

            <!-- 가격랭킹 -->
            <v-btn
                depressed
                dark
                class="rounded-10 mr-1"
                style="font-size:12px;"
                :color="rank_type == '가격랭킹'? '#23D2E2':'#ADAFCA'"
                width="74"
                height="40"
                @click="rank_type = '가격랭킹'"
            >
                가격랭킹
            </v-btn>

            <!-- 사용랭킹 -->
            <v-btn
                depressed
                dark
                class="rounded-10"
                style="font-size:12px;"
                :color="rank_type == '사용랭킹'? '#23D2E2':'#ADAFCA'"
                width="74"
                height="40"
                @click="rank_type = '사용랭킹'"
            >
                사용랭킹
            </v-btn>
        </div>

        <!-- 검색조건 -->
        <v-sheet
            class="d-flex align-center px-4"
            height="54"
        >
            <!-- 시리즈 -->
            <v-autocomplete
                dense outlined hide-details
                class="rounded-10 mr-2"
                label="시리즈"
                placeholder="전체"
                persistent-placeholder
                :items="series_list"
                v-model="keyword.series"
                @change="keyword.set_name = ''"
            >
                <template v-slot:append>
                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>

            <!-- 제품명 -->
            <v-autocomplete
                dense outlined hide-details
                class="rounded-10"
                label="제품명"
                placeholder="전체"
                persistent-placeholder
                :items="set_list"
                v-model="keyword.set_name"
                @change="keyword.series = ''"
            >
                <template v-slot:append>
                    <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>
        </v-sheet>

        <!-- 목록 -->
        <v-sheet
            v-if="list.length"
            class="d-flex pt-3 pb-2 px-4"
            color="transparent"
            style="font-size:12px; font-weight:bold;"
        >
            <p v-if="rank_type == '평점랭킹'" style="width:33%; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_1'? 'primary--text':''" @click="type = 'align_1'; align_1 = !align_1">
                종합평점
                <v-icon v-if="align_1" size="20" class="pb-1" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_1" size="20" class="pb-1" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '평점랭킹'" style="width:33%; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_2'? 'primary--text':''" @click="type = 'align_2'; align_2 = !align_2">
                성능평점
                <v-icon v-if="align_2" size="20" class="pb-1" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_2" size="20" class="pb-1" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '평점랭킹'" style="width:33%; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_3'? 'primary--text':''" @click="type = 'align_3'; align_3 = !align_3">
                수집평점
                <v-icon v-if="align_3" size="20" class="pb-1" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_3" size="20" class="pb-1" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>


            <p v-if="rank_type == '가격랭킹'" style="width:33%; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_1'? 'primary--text':''" @click="type = 'align_1'; align_1 = !align_1">
                평균가격
                <v-icon v-if="align_1" size="20" class="pb-1" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_1" size="20" class="pb-1" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '가격랭킹'" style="width:33%; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_2'? 'primary--text':''" @click="type = 'align_2'; align_2 = !align_2">
                최고가격
                <v-icon v-if="align_2" size="20" class="pb-1" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_2" size="20" class="pb-1" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '가격랭킹'" style="width:33%; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_3'? 'primary--text':''" @click="type = 'align_3'; align_3 = !align_3">
                거래량
                <v-icon v-if="align_3" size="20" class="pb-1" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_3" size="20" class="pb-1" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>


            <p v-if="rank_type == '사용랭킹'" style="width:33%; text-align:center; cursor:pointer;" class="pl-8 mb-0" :class="type == 'align_1'? 'primary--text':''" @click="type = 'align_1'; align_1 = !align_1">
                덱 채용건
                <v-icon v-if="align_1" size="20" class="pb-1" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_1" size="20" class="pb-1" :class="type == 'align_1'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '사용랭킹'" style="width:33%; text-align:center; cursor:pointer;" class="pl-4 mb-0" :class="type == 'align_2'? 'primary--text':''" @click="type = 'align_2'; align_2 = !align_2">
                덱 채용수
                <v-icon v-if="align_2" size="20" class="pb-1" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_2" size="20" class="pb-1" :class="type == 'align_2'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
            <p v-if="rank_type == '사용랭킹'" style="width:33%; text-align:center; cursor:pointer;" class="pl-4 pr-3 mb-0" :class="type == 'align_3'? 'primary--text':''" @click="type = 'align_3'; align_3 = !align_3">
                수집
                <v-icon v-if="align_3" size="20" class="pb-1" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-down</v-icon>
                <v-icon v-if="!align_3" size="20" class="pb-1" :class="type == 'align_3'? 'primary--text':''">mdi-chevron-up</v-icon>
            </p>
        </v-sheet>

        <v-sheet
            v-if="currentPageList.length"
            class="pt-2 px-4"
        >
            <v-row
                no-gutters
            >
                <v-col cols="12" v-for="(item, index) in currentPageList" :key="item.id" style="border-bottom:1px solid #e0e0e0;">
                    <v-sheet class="py-2 pr-4 d-flex align-center rounded-15">
                        <v-menu
                            offset-x
                            right
                            open-on-hover
                            nudge-right="0"
                            max-width="320"
                            content-class="rounded-15"
                            :close-on-content-click="true"
                        >
                            <template v-slot:activator="{ on: menu }">
                                <v-sheet
                                    v-on="{ ...menu }"
                                    class="d-flex align-center px-4"
                                >
                                    <v-badge
                                        class="custom-badge"
                                        :offset-x="44"
                                        :offset-y="16"
                                        :content="index+10*(page-1)+1"
                                        width="18"
                                        height="18"
                                        style="font-size:10px; font-weight:bold; color:white; text-align:center;"
                                        color="icu_purple"
                                    >
                                        <v-img
                                            width="36"
                                            height="52"
                                            class="grey lighten-2"
                                            :src="showImage(item)"
                                        ></v-img>
                                    </v-badge>

                                    <v-sheet class="ml-2 mb-2px">
                                        <!-- 카드이름 -->
                                        <div>
                                            <p
                                                style="font-size:10px; font-weight:600; width:130px;"
                                                class="mb-0 pb-2px mt-1 ml-1 text-truncate"
                                            >
                                                {{item.name}}
                                            </p>
                                        </div>

                                        <!-- 제품명 -->
                                        <div class="ml-5px">
                                            <p
                                                style="font-size:9px; font-weight:300; width:130px;"
                                                class="mb-0 text-truncate"
                                            >
                                                {{item.set_name}}
                                            </p>
                                        </div>
                                        
                                        <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                        <div
                                            class="d-flex ml-1 text-truncate"
                                            style="width:130px;"
                                        >
                                            <!-- 레귤레이션 -->
                                            <p
                                                v-if="item.regulationMark"
                                                style="font-size:9px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                <v-icon size="12" class="pb-1 mr-1">
                                                    mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                                </v-icon>
                                            </p>

                                            <!-- 넘버, 토탈넘버 -->
                                            <div class="mr-2px">
                                                <p
                                                    style="font-size:9px; font-weight:300;"
                                                    class="mb-0"
                                                >
                                                    {{item.number}} / {{item.set_printedTotal}}
                                                </p>
                                            </div>

                                            <!-- 희귀도 -->
                                            <div>
                                                <p
                                                    style="font-size:9px; font-weight:300;"
                                                    class="mb-0"
                                                >
                                                    {{item.rarity}}
                                                </p>
                                            </div>
                                        </div>
                                    </v-sheet>
                                </v-sheet>
                            </template>
                            <Detail
                                :id="item.id"
                                :isBoardContent="true"
                                :nation="keyword.nation"
                            />
                        </v-menu>

                        <v-spacer></v-spacer>

                        <!-- 평점랭킹 -->
                        <div
                            v-if="rank_type == '평점랭킹'"
                        >
                            <v-sheet
                                class="d-flex align-center font-weight-bold"
                                style="margin-bottom:-12px;"
                            >
                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light mr-1"
                                    style="font-size:8px; margin-bottom:-3px !important;"
                                >
                                    종합평점
                                </p>

                                <!-- 평점(별) -->
                                <v-rating
                                    :value="item.total_avg"
                                    half-increments
                                    background-color="grey"
                                    color="#615DFA"
                                    size="10"
                                    style="height:26px;"
                                    density="compact"
                                    readonly
                                ></v-rating>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light"
                                    style="font-size:8px; margin-bottom:-3px !important;"
                                >
                                    평점 {{item.total_avg?.toFixed(0)}}
                                </p>
                            </v-sheet>
                            <v-sheet
                                class="d-flex align-center font-weight-bold"
                                style="margin-bottom:-12px;"
                            >
                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light mr-1"
                                    style="font-size:8px; margin-bottom:-3px !important;"
                                >
                                    성능평점
                                </p>

                                <!-- 평점(별) -->
                                <v-rating
                                    :value="item.perform_avg"
                                    half-increments
                                    background-color="grey"
                                    color="#615DFA"
                                    size="10"
                                    style="height:26px;"
                                    density="compact"
                                    readonly
                                ></v-rating>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light"
                                    style="font-size:8px; margin-bottom:-3px !important;"
                                >
                                    평점 {{item.perform_avg?.toFixed(0)}}
                                </p>
                            </v-sheet>
                            <v-sheet
                                class="d-flex align-center font-weight-bold mb-1"
                            >
                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light mr-1"
                                    style="font-size:8px; margin-bottom:-3px !important;"
                                >
                                    수집평점
                                </p>

                                <!-- 평점(별) -->
                                <v-rating
                                    :value="item.collect_avg"
                                    half-increments
                                    background-color="grey"
                                    color="#615DFA"
                                    size="10"
                                    style="height:26px;"
                                    density="compact"
                                    readonly
                                ></v-rating>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light"
                                    style="font-size:8px; margin-bottom:-3px !important;"
                                >
                                    평점 {{item.collect_avg?.toFixed(0)}}
                                </p>
                            </v-sheet>
                        </div>

                        <!-- 가격랭킹 -->
                        <div
                            v-if="rank_type == '가격랭킹'"
                            class="mt-1"
                        >
                            <v-sheet
                                class="d-flex align-center font-weight-bold"
                            >
                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light mr-1"
                                    style="font-size:8px;"
                                >
                                    평균가
                                </p>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-bold"
                                    style="font-size:8px; color:#615DFA"
                                >
                                    {{$toComma(Math.floor(item.avg_price))}}원
                                </p>
                            </v-sheet>
                            <v-sheet
                                class="d-flex align-center font-weight-bold"
                            >
                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light mr-1"
                                    style="font-size:8px;"
                                >
                                    최고가
                                </p>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-bold"
                                    style="font-size:8px; color:#F8468D;"
                                >
                                    {{$toComma(Math.floor(item.max_price))}}원
                                </p>
                            </v-sheet>
                            <v-sheet
                                class="d-flex align-center font-weight-bold mb-1"
                            >
                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light mr-1"
                                    style="font-size:8px;"
                                >
                                    거래량
                                </p>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-bold"
                                    style="font-size:8px; color:#1DA1F2;"
                                >
                                    {{item.count}}건
                                </p>
                            </v-sheet>
                        </div>

                        <!-- 사용랭킹 -->
                        <div
                            v-if="rank_type == '사용랭킹'"
                            class="mt-1"
                        >
                            <v-sheet
                                class="d-flex align-center font-weight-bold"
                            >
                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light mr-1"
                                    style="font-size:8px;"
                                >
                                    덱 채용건
                                </p>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-bold"
                                    style="font-size:8px; color:#615DFA"
                                >
                                    {{item.deck_total_count}}건
                                </p>
                            </v-sheet>
                            <v-sheet
                                class="d-flex align-center font-weight-bold"
                            >
                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light mr-1"
                                    style="font-size:8px;"
                                >
                                    덱 채용수
                                </p>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-bold"
                                    style="font-size:8px; color:#F8468D;"
                                >
                                    {{item.deck_total_amount}}장
                                </p>
                            </v-sheet>
                            <v-sheet
                                class="d-flex align-center font-weight-bold mb-1"
                            >
                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-light mr-1"
                                    style="font-size:8px;"
                                >
                                    수집
                                </p>

                                <!-- 평점(숫자) -->
                                <p
                                    class="my-0 text-truncate ml-2px font-weight-bold"
                                    style="font-size:8px; color:#1DA1F2;"
                                >
                                    {{item.collection_total_amount}}장
                                </p>
                            </v-sheet>
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-sheet>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 248px; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="80"
        ></v-progress-circular>

        <v-sheet
            v-if="!list.length && !loading"
            class="mt-2 text-center py-16 rounded-15"
        >
            <v-icon size="60" color="#424361" class="mb-3">mdi-close-circle-outline</v-icon>
            <p style="color:#424361; font-size:19px; font-weight:bold;">해당 조건에 맞는 데이터가 없습니다</p>
        </v-sheet>

        <v-sheet
            v-if="!list.length && loading"
            style="height:340px;"
        ></v-sheet>

        <BannerView
            v-if="$store.state.ads_banner.card_ranking"
            class="mt-3"
            type="card_ranking"
            width="100%"
            :height="46/360*100+'vw'"
            :round="'rounded-0'"
        />
    </div>
</template>
<script>
import Detail from '@/components/card/detailForBoard/Detail'
import BannerView from '@/components/BannerView'

export default {
    components: {
        Detail,
        BannerView
    },

    data: () => ({
        select_list: {
            set: {
                en: [],
                kr: [],
                jp: []
            },

            series: {
                en: [],
                kr: [],
                jp: []
            },

            nation: [
                {text: "국내판", value: "kr" },
                {text: "북미판", value: "en" },
                {text: "일본판", value: "jp" }
            ]
        },

        // 키워드
        keyword: {
            nation: "kr",
            series: "",
            set_name: "",
        },

        // 랭킹
        rank_type: "평점랭킹",
        align_1: true,
        align_2: false,
        align_3: false,
        type: "align_1",

        // 목록
        list: [],
        list_ready:false,

        loading: false
    }),

    watch: {
        keyword: {
            handler: function (val, oldVal) {
                this.search()
            },
            deep: true
        },

        rank_type(){
            this.search()
        },

        align_1(){
            this.search()
        },

        align_2(){
            this.search()
        },
        
        align_3(){
            this.search()
        },
    },

    computed: {
        // 제품명
        set_list(){
            switch(this.keyword.nation){
                case 'kr' : return this.select_list.set.kr
                case 'en' : return this.select_list.set.en
                case 'jp' : return this.select_list.set.jp
            }
        },

        // 시리즈
        series_list(){
            switch(this.keyword.nation){
                case 'kr' : return this.select_list.series.kr
                case 'en' : return this.select_list.series.en
                case 'jp' : return this.select_list.series.jp
            }
        }
    },

    mounted(){
        this.loading = true

        // 제품명 목록 불러오기
        this.loadSetsList()

        // 시리즈 목록 불러오기
        this.loadSeriesList()

        this.search()
    },

    methods: {
        showImage(item){
            if(item.image_kr != "default.png")
            {
                return '/upload/card_kr_image/' + item.image_kr
            }
            else if(item.image_en != "default.png")
            {
                return '/upload/card_en_image/' + item.image_en
            }
            else if(item.image_jp != "default.png")
            {
                return '/upload/card_jp_image/' + item.image_jp
            }
            else {
                return '/upload/card_kr_image/default.png'
            }
        },

        sortList(){
            if(this.rank_type == "평점랭킹")
            {
                if(this.type == "align_1")
                {
                    if(this.align_1)
                    {
                        this.list = this.list.sort((a, b) => b.total_avg - a.total_avg)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.total_avg - b.total_avg)
                    }
                }
                else if(this.type == "align_2")
                {
                    if(this.align_2)
                    {
                        this.list = this.list.sort((a, b) => b.perform_avg - a.perform_avg)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.perform_avg - b.perform_avg)
                    }
                }
                else if(this.type == "align_3")
                {
                    if(this.align_3)
                    {
                        this.list = this.list.sort((a, b) => b.collect_avg - a.collect_avg)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.collect_avg - b.collect_avg)
                    }
                }
            }

            if(this.rank_type == "가격랭킹")
            {
                if(this.type == "align_1")
                {
                    if(this.align_1)
                    {
                        this.list = this.list.sort((a, b) => b.avg_price - a.avg_price)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.avg_price - b.avg_price)
                    }
                }
                else if(this.type == "align_2")
                {
                    if(this.align_2)
                    {
                        this.list = this.list.sort((a, b) => b.max_price - a.max_price)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.max_price - b.max_price)
                    }
                }
                else if(this.type == "align_3")
                {
                    if(this.align_3)
                    {
                        this.list = this.list.sort((a, b) => b.count - a.count)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.count - b.count)
                    }
                }
            }

            if(this.rank_type == "사용랭킹")
            {
                if(this.type == "align_1")
                {
                    if(this.align_1)
                    {
                        this.list = this.list.sort((a, b) => b.deck_total_count - a.deck_total_count)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.deck_total_count - b.deck_total_count)
                    }
                }
                else if(this.type == "align_2")
                {
                    if(this.align_2)
                    {
                        this.list = this.list.sort((a, b) => b.deck_total_amount - a.deck_total_amount)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.deck_total_amount - b.deck_total_amount)
                    }
                }
                else if(this.type == "align_3")
                {
                    if(this.align_3)
                    {
                        this.list = this.list.sort((a, b) => b.collection_total_amount - a.collection_total_amount)
                    }
                    else
                    {
                        this.list = this.list.sort((a, b) => a.collection_total_amount - b.collection_total_amount)
                    }
                }
            }

            this.currentPageList = this.list.slice(0,100)
            this.page = 1
        },

        // 제품명 목록 불러오기
        loadSetsList(){
            // 한글
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })

            // 영어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "jp"
                }
            }).then(res => { 
                this.select_list.set.jp = res.data.map(a => a.name)
            })
        },

        // 시리즈 목록 불러오기
        loadSeriesList(){
            // 한글
            this.$http.post('/api/card/select/series/kr')
            .then(res => { 
                this.select_list.series.kr = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 영어
            this.$http.post('/api/card/select/series/en')
            .then(res => { 
                this.select_list.series.en = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 일어
            this.$http.post('/api/card/select/series/jp')
            .then(res => { 
                this.select_list.series.jp = res.data.map(a => a.series).filter(e => e!=null)
            })
        },

        async search(){
            this.loading = true
            if(this.rank_type == "평점랭킹")
            {
                await this.$http.post('/api/rank/rating/'+this.keyword.nation, {
                    params: {
                        keyword: this.keyword,
                    }
                }).then(async res => {
                    console.log(res)
                    this.list = res.data
                    await this.sortList()
                    this.loading = false
                })
            }

            else if(this.rank_type == "가격랭킹")
            {
                await this.$http.post('/api/rank/trade/'+this.keyword.nation, {
                    params: {
                        keyword: this.keyword,
                    }
                }).then(async res => {
                    console.log(res)
                    this.list = res.data
                    await this.sortList()
                    this.loading = false
                })
            }

            else if(this.rank_type == "사용랭킹")
            {
                await this.$http.post('/api/rank/usage/'+this.keyword.nation, {
                    params: {
                        keyword: this.keyword,
                    }
                }).then(async res => {
                    console.log(res)
                    this.list = res.data
                    await this.sortList()
                    this.loading = false
                })
            }
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}

/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:12px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:0px;
    font-size: 12px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:0px;
    font-size: 12px;
}

/* 페이징 */
::v-deep .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}


.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}

/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

::v-deep .v-input__icon--clear {
    padding-top:14px;
    padding-right:4px;
}
</style>