<template>
    <div>
        <!-- ID 복사 -->
        <div style="position:absolute; z-index:2; top: 24px; left:31px;" v-if="$store.state.type == '관리자' && $route.path.startsWith('/card/detail')">
            <v-btn
                height="42"
                dark
                depressed
                color="primary"
                class="rounded-10"
                @click="createItem()"
            >
                <v-icon>mdi-currency-usd</v-icon>
            </v-btn>
        </div>

        <!-- dialog -->
        <v-sheet>
            <!-- 추가하기 -->
            <v-dialog
                v-model="dialog.create"
                width="1360"
                content-class="rounded-10"
            >
                <v-sheet class="pa-4">
                    <!-- 제목 -->
                    <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                        추가하기
                    </p>

                    <!-- 입력 -->
                    <v-sheet class="mt-2 d-flex" v-for="(item, index) in card_list" :key="index">
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="item.card_view_name"
                                class="title_field"
                                hide-details
                                dense
                                placeholder="카드 선택"
                                solo
                                flat
                                readonly
                                @click="searchCard(index)"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-select
                                v-model="item.card_state"
                                hide-details
                                dense
                                solo
                                flat
                                placeholder="카드상태"
                                :items="select_list.card_state"
                            ></v-select>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="item.card_price"
                                class="title_field"
                                hide-details
                                dense
                                placeholder="가격"
                                solo
                                flat
                                type="number"
                                reverse
                                prefix="원"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="item.card_created"
                                hide-details dense solo flat
                                placeholder="등록날짜"
                                @click="open_created_select(item)"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="item.card_sold"
                                hide-details dense solo flat
                                placeholder="판매날짜"
                                @click="open_sold_select(item)"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-select
                                v-model="item.state"
                                hide-details dense solo flat
                                placeholder="등록됨 or 판매완료"
                                :items="select_list.state"
                                @change="state_changed(item)"
                            ></v-select>
                        </v-sheet>
                        <v-sheet class="mr-2">
                            <v-btn 
                                height="48"
                                block
                                color="blue"
                                dark
                                depressed
                                @click="searchCard(index)"
                            >
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-sheet>
                        <v-sheet>
                            <v-btn
                                v-if="!index"
                                height="48"
                                block
                                color="blue"
                                dark
                                depressed
                                @click="addField()"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="index"
                                height="48"
                                block
                                color="blue"
                                dark
                                depressed
                                @click="subtractField(index)"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            class="px-6 mr-4"
                            color="blue"
                            large
                            dark
                            depressed
                            @click="createSubmit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            추가하기
                        </v-btn>
                        <v-btn
                            class="px-6"
                            color="grey"
                            large
                            dark
                            depressed
                            @click="dialog.create = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-dialog>

            <!-- 등록날짜 선택 -->
            <v-dialog v-model="dialog.createdSelect" width="auto">
                <v-date-picker
                    locale="kr"
                    v-model="created_select"
                    @input="update_created()"
                ></v-date-picker>
            </v-dialog>

            <!-- 판매날짜 선택 -->
            <v-dialog v-model="dialog.soldSelect" width="auto">
                <v-date-picker
                    locale="kr"
                    v-model="sold_select"
                    @input="update_sold()"
                ></v-date-picker>
            </v-dialog>

            <!-- 추가하기 > 카드선택 -->
            <v-dialog
                v-model="dialog.cardSelect"
                width="1040"
            >
                <v-card class="pa-1">
                    <v-tabs
                        v-model="cardTab"
                        grow
                        text
                        color="blue"
                    >
                        <v-tab href="#CardList">
                            카드 선택
                        </v-tab>
                        <v-tab href="#CardDetail">
                            카드 상세페이지
                        </v-tab>
                    </v-tabs>
                    <v-divider></v-divider>
                    <v-tabs-items v-model="cardTab">
                        <v-tab-item value="CardList">
                            <CardList :isWrite="true" @goToDetail="goToDetail" />
                        </v-tab-item>
                        <v-tab-item value="CardDetail" class="pt-4">
                            <CardDetail
                                :id="cardDetail_id"
                                :isBoardContent="false"
                                :key="componentKey"
                                @CardSelected="CardSelected"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-dialog>
        </v-sheet>
    </div>
</template>
<script>
import CardList from '@/components/card/List'
import CardDetail from '@/components/card/detailForTrade/Detail'

export default {
    components: {
        CardList,
        CardDetail
    },

    data: () => ({
        list: [],

        // 선택목록
        select_list: {
            card_state: ['기타', 'S급', 'A급', 'B급', 'C급 이하', '등급'],
            state: ['등록됨', '판매완료'],
        },

        // 추가하기 카드목록
        card_list: [
            {
                card_view_name: "",
                card_id: "",
                card_nation: "",
                card_name: "",
                card_state: "",
                card_price: 0,
                card_created: "",
                card_sold: "",
                state: ""
            }
        ],

        cardTab: null,
        cardDetail_id:"",
        cardImage: "",
        selectedIndex: 0,

        // 다이얼로그
        dialog: {
            create:false,
            cardSelect:false,
            createdSelect:false,
            soldSelect:false,
        },

        // 선택
        selected: false,
        selectedItem: {},

        // 날짜선택
        created_select: "",
        sold_select: "",

        updateSelected: [],
        componentKey: 0
    }),

    methods: {
        // 추가하기 Dialog 열기
        createItem(){
            this.dialog.create = true
        },

        // 추가하기 > 등록날짜 Dialog 열기
        open_created_select(item){
            this.selectedItem = item
            this.dialog.createdSelect = true
        },

        // 추가하기 > 판매날짜 Dialog 열기
        open_sold_select(item){
            this.selectedItem = item
            this.dialog.soldSelect = true
        },

        // 추가하기 > 등록날짜 설정 (판매날짜 초기화)
        update_created(){
            this.selectedItem.card_created = this.created_select
            this.selectedItem.card_sold = null
            this.selectedItem.state = "등록됨"
            this.dialog.createdSelect = false
        },

        // 추가하기 > 판매날짜 설정 (등록날짜 초기화)
        update_sold(){
            this.selectedItem.card_sold = this.sold_select
            this.selectedItem.card_created = null
            this.selectedItem.state = "판매완료"
            this.dialog.soldSelect = false
        },

        // 추가하기 > 상태변경시 등록날짜 & 판매날짜 초기화
        state_changed(item){
            if(item.state=='등록됨'){
                item.card_sold = null
            }else if(item.state=='판매완료'){
                item.card_created = null
            }
        },

        // 추가하기 > 카드선택창 열기
        searchCard(index){
            this.selectedIndex = index
            this.dialog.cardSelect = true
        },

        // 추가하기 > 카드선택창 > 카드목록에서 카드 선택
        goToDetail(id){
            this.cardDetail_id = id
            this.cardTab="CardDetail"
            this.componentKey++
        },

        // 추가하기 > 카드선택창 > 카드목록 Computed
        CardSelected(card, nation){
            if(nation=='kr'){
                this.card_list[this.selectedIndex].card_view_name = card.kr.name + " (" + card.kr.id+")"
                this.card_list[this.selectedIndex].card_name = card.kr.name
            }else if(nation=='en'){
                this.card_list[this.selectedIndex].card_view_name = card.en.name + " (" + card.en.id+")"
                this.card_list[this.selectedIndex].card_name = card.en.name
            }else if(nation=='jp'){
                this.card_list[this.selectedIndex].card_view_name = card.jp.name + " (" + card.jp.id+")"
                this.card_list[this.selectedIndex].card_name = card.jp.name
            }

            this.card_list[this.selectedIndex].card_id = card.en.id
            this.card_list[this.selectedIndex].card_nation = nation
            this.dialog.cardSelect = false
        },

        // 추가하기 > 카드선택창 > 카드 추가
        addField(){
            this.card_list.push(
                {
                    card_view_name: "",
                    card_id: "",
                    card_nation: "",
                    card_name: "",
                    card_state: "",
                    card_price: 0,
                    state: ""
                }
            )
        },

        // 추가하기 > 카드선택창 > 카드 빼기
        subtractField(index){
            this.card_list.splice(index,1)
        },

        // 추가하기 > Submit
        async createSubmit(){
            for(let i=0; i<this.card_list.length; i++){
                await this.$http.post('/api/admin/trade/price/insert/card', {
                    params: {
                        post_id : 0,
                        card_id: this.card_list[i].card_id,
                        card_nation : this.card_list[i].card_nation,
                        card_name : this.card_list[i].card_name,
                        card_state : this.card_list[i].card_state,
                        card_price : this.card_list[i].card_price,
                        card_created : this.card_list[i].card_created,
                        card_sold : this.card_list[i].card_sold,
                        state : this.card_list[i].state
                    }
                })
            }

            alert("가격정보가 정상적으로 추가되었습니다")
            this.dialog.create = false
        }
    }
}
</script>