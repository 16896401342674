<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="px-16 py-4 rounded-15"
            color="#F8F7FB"
            width="840"
        >
            <!-- 제목 -->
            <p class="pt-4 mb-4 font-weight-bold text-center" style="font-size:24px;">
                정보 입력/수정
            </p>
            
            <!-- 제보 카드 ID -->
            <p class="ml-2 mb-2" style="font-size:16px; font-weight:bold;">
                제보 카드 ID
                <span class="ml-2">{{card_id}}</span>
            </p>

            <!-- 발매국가 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-6 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">발매국가</span>
                <v-select
                    style="max-width:240px; margin-left:160px;"
                    placeholder="발매국가를 선택해주세요"
                    dense
                    hide-details
                    solo
                    flat
                    :items="select_list.card_nation"
                    item-text="name"
                    item-value="value"
                    v-model="card_nation"
                    @change="card_nation_changed()"
                >
                    <template v-slot:selection="{ item }">
                        <v-sheet width="100%" class="text-center font-weight-bold">{{item.name}}</v-sheet>
                    </template>
                    <template v-slot:append>
                        <v-icon color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </v-sheet>


            <!-- 기본 정보 -->
            <p class="ml-2 mb-2" style="font-size:16px; font-weight:bold;">
                기본 정보
            </p>
            <Basic
                :card_id="card_id"
                :card_nation="card_nation"
                type="provide"
                @basicUpdated="basicImport"
                :key="key+'Basic'"
            />

            <!-- 능력 정보 -->
            <p class="ml-2 mb-2 mt-6" style="font-size:16px; font-weight:bold;">
                능력 정보
            </p>
            <Abilities
                :card_id="card_id"
                :card_nation="card_nation"
                @abilitiesUpdated="abilitiesImport"
                :key="key+'Abilities'"
            />

            <!-- 기술 정보 -->
            <p class="ml-2 mb-2 mt-6" style="font-size:16px; font-weight:bold;">
                기술 정보
            </p>
            <Attacks
                :card_id="card_id"
                :card_nation="card_nation"
                @attacksUpdated="attacksImport"
                :key="key+'Attacks'"
            />

            <!-- 제보내용 -->
            <v-sheet class="py-4 px-6 mt-8 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">제보내용</span>
                <v-textarea
                    class="no_under"
                    rows="4"
                    hide-details
                    v-model="content"
                    placeholder="내용을 입력해주세요"
                ></v-textarea>
            </v-sheet>

            <!-- 안내문구 -->
            <p class="text-center font-weight-bold mt-2 primary--text" style="font-size:12px;">
                정보오류를 제보해주시면 소정의 포인트가 지급됩니다.
            </p>

            <!-- 버튼 -->
            <div class="mt-2 pa-2 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="110"
                    height="42"
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    제출
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="110"
                    height="42"
                    depressed
                    color="white"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="pa-2 rounded-15"
            color="#F8F7FB"
        >
            <!-- 제목 -->
            <p class="pt-2 mb-3 font-weight-bold text-center" style="font-size:18px;">
                정보 입력/수정
            </p>
            
            <!-- 제보 카드 ID -->
            <p class="ml-2 mb-2" style="font-size:12px; font-weight:bold;">
                제보 카드 ID
                <span class="ml-2">{{card_id}}</span>
            </p>

            <!-- 발매국가 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-4 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <p style="font-size:12px; font-weight:bold; width:80px; margin-bottom:2px;">발매국가</p>
                <v-select
                    class="ml-5 font-weight-bold"
                    style="font-size:12px;"
                    dense
                    hide-details
                    solo
                    flat
                    :items="select_list.card_nation"
                    item-text="name"
                    item-value="value"
                    v-model="card_nation"
                    @change="card_nation_changed()"
                >
                    <template v-slot:selection="{ item }">
                        <v-sheet width="100%" class="text-center font-weight-bold">{{item.name}}</v-sheet>
                    </template>
                    <template v-slot:append>
                        <v-icon color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </v-sheet>


            <!-- 기본 정보 -->
            <p class="ml-2 mb-2" style="font-size:12px; font-weight:bold;">
                기본 정보
            </p>
            <Basic
                :card_id="card_id"
                :card_nation="card_nation"
                type="provide"
                @basicUpdated="basicImport"
                :key="key+'Basic'"
            />

            <!-- 능력 정보 -->
            <p class="ml-2 mb-2 mt-6" style="font-size:12px; font-weight:bold;">
                능력 정보
            </p>
            <Abilities
                :card_id="card_id"
                :card_nation="card_nation"
                @abilitiesUpdated="abilitiesImport"
                :key="key+'Abilities'"
            />

            <!-- 기술 정보 -->
            <p class="ml-2 mb-2 mt-6" style="font-size:12px; font-weight:bold;">
                기술 정보
            </p>
            <Attacks
                :card_id="card_id"
                :card_nation="card_nation"
                @attacksUpdated="attacksImport"
                :key="key+'Attacks'"
            />

            <!-- 제보내용 -->
            <v-sheet class="py-4 px-6 mt-6 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:12px; font-weight:bold;">제보내용</span>
                <v-textarea
                    class="no_under"
                    style="font-size:12px;"
                    rows="3"
                    hide-details
                    v-model="content"
                    placeholder="내용을 입력해주세요"
                ></v-textarea>
            </v-sheet>

            <!-- 안내문구 -->
            <p class="text-center font-weight-bold mt-2 primary--text" style="font-size:10px;">
                정보오류를 제보해주시면 소정의 포인트가 지급됩니다.
            </p>

            <!-- 버튼 -->
            <div class="pa-2 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="80"
                    height="34"
                    dark
                    depressed
                    color="#615DFA"
                    class="font-weight-bold rounded-10 mr-2"
                    @click="submit()"
                >
                    제출
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="80"
                    height="34"
                    depressed
                    color="white"
                    class="font-weight-bold rounded-10"
                    @click="$emit('close')"
                >
                    취소
                </v-btn>
            </div>
        </v-sheet>
    </div>
</template>
<script>
import Basic from './ModulesForProvide/basic'
import Abilities from './ModulesForProvide/Abilities'
import Attacks from './ModulesForProvide/Attacks'

export default {
    components: {
        Basic,
        Abilities,
        Attacks
    },

    props: [
        "card_id"
    ],

    data: () => ({
        select_list: {
            card_nation: [
                {name: "국내판", value: "kr"},
                {name: "북미판", value: "en"},
                {name: "일본판", value: "jp"}
            ]
        },

        card_nation: "kr",
        content: "",
        key: 0,

        card: {
            ancientTrait_name: "",
            ancientTrait_text: "",
            set_printedTotal: "",
            set_name: ""
        }
    }),

    methods: {
        // 카드 입력값 초기화
        reloadComponents() {
            this.key += 1
        },

        // 기본 정보 $Emit
        basicImport(basic){
            console.log("basic", basic)
            Object.assign(this.card, basic)
            this.$emit("cardUpdated", this.card)
        },

        // 능력 정보 $Emit
        abilitiesImport(abilities){
            console.log("abilities", abilities)
            this.card.abilities = abilities
            this.$emit("cardUpdated", this.card)
        },

        // 기술 정보 $Emit
        attacksImport(attacks){
            console.log("attacks", attacks)
            this.card.attacks = attacks
            this.$emit("cardUpdated", this.card)
        },

        // 카드 및 제보 입력
        async cardInsert(card){
            let card_provide_id = ""

            // 기본 정보 입력
            await this.$http.post('/api/admin/provide/info/insert/card', {
                params: {
                    card_id: card.id,
                    name: card.name,
                    name_local: card.name_local,
                    ancientTrait_name: card.ancientTrait_name,
                    ancientTrait_text: card.ancientTrait_text,
                    effects: card.effects,
                    rules: card.rules,
                    number: card.number,
                    rarity: card.rarity,
                    set_printedTotal: card.set_printedTotal,
                    set_name: card.set_name,
                    writer: this.$store.state.user_id,
                }
            }).then((res) => {
                card_provide_id = res.data.insertId
            })

            // 능력 입력
            if(card.abilities && card.abilities.length){
                for(let ability of card.abilities){
                    this.$http.post('/api/admin/provide/info/insert/card/abilities', {
                        params: {
                            card_provide_id: card_provide_id,
                            card_id: card.id,
                            type: ability.type,
                            name: ability.name,
                            text: ability.text
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                }
            }

            // 기술 입력
            if(card.attacks && card.attacks.length){
                for(let attack of card.attacks){
                    this.$http.post('/api/admin/provide/info/insert/card/attacks', {
                        params: {
                            card_provide_id: card_provide_id,
                            card_id: card.id,
                            name: attack.name,
                            cost: attack.cost.toString(),
                            convertedEnergyCost: attack.cost.length,
                            damage: attack.damage,
                            text: attack.text
                        }
                    }).then((res) => {
                        console.log(res)
                    }) 
                }
            }

            // 제보 입력
            await this.$http.post("/api/admin/provide/info/insert", {
                params: {
                    user_id: this.$store.state.user_id,
                    card_provide_id: card_provide_id,
                    card_nation: this.card_nation,
                    content: this.content
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("정보 입력/수정이 제보되었습니다.")
                }
            })
        },

        // Submit
        async submit(){
            if(this.card_nation == "")
            {
                alert("제보할 카드 정보의 국가를 선택해주세요")
            }
            else if(this.content == "")
            {
                alert("제보 내용을 입력해주세요")
            }
            else
            {
                // 카드 입력
                await this.cardInsert(this.card)

                // 카드 입력값 초기화
                await this.reloadComponents()

                // DB 알림
                this.$dbAlarm(
                    "제보 - 카드",
                    this.$store.state.nickname,
                    this.$store.state.user_id
                )

                // 종료
                await this.$emit("close")
            }
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
    border-style: none !important;
}
</style>