<template>
    <div class="d-flex justify-center">
        <!-- 버튼들 -->
        <UserButtons
            :card="card"
            :isPreCard="isPreCard"
            :cardImport="cardImport"
            @reload="reload"
        />
        
        <!-- 관리자 버튼들 -->
        <AdminButtons
            v-if="!$vuetify.breakpoint.mobile && ($store.state.type=='관리자' || $store.state.type=='운영진' || $store.state.type=='매니저' || $store.state.type=='일어알바')"
            :card="card" 
            :isPreCard="isPreCard" 
            :text_nation="text_nation"
            :cardImport="cardImport" 
            @reload="reload" 
            :key="componentKey"
        />
    </div>
</template>
<script>
import AdminButtons from './CardButtons/AdminButtons'
import UserButtons from './CardButtons/UserButtons'

export default {
    props: ["text_nation"],
    components: {
        AdminButtons,
        UserButtons
    },

    data: () => ({
        card: {
            en: {
                isAvailable: 1
            },
            kr: {},
            jp: {}
        },

        isPreCard: false,
        cardImport: {
            dump: {},
            set: {
                name: "",
                printedTotal: ""
            }
        },

        componentKey: 0
    }),

    watch: {
        text_nation_in_info(){
            this.$emit('text_nation_changed', this.text_nation_in_info)
        },
    },

    mounted(){
        this.load()
    },

    methods: {
        reload(){
            this.$router.go(this.$router.currentRoute)
        },

        forceRerenderer(){
            this.componentKey += 1
        },

        async load(){
            // 영어카드 불러오기
            await this.$http.post('/api/card/detail/card_en', {
                params: {
                    id: this.$route.query.id
                }
            }).then(res => {
                this.card.en = JSON.parse(res.data[0].dump)
                this.card.en.isAvailable = res.data[0].isAvailable
                this.card.en.set_name = this.card.en.set.name
                this.card.en.set_printedTotal = this.card.en.set.printedTotal
                this.card.en.image = res.data[0].image

                // 선입력카드의 경우
                if(this.card.en.images==null){
                    this.isPreCard = true

                    this.$http.post('/api/card/detail/import/pre_card', {
                        params: {
                            id : res.data[0].id
                        }
                    }).then((res) => {
                        Object.assign(this.cardImport, res.data[0])
                        this.cardImport.set.name = this.cardImport.set_name
                        this.cardImport.set.printedTotal = this.cardImport.set_printedTotal
                    })
                }
            })

            // 한글카드 불러오기
            await this.$http.post('/api/card/detail/card_kr', {
                params: {
                    id: this.$route.query.id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.kr=res.data[0]
                    if(
                        this.card.kr.name != null && this.card.kr.name != ""
                        && this.card.kr.rarity != null && this.card.kr.rarity != ""
                    )
                    {
                        this.text_nation_in_info = 'kr'
                    }
                }
            })

            // 일본카드 불러오기
            await this.$http.post('/api/card/detail/card_jp', {
                params: {
                    id: this.$route.query.id
                }
            }).then(res => {
                if(res.data.length){
                    this.card.jp = res.data[0]
                    if((Object.keys(this.card.en).length==0 || this.card.en.images==null) && Object.keys(this.card.kr).length==0){
                        this.text_nation_in_info = 'jp'
                    }
                }
            })

            this.forceRerenderer()
        }
    }
}
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>