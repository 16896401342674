<template>
    <v-sheet class="pa-4" style="z-index:1 !important;">
        <table
            class="table_style mx-auto"
            :style="$vuetify.breakpoint.mobile? '':'340px;'"
        >   
            <!-- 이미지 편집/미리보기 -->       
            <tr>
                <td class="pa-3">
                    <clipper-basic
                        :src="url"
                        ref="clipper"
                        class="my-clipper"
                        :init-width="100"
                        :init-height="100"
                    >
                        <div class="placeholder" slot="placeholder"></div>
                    </clipper-basic>
                </td>
            </tr>

            <!-- 파일 입력 -->
            <tr>
                <td class="py-2 px-3">
                    <v-sheet
                        @drop.prevent="addDropFile"
                        @dragover.prevent
                    >
                        <v-file-input
                            placeholder="카드 이미지를 선택해주세요"
                            outlined
                            dense
                            show-size
                            hide-details
                            prepend-icon
                            color="primary"
                            v-model="uploadImage"
                            @change="onFileChange()"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
                            </template>
                        </v-file-input>
                    </v-sheet>
                </td>
            </tr>

            <!-- 발매 국가 선택 -->
            <tr>
                <td class="py-2 px-3">
                    <v-select
                        dense outlined hide-details
                        placeholder="발매 국가를 선택해주세요."
                        v-model="card_nation"
                        :items="select_list.card_nation"
                        item-text="name"
                        item-value="value"
                    ></v-select>
                </td>
            </tr>

            <!-- 제출 버튼 -->
            <tr>
                <td class="py-2 px-3">
                    <v-btn
                        @click="submit()"
                        depressed
                        dark
                        color="primary"
                        width="100%"
                    >
                        이미지 등록하기
                    </v-btn>
                </td>
            </tr>
        </table>
        
        <!-- 저작권법 안내문구 -->
        <v-sheet
            height="24"
            color="#F8468D"
            class="rounded-lg pt-1 mt-3"
        >
            <p
                class="white--text text-center mb-0"
                style=" letter-spacing:-0.5px;"
                :style="$vuetify.breakpoint.mobile? 'font-size:8px; padding-top:2px;':'font-size:10px;'"
            >
                타인이 촬영한 사진을 무단으로 사용시 저작권법 위반으로 처벌 될 수 있습니다.
            </p>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        select_list: {
            card_nation: [
                { name: "국내판", value: "kr" },
                { name: "북미판", value: "en" },
                { name: "일본판", value: "jp" } 
            ] 
        },
        
        uploadImage: {},
        url: "",
        card_nation: ""
    }),

    methods: {
        // 파일 드래그앤드롭
        addDropFile(e) { 
            const file = e.dataTransfer.files[0]
            this.uploadImage = e.dataTransfer.files[0]
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },
        
        // 파일 선택시 이미지 표시
        onFileChange() {
            const file = this.uploadImage
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },

        // 이미지 업로드
        imageUpload: _.debounce(function() {
            // Crop 이미지 파일로 변환
            const canvas = this.$refs.clipper.clip() //call component's clip method
            var imgDataUrl = canvas.toDataURL("image/webp", 0.5) //canvas->image
            var binaryData = atob(imgDataUrl.split(',')[1])
            var array = []
            for (var i = 0; i < binaryData.length; i++) {
                array.push(binaryData.charCodeAt(i));
            }
            var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})
            var formData = new FormData()
            formData.append("image", file)

            // 이미지 업로드
            this.$http.post('/api/admin/provide/image/insert/image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    card_nation: this.card_nation
                }
            }).then((res) => {
                // DB 입력
                this.$http.post('/api/admin/provide/image/insert/data', {
                    params: {
                        user_id : this.$store.state.user_id,
                        card_id : this.$route.query.id,
                        card_nation : this.card_nation,
                        image : res.data
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        // DB 알림
                        this.$dbAlarm(
                            "제보 - 이미지",
                            this.$store.state.nickname,
                            this.$store.state.user_id
                        )

                        alert("이미지 등록 신청이 완료되었습니다.")
                        this.$emit("close")
                    }
                })
            })
        }, 500),

        // 제출
        submit(){
            if(!this.uploadImage.name){
                alert("등록하실 이미지를 선택해주세요")
            }else if(this.card_nation == ""){
                alert("등록하실 이미지의 발매 국가를 선택해주세요")
            }else{
                this.imageUpload()
            }
        },
    }
}
</script>
<style scoped>
.table_style{
    border:1px solid #ccc;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ccc;
    padding:10px;
}

.my-clipper {
    width:100%;
}

.placeholder {
    width:245px;
    height:363px;
}
</style>