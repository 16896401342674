<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- 카드이름 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">카드이름</span>
                <v-sheet class="pb-2px">
                    <v-text-field
                        class="ml-5 font-weight-bold"
                        style="font-size:15px;"
                        placeholder="카드이름을 입력해주세요"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="basic.name_local"
                        @change="basicUpdated()"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <div class="d-flex">
                <!-- 희귀도 -->
                <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10" width="50%">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">희귀도</span>
                    <v-sheet class="pb-2px ml-2px">
                        <v-autocomplete
                            class="ml-8 font-weight-bold"
                            style="font-size:15px; width:220px;"
                            placeholder="희귀도를 입력해주세요"
                            dense
                            solo
                            flat
                            hide-details
                            :items="select_list.rarity"
                            item-text="name"
                            item-value="value"
                            v-model="basic.rarity"
                            @change="basicUpdated()"
                        >
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-autocomplete>
                    </v-sheet>
                </v-sheet>

                <!-- 발매여부 -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 ml-2 rounded-10" width="50%">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">발매여부</span>
                    <v-sheet class="pb-2px" style="margin-left:76px;">
                        <v-checkbox
                            class="my-0"
                            hide-details dense
                            v-model="basic.isAvailable"
                            :label="basic.isAvailable? '발매중':'발매안됨'"
                            @change="basicUpdated()"
                        ></v-checkbox>
                    </v-sheet>
                </v-sheet>
            </div>

            <!-- 콜렉션넘버 & 토탈넘버 -->
            <div class="d-flex">
                <!-- 콜렉션넘버 -->
                <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10" width="50%">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">콜렉션넘버</span>
                    <v-sheet class="ml-1 pb-2px">
                        <v-text-field
                            class="font-weight-bold"
                            style="font-size:15px;"
                            placeholder="콜렉션넘버"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="basic.number"
                            @change="basicUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <!-- 토탈넘버 -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 ml-2 rounded-10" width="50%">
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">토탈넘버</span>
                    <v-sheet class="ml-1 pb-2px">
                        <v-text-field
                            class="font-weight-bold"
                            style="font-size:15px;"
                            placeholder="제품명 선택시 자동입력"
                            dense
                            hide-details
                            solo
                            flat
                            v-model="basic.set.printedTotal"
                            @change="basicUpdated()"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
            </div>

            <!-- 제품명 -->
            <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">제품명</span>
                <v-sheet width="610">
                    <v-autocomplete
                        class="ml-8 font-weight-bold"
                        style="font-size:15px;"
                        solo
                        flat
                        dense
                        hide-details
                        placeholder="제품명을 선택해주세요"
                        :items="select_list.set"
                        v-model="basic.set.name"
                        @change="autoTotalNumber()"
                    >
                        <template v-slot:selection="{ item }">
                            <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                        </template>
                        <template v-slot:append>
                            <v-icon color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </v-sheet>
            </v-sheet>

            <!-- 효과 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">효과</span>
                <v-sheet class="pb-2px" width="610">
                    <v-text-field
                        class="ml-11 font-weight-bold"
                        style="font-size:15px;"
                        placeholder="효과를 입력해주세요"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="basic.effects"
                        @change="basicUpdated()"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <!-- 룰 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <span style="font-size:15px; font-weight:bold;">룰</span>
                <v-sheet class="pb-2px" width="610">
                    <v-text-field
                        class="ml-14 font-weight-bold"
                        style="font-size:15px;"
                        placeholder="룰을 입력해주세요"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="basic.rules"
                        @change="basicUpdated()"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- 카드이름 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">카드이름</p>
                <v-sheet class="pb-2px">
                    <v-text-field
                        class="ml-5 font-weight-bold"
                        style="font-size:12px;"
                        placeholder="카드이름을 입력해주세요"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="basic.name_local"
                        @change="basicUpdated()"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <!-- 희귀도 -->
            <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">희귀도</p>
                <v-sheet class="pb-2px ml-2px">
                    <v-autocomplete
                        class="ml-5 font-weight-bold"
                        style="font-size:12px;"
                        placeholder="희귀도를 입력해주세요"
                        dense
                        solo
                        flat
                        hide-details
                        :items="select_list.rarity"
                        item-text="name"
                        item-value="value"
                        v-model="basic.rarity"
                        @change="basicUpdated()"
                    >
                        <template v-slot:append>
                            <v-icon color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </v-sheet>
            </v-sheet>

            <!-- 발매여부 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">발매여부</p>
                <v-sheet class="pb-2px">
                    <v-checkbox
                        class="my-0 ml-10"
                        hide-details dense
                        v-model="basic.isAvailable"
                        :label="basic.isAvailable? '발매중':'발매안됨'"
                        @change="basicUpdated()"
                    ></v-checkbox>
                </v-sheet>
            </v-sheet>

            <!-- 콜렉션넘버 -->
            <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">콜렉션넘버</p>
                <v-sheet class="ml-1 pb-2px">
                    <v-text-field
                        class="ml-3 font-weight-bold"
                        style="font-size:12px;"
                        placeholder="콜렉션넘버"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="basic.number"
                        @change="basicUpdated()"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <!-- 토탈넘버 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">토탈넘버</p>
                <v-sheet class="ml-1 pb-2px">
                    <v-text-field
                        class="ml-5 font-weight-bold"
                        style="font-size:12px;"
                        placeholder="제품명 선택시 자동입력"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="basic.set.printedTotal"
                        @change="basicUpdated()"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <!-- 제품명 -->
            <v-sheet class="d-flex align-center py-2 pl-6 mb-3 rounded-10">
                <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">제품명</p>
                <v-sheet>
                    <v-autocomplete
                        class="ml-5 font-weight-bold"
                        style="font-size:12px; max-width:176px;"
                        solo
                        flat
                        dense
                        hide-details
                        placeholder="제품명을 선택해주세요"
                        :items="select_list.set"
                        v-model="basic.set.name"
                        @change="autoTotalNumber()"
                    >
                        <template v-slot:append>
                            <v-icon color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-autocomplete>
                </v-sheet>
            </v-sheet>

            <!-- 효과 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">효과</p>
                <v-sheet class="pb-2px">
                    <v-text-field
                        class="ml-5 font-weight-bold"
                        style="font-size:12px;"
                        placeholder="효과를 입력해주세요"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="basic.effects"
                        @change="basicUpdated()"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>

            <!-- 룰 -->
            <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                <p style="font-size:12px; font-weight:bold; width:60px; margin-bottom:2px;">룰</p>
                <v-sheet class="pb-2px">
                    <v-text-field
                        class="ml-5 font-weight-bold"
                        style="font-size:12px;"
                        placeholder="룰을 입력해주세요"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="basic.rules"
                        @change="basicUpdated()"
                    ></v-text-field>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "cardImport",
        "card_id",
        "card_nation",
        "type"
    ],
    
    data: () => ({
        basic: {
            ancientTrait: {
                name: "",
                text: ""
            },
            ancientTrait_name: "",
            ancientTrait_text: "",

            set: {
                printedTotal: "",
                name: ""
            },
            set_printedTotal: "",
            set_name: "",

            retreatCost: [],
            weaknesses: [],
            resistances: [],
            isAvailable: 1
        },

        select_list: {
            rarity: [],
            regulationMark: [],
            set: []
        }
    }),

    mounted() {
        this.load()
    },

    watch: {
        card_nation(){
            this.load()
        }
    },

    methods: {
        // 입력값 초기화
        BasicInit(){
            this.basic = {
                ancientTrait: {
                    name: "",
                    text: ""
                },
                ancientTrait_name: "",
                ancientTrait_text: "",

                set: {
                    printedTotal: "",
                    name: ""
                },
                set_printedTotal: "",
                set_name: "",

                retreatCost: [],
                weaknesses: [],
                resistances: [],
                isAvailable: 1
            }
        },

        // 카드 ID에서 카드 정보 가져오기
        async importCardDataFromCardId(card_id){
            // card_dump
            await this.$http.post('/api/admin/provide/info/card/import/dump', {
                params: {
                    card_id: card_id
                }
            }).then((res) => {
                this.basic.id = card_id
                this.basic.name = JSON.parse(res.data[0].dump).name
                
                this.basic.supertype = JSON.parse(res.data[0].dump).supertype
                this.basic.subtypes = JSON.parse(res.data[0].dump).subtypes
                this.basic.hp = JSON.parse(res.data[0].dump).hp
                this.basic.types = JSON.parse(res.data[0].dump).types
                this.basic.evolvesFrom = JSON.parse(res.data[0].dump).evolvesFrom
                this.basic.evolvesTo = JSON.parse(res.data[0].dump).evolvesTo
                this.basic.artist = JSON.parse(res.data[0].dump).artist
                this.basic.number = JSON.parse(res.data[0].dump).number
                this.basic.nationalPokedexNumbers = JSON.parse(res.data[0].dump).nationalPokedexNumbers
                this.basic.regulationMark = JSON.parse(res.data[0].dump).regulationMark
                this.basic.retreatCost = JSON.parse(res.data[0].dump).retreatCost
                // this.basic.isAvailable = res.data[0].isAvailable

                if(JSON.parse(res.data[0].dump).images = null)
                {
                    this.basic.weaknesses = JSON.parse(res.data[0].dump).weaknesses
                    this.basic.resistances = JSON.parse(res.data[0].dump).resistances
                }
                else
                {
                    if(JSON.parse(res.data[0].dump).weaknesses!=undefined && JSON.parse(res.data[0].dump).weaknesses.length){
                        for(let weakness of JSON.parse(res.data[0].dump).weaknesses){
                            this.basic.weaknesses.push(
                                {
                                    name: weakness.type+" "+weakness.value, 
                                    input: {
                                        type:weakness.type, 
                                        value:weakness.value
                                    }
                                }
                            )
                        }
                    }

                    if(JSON.parse(res.data[0].dump).resistances!=undefined && JSON.parse(res.data[0].dump).resistances.length){
                        for(let resistance of JSON.parse(res.data[0].dump).resistances){
                            this.basic.resistances.push(
                                {
                                    name: resistance.type+" "+resistance.value,
                                    input: {
                                        type:resistance.type,
                                        value:resistance.value
                                    }
                                }
                            )
                        }
                    }
                }
            })

            // card_kr
            if(this.card_nation=='kr')
            {
                await this.$http.post('/api/card/detail/card_kr', {
                    params: {
                        id: card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.basic.name_local = res.data[0].name
                        this.basic.ancientTrait.name = res.data[0].ancientTrait_name
                        this.basic.ancientTrait.text = res.data[0].ancientTrait_text
                        this.basic.number = res.data[0].number
                        this.basic.effects = res.data[0].effects
                        this.basic.rules = res.data[0].rules
                        this.basic.rarity = res.data[0].rarity
                        this.basic.set.printedTotal = res.data[0].set_printedTotal
                        this.basic.set.name = res.data[0].set_name
                        this.basic.isAvailable = res.data[0].isAvailable
                    }
                })
            }

            // card_jp
            else if(this.card_nation=='jp')
            {
                await this.$http.post('/api/card/detail/card_jp', {
                    params: {
                        id: card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.basic.name_local = res.data[0].name
                        this.basic.ancientTrait.name = res.data[0].ancientTrait_name
                        this.basic.ancientTrait.text = res.data[0].ancientTrait_text
                        this.basic.number = res.data[0].number
                        this.basic.effects = res.data[0].effects
                        this.basic.rules = res.data[0].rules
                        this.basic.rarity = res.data[0].rarity
                        this.basic.set.printedTotal = res.data[0].set_printedTotal
                        this.basic.set.name = res.data[0].set_name
                        this.basic.isAvailable = res.data[0].isAvailable
                    }
                })
            }

            // card_en
            else if(this.card_nation=='en')
            {
                await this.$http.post('/api/card/detail/card_en', {
                    params: {
                        id: card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.basic.name_local = JSON.parse(res.data[0].dump).name
                        if(JSON.parse(res.data[0].dump).hasOwnProperty('ancientTrait')){
                            this.basic.ancientTrait.name = JSON.parse(res.data[0].dump).ancientTrait.name
                            this.basic.ancientTrait.text = JSON.parse(res.data[0].dump).ancientTrait.text
                        }else{
                            this.basic.ancientTrait.name = JSON.parse(res.data[0].dump).ancientTrait_name
                            this.basic.ancientTrait.text = JSON.parse(res.data[0].dump).ancientTrait_text
                        }
                        this.basic.number = JSON.parse(res.data[0].dump).number
                        this.basic.effects = JSON.parse(res.data[0].dump).effects
                        this.basic.rules = JSON.parse(res.data[0].dump).rules
                        this.basic.rarity = JSON.parse(res.data[0].dump).rarity
                        this.basic.set.printedTotal = JSON.parse(res.data[0].dump).set.printedTotal
                        this.basic.set.name = JSON.parse(res.data[0].dump).set.name
                        this.basic.isAvailable = res.data[0].isAvailable
                    }
                })
            }
        },

        // 제보카드 정보 불러오기
        async importProvideCard(provide_id){
            await this.$http.post('/api/admin/provide/info/import/card', {
                params: {
                    provide_id: provide_id
                }
            }).then(res => {
                console.log(res)
                if(res.data.length){
                    this.basic.name = res.data[0].name
                    this.basic.name_local = res.data[0].name_local
                    this.basic.ancientTrait.name = res.data[0].ancientTrait_name
                    this.basic.ancientTrait.text = res.data[0].ancientTrait_text
                    this.basic.effects = res.data[0].effects
                    this.basic.rules = res.data[0].rules
                    this.basic.number = res.data[0].number
                    this.basic.rarity = res.data[0].rarity
                    this.basic.set.printedTotal = res.data[0].set_printedTotal
                    this.basic.set.name = res.data[0].set_name
                }
            })
        },

        // 희귀도 없을 경우 자동 매칭
        async rarityMatch(card_id){
            await this.$http.post('/api/admin/provide/info/card/import/dump', {
                params: {
                    card_id: card_id
                }
            }).then( async (res) => {
                let rarity_en = JSON.parse(res.data[0].dump).rarity

                await this.$http.post('/api/admin/card/pre_card/rarity_match', {
                    params: {
                        rarity_en: rarity_en
                    }
                }).then((res) => {
                    console.log(res)
                    if(res.data.length){
                        if(this.card_nation == "kr"){
                            this.basic.rarity = res.data[0].kr
                        }else if(this.card_nation == "jp"){
                            this.basic.rarity = res.data[0].jp
                        }
                    }
                })
            })
        },

        // 희귀도 select_list 가져오기
        async loadRaritySelectList(){
            this.$http.post('/api/admin/card/pre_card/rarity/list')
            .then((res) => {
                if(this.card_nation=='kr'){
                    this.select_list.rarity = res.data.map(e => e.kr).filter(e => e!='-' && e!='')
                }else if(this.card_nation=='jp'){
                    this.select_list.rarity = res.data.map(e => e.jp).filter(e => e!='-' && e!='')
                }else if(this.card_nation=='en'){
                    this.select_list.rarity = res.data.map(e => e.en).filter(e => e!='-' && e!='')
                }
            })
        },

        // 제품명 select_list 가져오기
        loadSetSelectList(){
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: this.card_nation
                }
            }).then(res => { 
                this.select_list.set = res.data.map(a => a.name)
            })
        },

        // 레귤레이션 select_list 불러오기
        loadRegulationSelectList(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.select_list.regulationMark = res.data.map(e => e.regulation_mark).reverse()
            })
        },

        // 기본 정보 업데이트
        basicUpdated(){
            // 방식 변경
            if(this.basic.ancientTrait.name != null){
                this.basic.ancientTrait_name = this.basic.ancientTrait.name
            }

            if(this.basic.ancientTrait.text != null){
                this.basic.ancientTrait_text = this.basic.ancientTrait.text
            }

            if(this.basic.set.printedTotal != null){
                this.basic.set_printedTotal = this.basic.set.printedTotal
            }

            if(this.basic.set.name != null){
                this.basic.set_name = this.basic.set.name
            }

            if(this.basic.weaknesses.length){

            }

            this.$emit("basicUpdated", this.basic)
        },

        // 불러오기
        async load(){
            // 입력값 초기화
            await this.BasicInit()
            
            // 카드상세 페이지 > 한글카드(선), 일본카드(선) 수정하기
            // 관리자 > 선입력카드 관리 > 수정하기
            if(this.type == "update" || this.type=="provide")
            {
                await this.importCardDataFromCardId(this.card_id)
            }
            else if(this.type == "read")
            {
                await this.importProvideCard(this.card_id)
            }

            // 희귀도 없을 경우 자동매칭
            if(this.basic.rarity == "" || this.basic.rariy == null){
                await this.rarityMatch(this.card_id)
            }

            // 희귀도 목록 가져오기
            await this.loadRaritySelectList()

            // 제품명 목록 가져오기
            await this.loadSetSelectList()

            // 레귤레이션 list 불러오기
            await this.loadRegulationSelectList()

            // basic 업데이트
            await this.basicUpdated()
        },

        // 제품명 선택시 토탈넘버 자동입력
        autoTotalNumber(){
            if(this.card_nation=='kr'){
                this.$http.post('/api/card/select/sets/sets_kr/list/import/find', {
                    params: {
                        set_name: this.basic.set.name
                    }
                }).then((res) => { 
                    if(res.data.length){
                        this.basic.set.printedTotal = res.data[0].totalNumber
                        this.basicUpdated()
                    }
                })
            }else if(this.card_nation=='jp'){
                this.$http.post('/api/card/select/sets/sets_jp/list/import/find', {
                    params: {
                        set_name: this.basic.set.name
                    }
                }).then((res) => { 
                    if(res.data.length){
                        this.basic.set.printedTotal = res.data[0].totalNumber
                        this.basicUpdated()
                    }
                })
            }else if(this.card_nation=='en'){
                this.$http.post('/api/card/select/sets/sets_en/list/import/find', {
                    params: {
                        set_name: this.basic.set.name
                    }
                }).then((res) => { 
                    if(res.data.length){
                        this.basic.set.printedTotal = res.data[0].totalNumber
                        this.basicUpdated()
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
/* 테이블 */
#basic{
    background:white;
    border:1px solid #ccc;
    border-collapse:collapse;
    text-align:center;
}

/* 헤더 (기본 정보) */
#basic tr th{
    width:100%;
    height:44px;
    height:44px;
    font-size:13pt;
    font-weight:bold;
}

/* 값 TD */
#basic tr td{
    width:260px;
    height:38px;
    font-size:11pt;
    border:1px solid #ccc;
    padding:6px;
}

/* 이름 TD */
#basic tr td:nth-child(odd){ 
    width:140px;
    background:#f2f3f4;
}
</style>