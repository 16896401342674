<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
        >
            <v-sheet v-if="text_nation=='kr' && Object.keys(card.kr).length && !card.kr.isAvailable">
                <v-sheet class="my-16">
                    <p
                        :class="isBoard? 'text-subtitle-1':'text-h6'"
                        class="text-center font-weight-bold"
                    >
                        국내에 발매되지 않은 카드입니다.<br/>
                        This card has not been released in Korea
                    </p>
                </v-sheet>
            </v-sheet>
            <v-sheet v-if="text_nation=='en' && Object.keys(card.en).length && !card.en.isAvailable">
                <v-sheet class="my-16">
                    <p
                        :class="isBoard? 'text-subtitle-1':'text-h6'"
                        class="text-center font-weight-bold"
                    >
                        The English version of the card has not been released.<br/>
                        영어버전이 발매되지 않은 카드입니다.
                    </p>
                </v-sheet>
            </v-sheet>
            <v-sheet v-if="text_nation=='jp' && Object.keys(card.jp).length && !card.jp.isAvailable">
                <v-sheet class="my-16">
                    <p
                        :class="isBoard? 'text-subtitle-1':'text-h6'"
                        class="text-h6 text-center font-weight-bold"
                    >
                        일어버전이 발매되지 않은 카드입니다.<br/>
                        This card has not been released in Japan.<br/>
                        日本語版が発売されていないカードです。
                    </p>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
        >
            <v-sheet v-if="text_nation=='kr' && Object.keys(card.kr).length && !card.kr.isAvailable">
                <v-sheet class="py-4 px-8">
                    <p
                        :class="isBoard? 'text-subtitle-1':'text-subtitle-1'"
                        class="text-center font-weight-bold"
                    >
                        국내에 발매되지 않은 카드입니다.<br/>
                        This card has not been released in Korea
                    </p>
                </v-sheet>
            </v-sheet>
            <v-sheet v-if="text_nation=='en' && Object.keys(card.en).length && !card.en.isAvailable">
                <v-sheet class="py-4 px-8">
                    <p
                        :class="isBoard? 'text-subtitle-1':'text-subtitle-1'"
                        class="text-center font-weight-bold"
                    >
                        The English version of the card has not been released.<br/>
                        영어버전이 발매되지 않은 카드입니다.
                    </p>
                </v-sheet>
            </v-sheet>
            <v-sheet v-if="text_nation=='jp' && Object.keys(card.jp).length && !card.jp.isAvailable">
                <v-sheet class="py-4 px-8">
                    <p
                        :class="isBoard? 'text-subtitle-1':'text-subtitle-1'"
                        class="text-h6 text-center font-weight-bold"
                    >
                        일어버전이 발매되지 않은 카드입니다.<br/>
                        This card has not been released in Japan.<br/>
                        日本語版が発売されていないカードです。
                    </p>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"]
}
</script>