<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="mb-5"
        >
            <v-sheet v-if="text_nation=='en'">
                <v-sheet class="mt-3" v-if="card.en.abilities && card.en.isAvailable">
                    <v-sheet class="mb-2" v-for="item in card.en.abilities" :key="item.name">
                        <v-sheet class="d-flex align-center mb-2">
                            <!-- 능력 type-->
                            <font style="font-size:18px;" class="font-weight-bold rounded-10 mr-3 red white--text px-4 py-2px">{{item.type}}</font>
                            <!-- 능력 name -->
                            <font style="font-size:20px;" class="font-weight-bold">{{item.name}}</font>
                        </v-sheet>
                        <v-sheet class="mx-1">
                            <!-- 능력 text -->
                            <span 
                                style="font-size:18px;" 
                                v-html="replaceEnglishToIcon(item.text)"
                            ></span>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <v-sheet v-if="text_nation=='kr'">
                <v-sheet class="mt-3" v-if="abilities_list_kr.length && card.kr.isAvailable">
                    <v-sheet class="mb-2" v-for="item in abilities_list_kr" :key="item.name">
                        <v-sheet class="d-flex align-center mb-2">
                            <!-- 능력 type-->
                            <font style="font-size:18px;" class="font-weight-bold rounded-10 mr-3 red white--text px-4 py-2px">{{item.type}}</font>
                            <!-- 능력 name -->
                            <font style="font-size:20px;" class="font-weight-bold">{{item.name}}</font>
                        </v-sheet>
                        <v-sheet class="mx-1">
                            <!-- 능력 text -->
                            <span 
                                style="font-size:18px;" 
                                v-html="replaceKoreanToIcon(item.text)"
                            ></span>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <v-sheet v-if="text_nation=='jp'">
                <v-sheet class="mt-3" v-if="abilities_list_jp && card.jp.isAvailable">
                    <v-sheet class="mb-2" v-for="item in abilities_list_jp" :key="item.name">
                        <v-sheet class="d-flex align-center mb-2">
                            <!-- 능력 type-->
                            <font style="font-size:18px;" class="font-weight-bold rounded-10 mr-3 red white--text px-4 py-2px">{{item.type}}</font>
                            <!-- 능력 name -->
                            <font style="font-size:20px;" class="font-weight-bold">{{item.name}}</font>
                        </v-sheet>
                        <v-sheet class="mx-1">
                            <!-- 능력 text -->
                            <span 
                                style="font-size:18px;" 
                                v-html="replaceJapaneseToIcon(item.text)"
                            ></span>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="px-7 mobile"
        >
            <v-sheet v-if="text_nation=='en'">
                <v-sheet v-if="card.en.abilities && card.en.isAvailable">
                    <v-sheet class="pt-1 pb-2" v-for="item in card.en.abilities" :key="item.name">
                        <v-sheet class="d-flex align-center mb-1">
                            <!-- 능력 type-->
                            <font style="font-size:12px;" class="font-weight-bold rounded-10 mr-3 red white--text px-3 py-2px">{{item.type}}</font>
                            <!-- 능력 name -->
                            <font style="font-size:14px;" class="font-weight-bold">{{item.name}}</font>
                        </v-sheet>
                        <v-sheet>
                            <!-- 능력 text -->
                            <p 
                                style="font-size:12px;" 
                                class="mb-0"
                                v-html="replaceEnglishToIcon(item.text)"
                            ></p>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <v-sheet v-if="text_nation=='kr'">
                <v-sheet v-if="abilities_list_kr.length && card.kr.isAvailable">
                    <v-sheet class="pt-1 pb-2" v-for="item in abilities_list_kr" :key="item.name">
                        <v-sheet class="d-flex align-center mb-1">
                            <!-- 능력 type-->
                            <font style="font-size:12px;" class="font-weight-bold rounded-10 mr-3 red white--text px-3 py-2px">{{item.type}}</font>
                            <!-- 능력 name -->
                            <font style="font-size:14px;" class="font-weight-bold">{{item.name}}</font>
                        </v-sheet>
                        <v-sheet>
                            <!-- 능력 text -->
                            <p 
                                style="font-size:12px;" 
                                class="mb-0"
                                v-html="replaceKoreanToIcon(item.text)"
                            ></p>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <v-sheet v-if="text_nation=='jp'">
                <v-sheet v-if="abilities_list_jp && card.jp.isAvailable">
                    <v-sheet class="pt-1 pb-2" v-for="item in abilities_list_jp" :key="item.name">
                        <v-sheet class="d-flex align-center mb-1">
                            <!-- 능력 type-->
                            <font style="font-size:12px;" class="font-weight-bold rounded-10 mr-3 red white--text px-3 py-2px">{{item.type}}</font>
                            <!-- 능력 name -->
                            <font style="font-size:14px;" class="font-weight-bold">{{item.name}}</font>
                        </v-sheet>
                        <v-sheet>
                            <!-- 능력 text -->
                            <p 
                                style="font-size:12px;"
                                class="mb-0"
                                v-html="replaceJapaneseToIcon(item.text)"
                            ></p>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],

    data: () => ({
        abilities_list_kr: [],
        abilities_list_jp: [],
    }),

    watch: {
        card: {
            deep:true,
            handler(newData) {
                this.importAbilities()
            }
        }
    },

    mounted(){
        this.importAbilities()
    },

    methods: {
        importAbilities(){
            // 한글카드 능력 불러오기
            this.$http.post('/api/card/select/abilities/kr', {
                params: {
                    card_id: this.card.kr.id
                }
            }).then((res) => {
                this.abilities_list_kr = res.data
            })

            // 일본카드 능력 불러오기
            this.$http.post('/api/card/select/abilities/jp', {
                params: {
                    card_id: this.card.jp.id
                }
            }).then((res) => {
                this.abilities_list_jp = res.data
            })
        },

        // 에너지 text 아이콘으로 변환 (영어)
        replaceEnglishToIcon(text){
            if(!text){
                return text
            }
            text = text.replaceAll('Grass Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" title=" Grass Energy"></img>`)
            text = text.replaceAll('Fire Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" title=" Fire Energy"></img>`)
            text = text.replaceAll('Water Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" title=" Water Energy"></img>`)
            text = text.replaceAll('Lightning Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" title=" Lightning Energy"></img>`)
            text = text.replaceAll('Psychic Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" title=" Psychic Energy"></img>`)
            text = text.replaceAll('Fighting Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" title=" Fighting Energy"></img>`)
            text = text.replaceAll('Darkness Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" title=" Darkness Energy"></img>`)
            text = text.replaceAll('Metal Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" title=" Metal Energy"></img>`)
            text = text.replaceAll('Fairy Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" title=" Fairy Energy"></img>`)
            text = text.replaceAll('Dragon Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" title=" Dragon Energy"></img>`)
            text = text.replaceAll('Colorless Energy', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" title=" Colorless Energy"></img>`)
            return text
        },

        // 에너지 text 아이콘으로 변환 (한글)
        replaceKoreanToIcon(text){
            if(!text){
                return text
            }
            text = text.replaceAll('풀 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" title=" 풀 에너지"></img>`)
            text = text.replaceAll('불꽃 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" title=" 불꽃 에너지"></img>`)
            text = text.replaceAll('물 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" title=" 물 에너지"></img>`)
            text = text.replaceAll('번개 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" title=" 번개 에너지"></img>`)
            text = text.replaceAll('초 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" title=" 초 에너지"></img>`)
            text = text.replaceAll('격투 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" title=" 격투 에너지"></img>`)
            text = text.replaceAll('악 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" title=" 악 에너지"></img>`)
            text = text.replaceAll('강철 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" title=" 강철 에너지"></img>`)
            text = text.replaceAll('페어리 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" title=" 페어리 에너지"></img>`)
            text = text.replaceAll('드래곤 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" title=" 드래곤 에너지"></img>`)
            text = text.replaceAll('무색 에너지', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" title=" 무색 에너지"></img>`)
            return text
        },

        // 에너지 text 아이콘으로 변환 (일어)
        replaceJapaneseToIcon(text){
            if(!text){
                return text
            }
            text = text.replaceAll('草エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" title=" 草エネルギー"></img>`)
            text = text.replaceAll('炎エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" title=" 炎エネルギー"></img>`)
            text = text.replaceAll('水エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" title=" 水エネルギー"></img>`)
            text = text.replaceAll('雷エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" title=" 雷エネルギー"></img>`)
            text = text.replaceAll('超エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" title=" 超エネルギー"></img>`)
            text = text.replaceAll('闘エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" title=" 闘エネルギー"></img>`)
            text = text.replaceAll('悪エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" title=" 悪エネルギー"></img>`)
            text = text.replaceAll('鋼エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" title=" 鋼エネルギー"></img>`)
            text = text.replaceAll('フェアリエネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" title=" 페어리 에너지"></img>`)
            text = text.replaceAll('ドラゴンエネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" title=" 드래곤 에너지"></img>`)
            text = text.replaceAll('無色エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" title=" 무색 에너지"></img>`)
            return text
        }
    }
}
</script>
<style scoped>
.mobile >>> img {
    width: 16px !important;
    height: 16px !important;
}
</style>