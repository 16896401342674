<template>
    <!-- 상단 -->
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="d-flex align-center rounded-10 py-2"
        >
            <!-- 제목 -->
            <div class="ml-4">
                <p
                    class="mt-2 mb-1 font-weight-bold"
                    style="font-size:26px;"
                >
                    카드검색
                    <span style="margin-left:4px; color:#F8468D; font-size:12px; vertical-align:super; font-weight:500;">BETA</span>
                </p>
                <p style="font-size:11px;">
                    전 세계 최대 포켓몬카드 자체 데이터베이스를 이용해보세요. 
                </p>
            </div>

            <v-spacer></v-spacer>
            <v-sheet
                class="px-6 py-3 rounded-15 d-flex justify-space-between"
                style="cursor:pointer;"
                @click="$router.push('/board/list?board_group=너정다&board_name=카드/DB%20추가%20요청')"
            >
                <v-icon left>mdi-help-circle-outline</v-icon>찾는 카드가 없나요?
            </v-sheet>

            <BannerView
                v-if="$store.state.ads_banner.card_search_detail"
                class="ml-3"
                type="card_search_detail"
                width="360"
                height="46"
                :round="'rounded-10'"
            />
        </div>
        
        <!-- 검색 & 목록 -->
        <CardList :isWrite="false" :key="componentKey"/>
    </div>
</template>
<script>
import CardList from '@/components/card/List'
import BannerView from '@/components/BannerView'

export default {
    components: {
        CardList,
        BannerView
    },

    data: () => ({
        componentKey: 0
    }),

    mounted(){
        this.componentKey++
    }
}
</script>