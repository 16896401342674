<template>
    <v-sheet width="780" class="rounded-15 px-16 pt-8 pb-4" color="#F8F7FB">
        <!-- 제목 -->
        <p class="mb-5 font-weight-bold text-center" style="font-size:24px;">
            정보오류 제보하기
        </p>

        <!-- 제보 카드 ID -->
        <p class="ml-4 mb-2" style="font-size:16px; font-weight:bold;">
            제보 카드 ID
            <span class="ml-2">{{card.en.id}}</span>
        </p>

        <!-- 발매국가 -->
        <v-sheet class="d-flex align-center py-2 px-6 rounded-10">
            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
            <span style="font-size:15px; font-weight:bold;">발매국가</span>
            <v-select
                style="max-width:240px; margin-left:160px;"
                placeholder="발매국가를 선택해주세요"
                dense
                hide-details
                solo
                flat
                :items="select_list.card_nation"
                item-text="name"
                item-value="value"
                v-model="provide.card_nation"
            >
                <template v-slot:selection="{ item }">
                    <v-sheet width="100%" class="text-center font-weight-bold">{{item.name}}</v-sheet>
                </template>
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </v-sheet>

        <!-- 제보항목 -->
        <v-sheet class="d-flex align-center py-2 px-6 mt-3 rounded-10">
            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
            <span style="font-size:15px; font-weight:bold;">제보항목</span>
            <v-select
                style="max-width:240px; margin-left:160px;"
                placeholder="제보 항목을 선택해주세요"
                dense
                hide-details
                solo
                flat
                :items="select_list.category"
                v-model="provide.category"
            >
                <template v-slot:selection="{ item }">
                    <v-sheet width="100%" class="text-center font-weight-bold">{{item}}</v-sheet>
                </template>
                <template v-slot:append>
                    <v-icon color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </v-sheet>

        <!-- 제보내용 -->
        <v-sheet class="py-4 px-6 mt-3 rounded-10">
            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
            <span style="font-size:15px; font-weight:bold;">제보내용</span>
            <v-textarea
                class="no_under"
                rows="4"
                hide-details
                v-model="provide.content"
                placeholder="내용을 입력해주세요"
            ></v-textarea>
        </v-sheet>
        
        <!-- 안내문구 -->
        <p class="text-center font-weight-bold mt-2 mb-4 primary--text" style="font-size:12px;">
            정보오류를 제보해주시면 소정의 포인트가 지급됩니다.
        </p>

        <!-- 버튼 -->
        <div class="mt-2 pa-2 d-flex justify-center">
            <!-- 제출 -->
            <v-btn
                width="110"
                height="42"
                dark
                depressed
                color="#615DFA"
                class="font-weight-bold rounded-10 mr-2"
                @click="submit()"
            >
                제출
            </v-btn>

            <!-- 취소 -->
            <v-btn
                width="110"
                height="42"
                depressed
                color="white"
                class="font-weight-bold rounded-10"
                @click="close()"
            >
                취소
            </v-btn>
        </div>
    </v-sheet>
</template>
<script>
export default {
    props: ["card"],

    data: () => ({
        select_list: {
            card_nation: [
                {name: "국내판", value: "kr"},
                {name: "북미판", value: "en"},
                {name: "일본판", value: "jp"}
            ],

            category: [
                "오타",
                "정보 오류",
                "업데이트",
                "시세/가격"
            ]
        },

        provide: {
            card_nation: "",
            category: "",
            content: ""
        }
    }),

    methods: {
        // 제출
        submit(){
            if(this.provide.card_nation == ""){
                alert("발매국가를 선택해주세요")
            }else if(this.provide.category == ""){
                alert("제보 항목을 선택해주세요")
            }else if(this.provide.content == ""){
                alert("제보 내용을 입력해주세요")
            }else{
                this.$http.post("/api/admin/provide/bug/insert", {
                    params: {
                        user_id: this.$store.state.user_id,
                        card_id: this.card.en.id,
                        card_nation: this.provide.card_nation,
                        category: this.provide.category,
                        content: this.provide.content
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        // DB 알림
                        this.$dbAlarm(
                            "제보 - 오류",
                            this.$store.state.nickname,
                            this.$store.state.user_id
                        )

                        alert("정보오류가 제보되었습니다.")
                        this.$emit("close")
                    }
                })
            }
        },

        // 닫기
        close(){
            this.$emit("close")
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>