<template>
    <div>
        <!-- 버튼 -->
        <div class="d-flex">
            <!-- 간편수정 -->
            <v-btn
                class="ml-2 mr-2 rounded-10 font-weight-bold px-6"
                color="white"
                height="50"
                depressed
                @click="dialog.update.simple=true"
            >
                <v-icon size="24" left color="#615DFA">
                    mdi-file-document-edit-outline
                </v-icon>
                간편수정
            </v-btn>

            <!-- 전체수정 -->
            <v-btn
                class="mr-2 rounded-10 font-weight-bold px-6"
                color="white"
                height="50"
                depressed
                @click="dialog.update.whole=true"
            >
                <v-icon size="24" left color="#615DFA">
                    mdi-database-edit-outline
                </v-icon>
                전체수정
            </v-btn>

            <!-- 카드 복사하기 -->
            <v-btn
                class="rounded-10 font-weight-bold px-8"
                color="white"
                height="50"
                depressed
                @click="dialog.copy = true"
            >
                <v-icon size="24" left color="#615DFA">
                    mdi-plus-box
                </v-icon>
                복사
            </v-btn>
        </div>

        <!-- dialog -->
        <div>
            <!-- 간편수정 -->
            <v-dialog v-model="dialog.update.simple" width="auto">
                <UpdateCard 
                    :card_id="$route.query.id"
                    insert_type="simple"
                    type="update"
                    :isPreCard="isPreCard"
                    :text_nation="text_nation"
                    @close="dialog.update.simple = false"
                />
            </v-dialog>

            <!-- 전체수정 -->
            <v-dialog v-model="dialog.update.whole" width="auto">
                <UpdateCard
                    :card_id="$route.query.id"
                    insert_type="whole"
                    type="update"
                    :isPreCard="isPreCard"
                    :text_nation="text_nation"
                    @close="dialog.update.whole = false"
                />
            </v-dialog>

            <!-- 복사하기 -->
            <v-dialog v-model="dialog.copy" width="auto">
                <CopyCard 
                    @close="dialog.copy = false"
                />
            </v-dialog>
        </div>
    </div>
</template>
<script>
import UpdateCard from '@/components/cardmodule/UpdateCard'
import CopyCard from './AdminButtons/CopyCard'

export default {
    props: [
        "card", 
        "card_id", 
        "isPreCard",
        "text_nation"
    ],

    components: {
        UpdateCard,
        CopyCard
    },

    data: () => ({
        dialog: {
            update: {
                simple: false,
                whole: false
            },

            copy: false
        }
    }),

    mounted(){
        // 단축키 설정
        window.addEventListener('keydown', (e) => {
            // console.log(e)
            if(e.ctrlKey && !e.shiftKey && e.code=="KeyE")
            {
                this.dialog.update.simple = true
                e.preventDefault()
            }
            else if(e.ctrlKey && e.shiftKey && e.code=="KeyE")
            {
                this.dialog.update.whole = true
                e.preventDefault()
            }
            else if(e.ctrlKey && e.code=="Space")
            {
                this.dialog.copy = true
                e.preventDefault()
            }
        })
    }
}
</script>