<template>
    <v-sheet width="680">
        <p class="pt-3 mb-3 font-weight-bold text-center text-subtitle-1 grey--text text--darken-2">
            카드 복사하기
        </p>
        <v-divider></v-divider>
        <v-sheet class="pa-3">
            <table class="reportTable">
                <tr>
                    <td>복사 카드 (ID)</td>
                    <td>
                        {{$route.query.id}}
                    </td>
                </tr>
                <tr>
                    <td>선입력 카드 (ID)</td>
                    <td class="pa-2">
                        <v-text-field
                            placeholder="복사하여 입력될 선입력카드의 ID를 입력해주세요"
                            dense
                            hide-details
                            outlined
                            v-model="copy.id"
                        ></v-text-field>
                    </td>
                </tr>
                <tr>
                    <td>한글 제품명</td>
                    <td class="pa-2">
                        <v-sheet width="480">
                            <v-autocomplete
                                placeholder="선입력카드의 제품명을 선택해주세요"
                                dense
                                hide-details
                                outlined
                                :items="sets_selectList_kr"
                                v-model="copy.set_name_kr"
                            ></v-autocomplete>
                        </v-sheet>
                    </td>
                </tr>
                <tr>
                    <td>일본 제품명</td>
                    <td class="pa-2">
                        <v-sheet width="480">
                            <v-autocomplete
                                placeholder="선입력카드의 제품명을 선택해주세요"
                                dense
                                hide-details
                                outlined
                                :items="sets_selectList_jp"
                                v-model="copy.set_name_jp"
                            ></v-autocomplete>
                        </v-sheet>
                    </td>
                </tr>
                <tr>
                    <td>블록넘버</td>
                    <td class="pa-2">
                        <v-text-field
                            placeholder="블록넘버 입력"
                            dense
                            hide-details
                            outlined
                            v-model="copy.number"
                        ></v-text-field>
                    </td>
                </tr>
            </table>
            <v-sheet class="mt-2 pa-2 d-flex justify-center">
                <v-btn large dark color="blue" class="px-6 mr-4" @click="submit()">
                    <v-icon small left>mdi-plus-box</v-icon>복사하기
                </v-btn>
                <v-btn large dark color="grey" class="px-6" @click="close()">
                    <v-icon small left>mdi-cancel</v-icon>취소하기
                </v-btn>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        sets_selectList_kr: [],
        sets_selectList_jp: [],

        copy: {
            id: "",
            set_name_kr: "",
            set_name_jp: "",
            number: 0
        }
    }),

    mounted(){
        // 한글 제품명 list 불러오기
        this.$http.post('/api/card/select/sets', {
            params: {
                nation: "kr"
            }
        }).then(res => { 
             this.sets_selectList_kr = res.data.map(a => a.name)
        })

        // 일본 제품명 list 불러오기
        this.$http.post('/api/card/select/sets', {
            params: {
                nation: "jp"
            }
        }).then(res => { 
             this.sets_selectList_jp = res.data.map(a => a.name)
        })

        // 단축키 설정
        window.addEventListener('keydown', (e) => {
            if(e.ctrlKey && e.code=="Enter")
            {
                this.submit()
                e.preventDefault()
            }
        })
    },

    methods: {
        submit(){
            if(this.copy.id=="")
            {
                alert("선입력 카드 (ID)를 입력해주세요")
            }
            else if(this.copy.set_name_kr=="" || this.copy.set_name_jp=="")
            {
                alert("제품명을 선택해주세요")
            }
            else
            {
                this.$http.post('/api/admin/card/pre_card/copy', {
                    params: {
                        id: this.$route.query.id,
                        pre_card_id: this.copy.id,
                        set_name_kr: this.copy.set_name_kr,
                        set_name_jp: this.copy.set_name_jp,
                        number: this.copy.number
                    }
                }).then(() => {
                    alert("카드가 정상적으로 복사되었습니다.")
                    this.$router.push("/card/detail?id="+this.copy.id)
                })
            }
        },

        close(){
            this.$emit("close")
        }
    }
}
</script>
<style scoped>
.reportTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.reportTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.reportTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>