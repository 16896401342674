<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="mt-4"
            :class="isBoard? 'pb-2':''"
        >
            <v-sheet v-if="text_nation=='en' && card.en.isAvailable">
                <!-- Rules -->
                <v-sheet class="mt-3" v-if="card.en.rules">
                    <!-- 트레이너스일때 -->
                    <v-sheet v-if="card.en.supertype=='Trainer'">
                        <!-- <v-sheet v-if="parsedEffects">
                        <font style="font-size:20px;" class="font-weight-bold ml-1">효과</font>
                            <v-sheet v-for="(item, index) in parsedEffects" :key="index" :min-height="isBoard? 0:40" style="font-size:18px;" class="pa-1">
                                {{item}}
                            </v-sheet>
                        </v-sheet>
                        <v-sheet v-if="parsedRules">
                            <font style="font-size:20px;" class="font-weight-bold ml-1">룰</font>
                            <v-sheet :min-height="isBoard? 0:40" style="font-size:18px;" class="pa-1">
                                {{parsedRules}}
                            </v-sheet>
                        </v-sheet> -->
                        <!-- 반대로 바꿔야함 -->
                        <v-sheet v-if="parsedRules">
                            <font style="font-size:20px;" class="font-weight-bold ml-1">효과</font>
                            <v-sheet :min-height="isBoard? 0:40" style="font-size:18px;" class="pa-1">
                                {{parsedRules}}
                            </v-sheet>
                        </v-sheet>
                        <v-sheet v-if="parsedEffects">
                        <font style="font-size:20px;" class="font-weight-bold ml-1">룰</font>
                            <v-sheet v-for="(item, index) in parsedEffects" :key="index" :min-height="isBoard? 0:40" style="font-size:18px;" class="pa-1">
                                {{item}}
                            </v-sheet>
                        </v-sheet>
                    </v-sheet>
                    <!-- 트레이너스가 아닐때 -->
                    <v-sheet v-if="card.en.supertype!='Trainer'">

                        <font style="font-size:20px;" class="font-weight-bold ml-1">
                            {{card.en.supertype=='Energy'? "효과" : "룰"}}
                        </font>
                        <v-sheet :min-height="isBoard? 0:40" style="font-size:18px;" class="pa-1">
                            {{card.en.rules.toString()}}
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <v-sheet v-if="text_nation=='kr' && card.kr.isAvailable">
                <!-- Effects -->
                <v-sheet class="mt-3" v-if="card.kr.effects">
                    <font style="font-size:20px;" class="font-weight-bold ml-1">효과</font>
                    <v-sheet
                        :min-height="isBoard? 0:40"
                        style="font-size:18px;"
                        class="pa-1"
                        v-html="replaceKoreanToIcon(card.kr.effects.toString())"
                    ></v-sheet>
                </v-sheet>

                <!-- Rules -->
                <v-sheet class="mt-3" v-if="card.kr.rules">
                    <font style="font-size:20px;" class="font-weight-bold ml-1">룰</font>
                    <v-sheet
                        :min-height="isBoard? 0:40"
                        style="font-size:18px;"
                        class="pa-1"
                        v-html="replaceKoreanToIcon(card.kr.rules.toString())"
                    ></v-sheet>
                </v-sheet>
            </v-sheet>

            <v-sheet v-if="text_nation=='jp' && card.jp.isAvailable">
                <!-- Effects -->
                <v-sheet class="mt-3" v-if="card.jp.effects">
                    <font style="font-size:20px;" class="font-weight-bold ml-1">효과</font>
                    <v-sheet
                        :min-height="isBoard? 0:40"
                        style="font-size:18px;"
                        class="pa-1"
                        v-html="replaceJapaneseToIcon(card.jp.effects.toString())"
                    ></v-sheet>
                </v-sheet>

                <!-- Rules -->
                <v-sheet class="mt-3" v-if="card.jp.rules">
                    <font style="font-size:20px;" class="font-weight-bold ml-1">룰</font>
                    <v-sheet
                        :min-height="isBoard? 0:40"
                        style="font-size:18px;"
                        class="pa-1"
                        v-html="replaceJapaneseToIcon(card.jp.rules.toString())"
                    ></v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            :class="isBoard? 'pb-2':''"
            class="pt-1 px-7"
        >
            <v-sheet v-if="text_nation=='en' && card.en.isAvailable">
                <!-- Rules -->
                <v-sheet class="mt-3" v-if="card.en.rules">
                    <!-- 트레이너스일때 -->
                    <v-sheet v-if="card.en.supertype=='Trainer'">
                        <!-- <v-sheet v-if="parsedEffects">
                        <font style="font-size:13px;" class="font-weight-bold">효과</font>
                            <v-sheet v-for="(item, index) in parsedEffects" :key="index" :min-height="isBoard? 0:40" style="font-size:11px;">
                                {{item}}
                            </v-sheet>
                        </v-sheet>
                        <v-sheet v-if="parsedRules">
                            <font style="font-size:13px;" class="font-weight-bold">룰</font>
                            <v-sheet :min-height="isBoard? 0:40" style="font-size:11px;">
                                {{parsedRules}}
                            </v-sheet>
                        </v-sheet> -->
                        <!-- 반대로 바꿔야함 -->
                        <v-sheet v-if="parsedRules">
                            <font style="font-size:13px;" class="font-weight-bold">효과</font>
                            <v-sheet :min-height="isBoard? 0:40" style="font-size:11px;">
                                {{parsedRules}}
                            </v-sheet>
                        </v-sheet>
                        <v-sheet v-if="parsedEffects">
                        <font style="font-size:13px;" class="font-weight-bold">룰</font>
                            <v-sheet v-for="(item, index) in parsedEffects" :key="index" :min-height="isBoard? 0:40" style="font-size:11px;">
                                {{item}}
                            </v-sheet>
                        </v-sheet>
                    </v-sheet>
                    <!-- 트레이너스가 아닐때 -->
                    <v-sheet v-if="card.en.supertype!='Trainer'">

                        <font style="font-size:13px;" class="font-weight-bold">
                            {{card.en.supertype=='Energy'? "효과" : "룰"}}
                        </font>
                        <v-sheet :min-height="isBoard? 0:40" style="font-size:11px;">
                            {{card.en.rules.toString()}}
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <v-sheet v-if="text_nation=='kr' && card.kr.isAvailable">
                <!-- Effects -->
                <v-sheet class="mt-1" v-if="card.kr.effects">
                    <font style="font-size:13px;" class="font-weight-bold">효과</font>
                    <v-sheet
                        :min-height="isBoard? 0:40"
                        style="font-size:11px;"
                        v-html="replaceKoreanToIcon(card.kr.effects.toString())"
                    ></v-sheet>
                </v-sheet>

                <!-- Rules -->
                <v-sheet class="mt-1" v-if="card.kr.rules">
                    <font style="font-size:13px;" class="font-weight-bold">룰</font>
                    <v-sheet
                        :min-height="isBoard? 0:40"
                        style="font-size:11px;"
                        v-html="replaceKoreanToIcon(card.kr.rules.toString())"
                    ></v-sheet>
                </v-sheet>
            </v-sheet>

            <v-sheet v-if="text_nation=='jp' && card.jp.isAvailable">
                <!-- Effects -->
                <v-sheet class="mt-1" v-if="card.jp.effects">
                    <font style="font-size:13px;" class="font-weight-bold">효과</font>
                    <v-sheet
                        :min-height="isBoard? 0:40"
                        style="font-size:11px;"
                        v-html="replaceJapaneseToIcon(card.jp.effects.toString())"
                    ></v-sheet>
                </v-sheet>

                <!-- Rules -->
                <v-sheet class="mt-1" v-if="card.jp.rules">
                    <font style="font-size:13px;" class="font-weight-bold">룰</font>
                    <v-sheet
                        :min-height="isBoard? 0:40"
                        style="font-size:11px;"
                        v-html="replaceJapaneseToIcon(card.jp.rules.toString())"
                    ></v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: ["card", "text_nation", "isBoard"],
    
    computed: {
        parsedEffects(){
            // console.log(this.card.en)
            if(this.card.en.supertype=='Trainer'){
                if(this.card.en.subtypes.length){
                    let effects = [];
                    // 아이템
                    if(this.card.en.subtypes[0]=='Item'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value=="You may play as many Item cards as you like during your turn (before your attack)."){
                                effects.push(this.card.en.rules[index])
                            }else if(value=="You may play any number of Item cards during your turn."){
                                effects.push(this.card.en.rules[index])
                            }
                        })
                    // 서포터
                    }else if(this.card.en.subtypes[0]=='Supporter'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value=="You can play only one Supporter card each turn. When you play this card, put it next to your Active Pokémon. When your turn ends, discard this card."){
                                effects.push(this.card.en.rules[index])
                            }else if(value=="You may play only 1 Supporter card during your turn (before your attack)."){
                                effects.push(this.card.en.rules[index])
                            }else if(value=="You can play only one Supporter card each turn. When you play this card, put it next to your Active Pokémon. When your turn ends, put this card into your hand instead of discarding it."){
                                effects.push(this.card.en.rules[index])
                            }
                        })
                    // 스타디움
                    }else if(this.card.en.subtypes[0]=='Stadium'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value=="This card stays in play when you play it. Discard this card if another Stadium card comes into play. If another card with the same name is in play, you can't play this card."){
                                effects.push(this.card.en.rules[index])
                            }else if(value=="This Stadium stays in play when you play it. Discard it if another Stadium comes into play. If a Stadium with the same name is in play, you can't play this card."){
                                effects.push(this.card.en.rules[index])
                            }
                        })
                    }else if(this.card.en.subtypes[0]=='Pokémon Tool'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value=="You may play as many Item cards as you like during your turn (before your attack)."){
                                effects.push(this.card.en.rules[index])
                            }
                        })
                    }
                    // console.log(effects)
                    return effects;
                }
            }
        },

        parsedRules(){
            if(this.card.en.supertype=='Trainer'){
                if(this.card.en.subtypes.length){
                    let rules = [];
                    // 아이템
                    if(this.card.en.subtypes[0]=='Item'){
                        this.card.en.rules.forEach((value, index) => {
                            if(value!="You may play as many Item cards as you like during your turn (before your attack)."){
                                rules.push(this.card.en.rules[index])
                            }else if(value!="You may play any number of Item cards during your turn."){
                                rules.push(this.card.en.rules[index])
                            }
                        })
                    // 서포터
                    }else if(this.card.en.subtypes[0]=='Supporter'){
                        this.card.en.rules.forEach((value, index) => {
                            if(
                                value!="You can play only one Supporter card each turn. When you play this card, put it next to your Active Pokémon. When your turn ends, discard this card."
                                    &&
                                value!="You may play only 1 Supporter card during your turn (before your attack)."
                                    &&
                                value!="You can play only one Supporter card each turn. When you play this card, put it next to your Active Pokémon. When your turn ends, put this card into your hand instead of discarding it."
                            ){
                                rules.push(this.card.en.rules[index])
                            }
                        })
                    // 스타디움
                    }else if(this.card.en.subtypes[0]=='Stadium'){
                        this.card.en.rules.forEach((value, index) => {
                            if(
                                value!="This card stays in play when you play it. Discard this card if another Stadium card comes into play. If another card with the same name is in play, you can't play this card."
                                    &&
                                value!="This Stadium stays in play when you play it. Discard it if another Stadium comes into play. If a Stadium with the same name is in play, you can't play this card."
                            ){
                                rules.push(this.card.en.rules[index])
                            }
                        })
                    }else if(this.card.en.subtypes[0]=='Pokémon Tool'){
                        this.card.en.rules.forEach((value, index) => {
                            if(
                                value!="You may play as many Item cards as you like during your turn (before your attack)."
                            ){
                                rules.push(this.card.en.rules[index])
                            }
                        })
                    }
                    return rules.toString();
                }
            }
        },
    },

    methods: {
        // 에너지 text 아이콘으로 변환 (영어)
        replaceEnglishToIcon(text){
            if(!text){
                return text
            }
            text = text.replaceAll('Grass Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Grass.png" title=" Grass Energy"></img>`)
            text = text.replaceAll('Fire Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Fire.png" title=" Fire Energy"></img>`)
            text = text.replaceAll('Water Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Water.png" title=" Water Energy"></img>`)
            text = text.replaceAll('Lightning Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Lightning.png" title=" Lightning Energy"></img>`)
            text = text.replaceAll('Psychic Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Psychic.png" title=" Psychic Energy"></img>`)
            text = text.replaceAll('Fighting Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Fighting.png" title=" Fighting Energy"></img>`)
            text = text.replaceAll('Darkness Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Darkness.png" title=" Darkness Energy"></img>`)
            text = text.replaceAll('Metal Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Metal.png" title=" Metal Energy"></img>`)
            text = text.replaceAll('Fairy Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Fairy.png" title=" Fairy Energy"></img>`)
            text = text.replaceAll('Dragon Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Dragon.png" title=" Dragon Energy"></img>`)
            text = text.replaceAll('Colorless Energy', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Colorless.png" title=" Colorless Energy"></img>`)
            return text
        },

        // 에너지 text 아이콘으로 변환 (한글)
        replaceKoreanToIcon(text){
            if(!text){
                return text
            }
            text = text.replaceAll('풀 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Grass.png" title=" 풀 에너지"></img>`)
            text = text.replaceAll('불꽃 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Fire.png" title=" 불꽃 에너지"></img>`)
            text = text.replaceAll('물 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Water.png" title=" 물 에너지"></img>`)
            text = text.replaceAll('번개 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Lightning.png" title=" 번개 에너지"></img>`)
            text = text.replaceAll('초 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Psychic.png" title=" 초 에너지"></img>`)
            text = text.replaceAll('격투 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Fighting.png" title=" 격투 에너지"></img>`)
            text = text.replaceAll('악 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Darkness.png" title=" 악 에너지"></img>`)
            text = text.replaceAll('강철 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Metal.png" title=" 강철 에너지"></img>`)
            text = text.replaceAll('페어리 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Fairy.png" title=" 페어리 에너지"></img>`)
            text = text.replaceAll('드래곤 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Dragon.png" title=" 드래곤 에너지"></img>`)
            text = text.replaceAll('무색 에너지', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Colorless.png" title=" 무색 에너지"></img>`)
            return text
        },

        // 에너지 text 아이콘으로 변환 (일어)
        replaceJapaneseToIcon(text){
            if(!text){
                return text
            }
            text = text.replaceAll('草エネルギー', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Grass.png" title=" 草エネルギー"></img>`)
            text = text.replaceAll('炎エネルギー', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Fire.png" title=" 炎エネルギー"></img>`)
            text = text.replaceAll('水エネルギー', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Water.png" title=" 水エネルギー"></img>`)
            text = text.replaceAll('雷エネルギー', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Lightning.png" title=" 雷エネルギー"></img>`)
            text = text.replaceAll('超エネルギー', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Psychic.png" title=" 超エネルギー"></img>`)
            text = text.replaceAll('闘エネルギー', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Fighting.png" title=" 闘エネルギー"></img>`)
            text = text.replaceAll('悪エネルギー', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Darkness.png" title=" 悪エネルギー"></img>`)
            text = text.replaceAll('鋼エネルギー', `<img width="20" height="20" style="vertical-align:-8%;" src="https://icu.gg/upload/type/Metal.png" title=" 鋼エネルギー"></img>`)
            text = text.replaceAll('フェアリエネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" title=" 페어리 에너지"></img>`)
            text = text.replaceAll('ドラゴンエネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" title=" 드래곤 에너지"></img>`)
            text = text.replaceAll('無色エネルギー', `<img width="20" height="20" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" title=" 무색 에너지"></img>`)
            return text
        }
    }
}
</script>